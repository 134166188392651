<template>
  <div class="flex flex-col mx-auto">
    <Header :backButton="leaveBreakoutRoom" :backButtonText="'Back'" :title="gameName()" />

    <PlayerList v-if="!currentGame.teamsEnabled" :players="players" :showName="true" class="mt-8 mb-8" />

    <div v-if="currentGame.teamsEnabled" class="mx-auto flex flex-col">
      <h2 class="lg:mt-4 font-semibold text-2xl lg:text-xl">{{ getBreakoutRoomById(breakoutRoomId).name }}</h2>
      <div class="">
        <PlayerList v-if="getPlayersNotInATeam.length > 0" :players="getPlayersNotInATeam" :showName="true" class="pt-8" />
      </div>
      <TeamList v-if="currentGame.teamsEnabled" :gameId="gameId" :breakoutRoomId="breakoutRoomId" class="mt-8" />
    </div>

    <vue-final-modal v-model="gameStartTimer" 
          classes="flex justify-center items-center" 
          content-class="flex flex-col space-y-7 items-center w-screen h-screen px-6 py-6 mx-auto justify-center text-white bg-black opacity-70">
      <div v-if="getPlayersNotInATeam.length > 0" class="flex flex-col px-4 text-semibold space-y-4 mt-6">
        <PlayerCountdown :players="getPlayersNotInATeam" :showName="true" class="mt-8" />
        <p class="text-4xl font-bold">You must be in a team to play!</p>
      </div>
      <div class="px-4 font-semibold flex-grow pt-56 space-y-4">
        <p class="text-6xl">{{ timerSeconds }}s</p>
        <p class="text-4xl">until the game begins</p>
        <p class="text-4xl">make sure all players have joined!</p>
      </div>
      <button @click="cancelStartGame()" class="text-red-500 text-2xl font-bold underline hover:no-underline">Cancel Launch</button>
    </vue-final-modal>

    <div class="mt-10 md:mt-28 mb-8" v-if="currentGame.teamsEnabled">
      <button
        v-if="canStartGame"
        class="flex py-4 px-6 rounded-xl font-semibold bg-green-500 my-4 text-white lg:my-8 mx-auto"
        @click="startGameClicked()">Start the Game!</button>

      <p class="font-semibold text-base lg:text-xl" v-if="players.length > 1 && !allTeamsHaveMinimumPlayers()">
        All teams must have at least {{ teams[0].minPlayerCount }} players...
      </p>
    </div>

  </div>

  <GameplayFooter />
  <GameResetWatcher :breakoutRoomId="breakoutRoomId" />
</template>


<script>
import Header from "../components/Header.vue";
import PlayerList from "../components/pre_game/PlayerList.vue";
import PlayerCountdown from "../components/pre_game/PlayerCountdown.vue";
import TeamList from "../components/pre_game/TeamList.vue";
import { mapMutations, mapState, mapGetters, mapActions } from "vuex";
import { onSnapshot } from "firebase/firestore";
import { 
  cancelStartRemoteGame, 
  getGameStateRefByBreakoutRoomId, 
  startRemoteGame, 
  removePlayerFromGameState
} from "../firebase";
import { gameStepMap } from '../store/modules/utils'
import GameplayFooter from "../components/game/GameplayFooter.vue";
import GameResetWatcher from "../components/post_game/GameResetWatcher.vue";
import axios from 'axios'

export default {
  name: "TeamLobby",
  props: ["gameId", "breakoutRoomId"],
  components: {
    Header,
    PlayerList,
    PlayerCountdown,
    TeamList,
    GameplayFooter,
    GameResetWatcher
},
  data() {
    return {
      gameStartTimer: false,
      timerSeconds: 8,
      unsubGameState: Function,
      startGameCancelled: false,
      gameInitiator: false,
    };
  },
  async created() {
    this.CLEAR_RESPONSES();

    let categories = [];
    if (this.gameContainsCharadesPromptSteps) {
      categories = this.getCharadesPromptCategories
      for (let i = 0; i < categories.length; i++) {
        await this.FETCH_CHARADES_PROMPTS({category: categories[i]})
      }
    }
    if (this.gameContainsWhoAmIPromptSteps) {
      categories = this.getWhoAmIPromptCategories
      for (let i = 0; i < categories.length; i++) {
        await this.FETCH_WHO_AM_I_PROMPTS({category: categories[i]})
      }
    }
    if (this.gameContainsTabooPromptSteps) {
      categories = this.getTabooPromptCategories
      for (let i = 0; i < categories.length; i++) {
        await this.FETCH_TABOO_PROMPTS({category: categories[i]})
      }
    }

    // get teams for breakoutRoom
    const teamIds = this.getBreakoutRoomById(this.breakoutRoomId).teamIds
    teamIds.forEach((teamId) => {
      this.FETCH_TEAM({teamId: teamId});
    });

    const gameStateRef = await getGameStateRefByBreakoutRoomId(this.breakoutRoomId);
    this.unsubGameState = onSnapshot(gameStateRef, (doc) => {
      let playerIds = doc.data().players;
      playerIds.forEach((id) => {
        this.FETCH_USER({ playerId: id });
      });

      // if a player has left the game lobby, remove from local state
      this.players.forEach((player) => {
        if (!playerIds.includes(player.id)) this.REMOVE_PLAYER_FROM_LOBBY(player.id)
      });
      
      const data = {
        id: doc.id,
        status: doc.data().status,
        players: doc.data().players,
        currentTaskGroup: doc.data().currentTaskGroup,
        currentTask: doc.data().currentTask,
        currentStep: doc.data().currentStep,
        currentStepTimestamp: doc.data().currentStepTimestamp,
        currentPrompt: doc.data().currentPrompt,
        currentTeamTurn: doc.data().currentTeamTurn,
        currentPlayerTurn: doc.data().currentPlayerTurn,
        gameId: doc.data().gameId,
        teams: doc.data().teams,
        gameListenersUrl: doc.data().gameListenersUrl,
      };
      this.SET_CURRENT_GAME_STATE(data);

      if (doc.data().status == "started" && this.gameStartTimer == false) {
        this.startGameTimer();
      }

      if (doc.data().status == "lobby" && this.gameStartTimer == true) {
        this.stopGameTimer();
      }
    });
  },
  beforeUnmount() {
    this.unsubGameState();
  },
  computed: {
    ...mapState({
      currentGame: state => state.gameActionsModule.currentGame, 
      currentGameState: state => state.gameStateModule.currentGameState,
      players: state => state.playersModule.players,
      currentPlayer: state => state.playersModule.currentPlayer,
      teams: state => state.teamsModule.teams,
      currentPrompt: state => state.gameStateModule.currentGameState.currentPrompt,
    }),

    ...mapGetters([
      "getStepById", 
      "getBreakoutRoomById", 
      "gameContainsCharadesPromptSteps", 
      "gameContainsWhoAmIPromptSteps", 
      "gameContainsTabooPromptSteps", 
      "getCharadesPromptCategories", 
      "getWhoAmIPromptCategories", 
      "getTabooPromptCategories",
      "getPlayersNotInATeam"
    ]),

    canStartGame() {
      if (this.currentGame.teamsEnabled) {
        return this.players.length > 1 && this.gameStartTimer == false && this.allTeamsHaveMinimumPlayers()
      } else {
        return this.players.length > 1 && this.gameStartTimer == false;
      }
    },

    breakoutRoom() {
      return this.getBreakoutRoomById(this.breakoutRoomId)
    } 
  },
  methods: {
    ...mapMutations(["CLEAR_RESPONSES", "SET_CURRENT_GAME_STATE", "REMOVE_PLAYER_FROM_LOBBY"]),
    ...mapActions([
      "FETCH_USER",
      "SET_CURRENT_TEAM_PLAYER_TURN",
      "REMOVE_PLAYER_FROM_TEAM",
      "NEXT_PROMPT",
      "FETCH_CHARADES_PROMPTS",
      "FETCH_WHO_AM_I_PROMPTS",
      "FETCH_TABOO_PROMPTS",
      "FETCH_TEAM",
    ]),

    async leaveBreakoutRoom() {
      this.$router.push({ name: "BreakoutRoomLobby", params: { gameId: this.gameId }})
      this.teams.forEach((team) => {
        this.REMOVE_PLAYER_FROM_TEAM({ playerId: this.currentPlayer.id, teamId: team.id })
      })
      await removePlayerFromGameState(null, this.breakoutRoomId, this.currentPlayer.id)
    },

    async startGameTimer() {
      // kick off initial prompt if needed
      const stepType = this.getStepById(this.currentGameState.currentStep).stepType
      if (["charades", "taboo", "whoami"].includes(stepType)) {
        this.NEXT_PROMPT({ breakoutRoomId: this.breakoutRoomId, gameId: null })
      }

      this.gameStartTimer = true;
      this.interval = setInterval(() => {
        this.timerSeconds -= 1;
        if (!this.allTeamsHaveMinimumPlayers()) {
          this.stopGameTimer()
        } else if (this.timerSeconds <= 0) {
          clearInterval(this.interval);
          return this.startGame();
        }
      }, 1000);
      return;
    },

    stopGameTimer() {
      this.gameStartTimer = false
      this.gameInitiator = false
      this.timerSeconds = 8;
      clearInterval(this.interval);
      return;
    },

    async startGameClicked() {
      this.startGameCancelled = false
      this.gameInitiator = true
      await startRemoteGame(null, this.breakoutRoomId);

      // kick off team turns
      this.SET_CURRENT_TEAM_PLAYER_TURN({ breakoutRoomId: this.breakoutRoomId })
    },

    startGame() {
      if (this.currentGameState.gameListenersUrl !== null && this.gameInitiator) { // I clicked the button
        // Trigger the game listeners on Flamingo... 
        axios({
          method: 'post',
          url: this.currentGameState.gameListenersUrl,
          data: { game_state_id: this.currentGameState.id },
          withCredentials: false,
        }).then(() => {
          // ... and then goto the first round
          this.gotoFirstRound()
        }).catch((error) => {
          console.log(`Could not trigger flamingo listeners: ${error}`)
          this.gotoFirstRound()
        })
      } else {
        // Otherwise just goto the first round
        setTimeout(() => this.gotoFirstRound(), 1000)
      }
    },

    gotoFirstRound() {
      const stepType = this.getStepById(this.currentGameState.currentStep).stepType
      const screenType = gameStepMap(stepType)
      return this.$router.push({
        name: screenType,
        params: {
          gameId: this.gameId,
          taskGroupId: this.currentGameState.currentTaskGroup,
          taskId: this.currentGameState.currentTask,
          stepId: this.currentGameState.currentStep,
        },
      });
    },

    playersNotInTeam() {
      return this.players.filter(player => !this.teams.map(team => team.players).flat().includes(player.id))
    },

    allTeamsHaveMinimumPlayers() {
      let conditionMet = true
      this.teams.filter((t) => this.breakoutRoom.teamIds.includes(t.id)).forEach((team) => {
        if (team.players.length < team.minPlayerCount) conditionMet = false
      })
      return conditionMet
    },

    async cancelStartGame() {
      await cancelStartRemoteGame(null, this.breakoutRoomId)
    },

    gameName() {
      return this.currentGame.gameName || "Wildgoose"
    }

  },
};
</script>

<style>
  .carousel__slide--visible {
    transform: rotateY(0);
  }
</style>
