import {
  addUser,
  getUserByDocumentId,
} from "../../firebase"

const playersModule = {
  state: () =>  ({
    currentPlayer: {},
    players: [],
    historicalPlayers: [],
  }),
  getters: {
    getCurrentPlayer: (state) => {
      return state.currentPlayer
    },
    getPlayers: (state) => () => {
      return state.players
    },
    getPlayerById: (state) => (playerId) => {
      let player = state.players.find((p) => p.id === playerId)
      if (!player) {
        player = state.historicalPlayers.find((p) => p.id === playerId)
      }
      return player
    },
    getPlayersNotInATeam: (state, getters, rootState, rootGetters) => {
      let playerIds = state.players.map((pl) => pl.id)
      playerIds = playerIds.filter((pl) => !rootGetters.getTeamForPlayer(pl))
      return playerIds.map((id) => getters.getPlayerById(id))
    }
  },
  mutations: {
    SET_CURRENT_PLAYER(state, payload) {
      state.currentPlayer = payload
    },
    UPDATE_GAME_PLAYERS(state, payload) {
      state.players = payload
    },
    FILTER_PLAYERS_UPDATE(state, updatedPlayersFromGameStateDoc) {
      state.players = state.players.filter((pl) => updatedPlayersFromGameStateDoc.includes(pl.id))
    },
    REMOVE_PLAYER_FROM_LOBBY(state, payload) {
      state.players = state.players.filter(player => player.id !== payload)
    },
    CLEAR_LOBBY_PLAYERS(state) {
      state.players = []
    },
    CLEAR_CURRENT_PLAYER(state) {
      state.currentPlayer = {}
    },
    UPDATE_PLAYER_SCORE(state, payload) {
      state.currentPlayer.score += payload;
    },
    ADD_PLAYER_TO_LOBBY(state, payload) {
      if (!state.players.some((player) => player.id === payload.id)) {
        state.players.push(payload)
      }
    },
    SET_HISTORICAL_PLAYERS(state, payload) {
      state.historicalPlayers = payload
    },
  },
  actions: {
    async CREATE_CURRENT_USER({ commit }, { name, initials }) {
      let data = {
        name: name,
        initials: initials,
        score: 0
      }
      const userRef = await addUser(data)
      data['id'] = userRef.id
      commit("SET_CURRENT_PLAYER", data)
    },
    async FETCH_USER({ commit }, { playerId }) {
      const userDoc = await getUserByDocumentId(playerId)
      if (userDoc.exists()) {
        const data = {
          id: userDoc.id,
          name: userDoc.data().name,
          initials: userDoc.data().initials,
          score: userDoc.data().score,
        }
        commit("ADD_PLAYER_TO_LOBBY", data)
      }
    },
  }
}

export default playersModule