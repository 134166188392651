<template>
  <div
    v-for="(player, index) in sortedPlayers"
    :key="player.id"
    class="mx-auto flex flex-row space-x-48"
  >
    <div>{{ index + 1 }}. {{ player.name }}</div>
    <div>{{ player.score }}</div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import {
  query,
  collection,
  where,
  onSnapshot,
} from "@firebase/firestore";
import { db } from "../../firebase";

export default {
  name: "PlayerLeaderboard",
  components: {},
  data() {
    return {
      unsubPlayerState: Function,
      sortedPlayers: [],
    };
  },
  computed: {
    ...mapState({
      currentGame: state => state.gameActionsModule.currentGame,
      players: state => state.playersModule.players,
    }),
    ...mapGetters(["getPlayers"])
  },

  created() {
    // listen for other players updated scores
    const playerList = this.getPlayers();

    const playerIdList = playerList.map((el) => el.id);
    const playerNameList = playerList.map((el) => el.name);
    const pq = query(
      collection(db, "users"),
      where("name", "in", playerNameList)
    );
    this.unsubPlayerState = onSnapshot(pq, (docs) => {
      let players = [];
      docs.forEach((doc) => {
        if (playerIdList.includes(doc.id)) {
          const player = {
            id: doc.id,
            name: doc.data().name,
            initials: doc.data().initials,
            score: doc.data().score,
          };
          players.push(player);
        }
      });
      this.UPDATE_GAME_PLAYERS(players);
      this.sortPlayersByScore();
    });
  },
  beforeUnmount() {
    this.unsubPlayerState();
  },
  methods: {
    ...mapMutations(["UPDATE_GAME_PLAYERS"]),

    compareScores(a, b) {
      if (a.score < b.score) {
        return 1;
      }
      if (a.score > b.score) {
        return -1;
      }
      return 0;
    },

    sortPlayersByScore() {
      this.sortedPlayers = this.players.sort(this.compareScores)
    },
  }
}
</script>