<template>
  <main>
    <router-view :key="$route.fullPath" />
  </main>

  <div class="hidden" id="game_debug_info">
    <span v-if="currentGame">
      -- Game ID: {{ currentGame.id }}  
    </span>
    <span v-if="currentPlayer && currentPlayer.id">
      -- Player ID: {{ currentPlayer.id }}
    </span>
    <span v-if="currentPlayer && currentPlayer.name">
      -- Player name: {{ currentPlayer.name }}
    </span>
    <span v-if="currentBreakoutRoom">
      -- Breakout Room ID: {{ currentBreakoutRoom.id }}  
    </span>
    <span v-if="currentPlayerTeam">
      -- Team ID: {{ currentPlayerTeam.id }}
    </span>
    <span v-if="currentPlayerTeam">
      -- Team name: {{ currentPlayerTeam.name }}
    </span>
    <span v-if="currentGameState && currentStep">
      -- Step ID: {{ currentStep }}
    </span>
  </div> 
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "App",
  computed: {
    ...mapState({
      currentPlayer: state => state.playersModule.currentPlayer,
      currentGame: state => state.gameActionsModule.currentGame,
      currentGameState: state => state.gameStateModule.currentGameState,
    }),
    ...mapGetters(["getTeamForPlayer", "getBreakoutRoomForTeam"]),

    currentStep() {
      if (!this.currentGameState) return null

      return this.currentGameState.currentStep
    },

    currentPlayerTeam() {
      if (!this.currentPlayer || !this.currentPlayer.id) return null

      return this.getTeamForPlayer(this.currentPlayer.id)
    },

    currentBreakoutRoom() {
      if(!this.currentPlayerTeam) return null

      return this.getBreakoutRoomForTeam(this.currentPlayerTeam.id)
    }
  },
  methods: {
    
  }
};
</script>

<style>
  @font-face {
    font-family: "Nunito";
    src: local("Nunito"),
    url(./fonts/Nunito-Medium.ttf) format("truetype");
  }

  @font-face {
    font-family: "NunitoBlack";
    src: local("NunitoBlack"),
    url(./fonts/Nunito-Black.ttf) format("truetype");
  }

  #app {
    font-family: Nunito, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #222222;
  }

  .black-font {
    font-family: NunitoBlack, Helvetica, Arial, sans-serif;
  }

  .normal-font {
    font-family: Nunito, Helvetica, Arial, sans-serif;
  }

  #gameplay {
    min-height: 100vh;
  }

  #top-corner-image {
    position: fixed;
    top: 3em;
    left: 1em;
    max-width: 15vw;
  }

  #bottom-corner-image {
    position: fixed;
    bottom: 1em;
    right: 1em;
    max-width: 15vw;
  }

  #game_debug_info {
    display: none;
  }
</style>
