const StringUtils = {
  data() {
    return {}
  },
  methods: {
    capitaliseFirst(string) {
      return string[0].toUpperCase() + string.substring(1)
    }
  }
}

export default StringUtils
