<template>
  <div class="grid gap-4 mb-2 md:mb-4 mt-8 mx-auto md:pt-3" 
      :class="[ 
        isTaboo ? 'grid-cols-1 md:grid-cols-2 w-4/5 lg:w-3/5 xl:w-1/2' : 'w-4/5 md:w-3/5 lg:w-2/5 lg:mt-5' 
      ]">

    <div>
      <p v-if="isWhoAmI" class="font-bold text-xl lg:text-3xl pb-4 lg:pb-2 pt-2 lg:pt-2 mx-auto">Category: {{humanisedCategory}}</p>
    </div>

    <div class="rounded-3xl px-5 pb-2 md:pb-10 pt-3 md:pt-5 border-2 mb-4"
          :style="{
            'color': spectatorView ? neutralColours.font : primaryColours.font,
            'background-color': spectatorView ? neutralColours.background : primaryColours.background,
            'border-color': spectatorView ? neutralColours.border : primaryColours.border
          }">
          
        <div v-if="spectatorView">
          <p class="mb-3 md:mb-5 text-base md:text-lg mx-auto px-8">
            <span v-if="isWhoAmI">{{ player.name }} is trying to guess who they are.<br /><br />{{ teamMateNames }} {{ teamMates.length > 1 ? 'are' : 'is' }} answering {{ player.name }}'s questions with a yes or no.</span>
            <span v-else-if="isCharades">Their performance word is:</span>
            <span v-else-if="isTaboo">Their word is:</span>
          </p>
        </div>

        <div v-else>
          <div v-if="isWhoAmI">
            <p class="text-base md:text-lg mb-4">It's your turn to guess who you are.<br />You can only ask yes or no questions of your teammates.</p>
          </div>

          <p class="mb-3 md:mb-5 text-lg" v-else>Your performance word is:</p>
        </div>

      <h2 v-if="!(isWhoAmI && actorView)" class="font-bold text-3xl lg:text-4xl mx-auto">{{ prompt.text }}</h2>

      <img v-if="isWhoAmI && actorView" src="https://wildgoose-production.s3.amazonaws.com/emu-resources/thinking-face.png" 
                            class="w-16 h-16 md:w-20 md:h-20 my-4 lg:my-8 mx-auto block" />
      <!-- <img v-else v-bind:src="prompt.image" class="w-16 h-16 md:w-20 md:h-20 my-4 lg:my-8 mx-auto block" /> -->

      <p v-if="spectatorView && !isWhoAmI">It's up to you to ensure they are being honest!</p>
      
      <p v-if="spectatorView && isWhoAmI" class="font-bold my-2">You are just spectating!</p>
      
      <p v-if="actorView">
        <span v-if="isWhoAmI"><span class="font-bold">Number #{{ promptNumber }}</span><br /> This is worth {{ prompt.pointsAwarded }} points!</span>
        <span v-else>This word is worth {{ prompt.pointsAwarded }} points!</span>
      </p>

      <p v-if="isWhoAmI" class="font-bold text-base lg:text-lg mx-auto mt-4 mb-3 md:mb-0">
        <span v-if="showHints">{{ spectatorView ? `${player.name}'s` : `Here's a` }} hint:<br /> {{currentHint}}</span>
        <span v-else>&nbsp;</span>
      </p>
    </div>

    <div v-if="isTaboo" 
        class="rounded-3xl px-5 pb-2 md:pb-10 pt-3 md:pt-5 border-2 mb-4"
        :style="{
          'color': secondaryColours.font,
          'background-color': secondaryColours.background,
          'border-color': secondaryColours.border
        }">
      <p class="mb-3 md:mb-5 text-lg">Restricted Words</p>
      
      <p v-if="spectatorView" class="px-8 mb-4">These are words that {{ player.name }} cannot say!</p>

      <ul class="md:py-6 grid grid-cols-2 gap-3 mb-4">
        <li class="font-semibold text-xl lg:text-xl mb-2 md:mb-1" v-for="(word) in limitedTabooWords" :key="word">{{word}}</li>
      </ul>
      
      <p v-if="spectatorView" class="px-8 mb-2">Speak up if you hear them say one!</p>
      <p v-if="actorView" class="px-8 mb-2">These are the words that you must not say!</p>
    </div>
  </div>

  <!-- Answer Buttons -->
  <div v-if="prompt && actorView && !tooLate" 
      class="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4 mb-8 w-4/5 lg:w-3/5 xl:w-2/5 mx-auto md:px-10 pb-4">
    <button
      @click="answerPrompt(true)"
        :disabled="!buttonsActive"
      class="correct-button py-4 w-full font-semibold rounded-xl cursor-pointer text-white"
      :class="{ 'opacity-40' : !buttonsActive }">
      Correct
    </button>
    <button @click="answerPrompt(false)"
      :disabled="!buttonsActive"
      class="skip-button py-4 w-full font-semibold rounded-xl cursor-pointer text-white"
      :class="{ 'opacity-40' : !buttonsActive }">
      Skip
    </button>
  </div>
</template>

<script>

import ColourUtils from '@/mixins/ColourUtils'
import StringUtils from '@/mixins/StringUtils'
import { mapActions, mapGetters } from "vuex"

export default {
  name: "ShowPrompt",
  mixins: [ColourUtils, StringUtils],
  props: [
    "prompt", 
    "respondToPrompt", 
    "spectatorView", 
    "tooLate", 
    "player", 
    "step",
    "promptNumber",
    "team"
  ],
  data() {
    return {
      fullPlayers: [],
      borderSaturateBy: 32,
      borderShadeBy: -20,
      promptHintIndex: 0,
      buttonsActive: false,
      hintRotationInterval: null,
      showHints: false
    }
  },
  async created() {
    if (this.isWhoAmI) this.rotateHints()

    await this.FETCH_TEAM({ teamId: this.team.id, getPlayers: true });    
    this.buildTeamPlayers()

    if (!this.spectatorView) {
      setTimeout(() => this.buttonsActive = true, 2000) // Set buttons active after the actor delay
    }
  },
  beforeUnmount() {
    clearInterval(this.hintRotationInterval)
  },
  computed: {
    ...mapGetters(["getTeamById"]),

    actorView() {
      return !this.spectatorView
    },

    teamMates() {
      return this.fullPlayers.filter((player) => player.id != this.player.id)
    },

    teamMateNames() {
      return this.teamMates.map((player) => player.name).join(", ").replace(/,(?=[^,]+$)/, ' and')
    },

    humanisedCategory() {
      return this.capitaliseFirst(this.step.promptCategory)
    },

    limitedTabooWords() {
      return this.prompt.tabooWords.slice(0, 8)
    },

    primaryColours() {
      const pColours = this.step.colours.primary
      pColours.border = this.applySaturationToHexColor(pColours.background, this.borderSaturateBy)
      pColours.border = this.shade(pColours.border, this.borderShadeBy)
      return pColours
    },
    secondaryColours() {
      const rColours = this.step.colours.secondary
      rColours.border = this.applySaturationToHexColor(rColours.background, this.borderSaturateBy)
      rColours.border = this.shade(rColours.border, this.borderShadeBy)
      return rColours
    },
    neutralColours() {
      const nColours = this.step.colours.neutral
      nColours.border = this.applySaturationToHexColor(nColours.background, this.borderSaturateBy)
      nColours.border = this.shade(nColours.border, this.borderShadeBy)
      return nColours
    },
    spectatingCharadesOrWhoAmI() {
      return (this.isCharades || this.isWhoAmI) && this.spectatorView
    },
    isTaboo() {
      return this.step.stepType == 'taboo'
    },
    isCharades() {
      return this.step.stepType == 'charades'
    },
    isWhoAmI() {
      return this.step.stepType == 'whoami'
    },
    currentHint() {
      return this.prompt.hints[this.promptHintIndex]
    }
  },
  watch: {
    prompt() {
      if (this.isWhoAmI) {
        this.showHints = false
        this.promptHintIndex = 0
        clearInterval(this.hintRotationInterval)
        setTimeout(() => this.rotateHints(), 750) // Restart the hint timer
      }
    }
  },

  methods: {
    ...mapActions(["FETCH_TEAM"]),

    async answerPrompt(answerStatus) {
      if (!this.buttonsActive) return

      this.buttonsActive = false 
      setTimeout(async () => {
        await this.respondToPrompt(answerStatus)
        this.buttonsActive = true
      }, 750)
    },

    rotateHints() {
      this.hintRotationInterval = window.setInterval(() => {
        if(this.promptHintIndex === this.prompt.hints.length - 1) {
          this.promptHintIndex = 0
        } else {
          this.promptHintIndex += 1
        }
        this.showHints = true
      }, 30000) // Show a new hint every 30 seconds
    },

    buildTeamPlayers() {
      this.fullPlayers = this.getTeamById(this.team.id).fullPlayers
    }
  }
};
</script>

<style>
  .skip-button {
    background: #F56565;
    border: 2px solid #c53030;
  }
  .correct-button {
    background: #48BB78;
    border: 2px solid #1a7865;
  }
</style>