<template>
  <div class="flex flex-col space-y-24">
    <div class="flex flex-1 flex-col h-full mx-auto md:mt-6 content-evenly space-y-16 lg:space-y-24">
      <div class="space-y-4">
        <p class="font-bold mx-4">Enter your name so people can recognise you!</p>
        <input
          class="border-2 mx-4 rounded-xl p-6 text-center"
          style="background: #E0F3EC"
          type="text"
          v-model="player.name"
          @keyup.enter="confirmLogin()"
          maxlength="25"
        />
        <p class="font-bold mx-4">Maximum Characters: {{ player.name.length }} / 25</p>
      </div>

      <button class="py-2 mx-4 font-semibold rounded-full border bg-white cursor-pointer" @click="confirmLogin()">Choose a Team</button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "UserForm",
  data() {
    return {
      player: {
        name: ""
      },
    };
  },
  methods: {
    ...mapActions(["CREATE_CURRENT_USER"]),

    formIsValid() {
      if (this.player.name.length == 0) {
        alert("Please enter your name");
        return false;
      } else {
        return true;
      }
    },

    async confirmLogin() {
      if (this.formIsValid()) {
        await this.CREATE_CURRENT_USER({
          name: this.player.name,
          initials: this.calculateInitials(this.player.name)
        });
        this.$emit("loggedIn", true);
      }
    },

    calculateInitials() {
      const words = this.player.name.split(" ")
      return words.map((word) => word[0].toUpperCase()).splice(0, 2).join("")
    }
    
  },
};
</script>