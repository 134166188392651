<template>
  <div class="flex flex-col">
    <Header />
    <PlayerList v-if="players" :players="players" class="mt-8" />

    <button
      v-if="canStartGame"
      class="py-4 px-6 rounded-xl font-semibold bg-green-300 mt-12 mx-auto"
      @click="startGameClicked()"
    >
      Start the Game!
    </button>

    <p class="mt-4 font-semibold text-xl" v-if="players.length == 1">
      Waiting for players to enter the lobby...
    </p>

    <p class="mt-4 font-semibold text-xl" v-if="gameStartTimer">
      Game starts in {{ timerSeconds }} seconds
    </p>

    <button 
      v-if="gameStartTimer"
      class="py-4 px-6 rounded-xl font-semibold bg-green-300 mt-12 mx-auto"
      @click="cancelStartGame()"
      >
      Cancel
    </button>

    <div class="hidden" id="tailwind_purge_hack">
      <span class="bg-green-300"></span>
      <span class="bg-red-300"></span>
      <span class="bg-yellow-300"></span>
      <span class="bg-blue-300"></span>
      <span class="bg-indigo-300"></span>
    </div>
  </div>
</template>


<script>
import Header from "../components/Header.vue";
import PlayerList from "../components/pre_game/PlayerList.vue";
import { mapMutations, mapState, mapActions } from "vuex";
import { onSnapshot } from "firebase/firestore";
import { cancelStartRemoteGame, getGameStateRefByGameId, startRemoteGame } from "../firebase";
export default {
  name: "PlayerLobby",
  props: ["gameId"],
  components: {
    Header,
    PlayerList,
  },
  data() {
    return {
      gameStartTimer: false,
      timerSeconds: 5,
      unsubGameState: Function,
      startGameCancelled: false,
    };
  },
  async created() {
    this.CLEAR_RESPONSES();
    const gameStateRef = await getGameStateRefByGameId(this.gameId);
    this.unsubGameState = onSnapshot(gameStateRef, (doc) => {
      let playerIds = doc.data().players;
      playerIds.forEach((id) => {
        this.FETCH_USER({ playerId: id });
      });

      // if a player has left the game lobby, remove from local state
      this.players.forEach((player) => {
        if (!playerIds.includes(player.id)) this.REMOVE_PLAYER_FROM_LOBBY(player.id)
      });

      const data = {
        id: doc.id,
        status: doc.data().status,
        players: doc.data().players,
        currentTaskGroup: doc.data().currentTaskGroup,
        currentTask: doc.data().currentTask,
        currentStep: doc.data().currentStep,
        gameId: doc.data().gameId,
      };
      this.SET_CURRENT_GAME_STATE(data);
      if (doc.data().status == "started" && this.gameStartTimer == false) {
        this.startGameTimer();
      }
      if (doc.data().status == "lobby" && this.gameStartTimer == true) {
        this.stopGameTimer();
      }
    });
  },
  beforeUnmount() {
    this.unsubGameState();
  },
  computed: {
    ...mapState({
      currentGame: state => state.gameActionsModule.currentGame, 
      currentGameState: state => state.gameStateModule.currentGameState,
      players: state => state.playersModule.players,
      currentPlayer: state => state.playersModule.currentPlayer,
      currentPrompt: state => state.gameStateModule.currentGameState.currentPrompt,
    }),
    canStartGame() {
      return this.players.length > 1 && this.gameStartTimer == false;
    },
  },
  methods: {
    ...mapMutations(["CLEAR_LOBBY_PLAYERS", "CLEAR_RESPONSES", "REMOVE_PLAYER_FROM_LOBBY"]),
    ...mapActions(["SET_CURRENT_GAME_STATE", "FETCH_USER"]),

    startGameTimer() {
      this.gameStartTimer = true;
      this.interval = setInterval(() => {
        this.timerSeconds -= 1;
        if (this.timerSeconds <= 0) {
          clearInterval(this.interval);
          return this.startGame();
        }
      }, 1000);
      return;
    },
    stopGameTimer() {
      this.gameStartTimer = false
      this.timerSeconds = 5;
      clearInterval(this.interval);
      return;
    },
    async startGameClicked() {
      this.startGameCancelled = false
      await startRemoteGame(this.gameId);
    },
    startGame() {
      return this.$router.push({
        name: "Question",
        params: {
          gameId: this.gameId,
          taskGroupId: this.currentGameState.currentTaskGroup,
          taskId: this.currentGameState.currentTask,
          stepId: this.currentGameState.currentStep,
        },
      });
    },
    async cancelStartGame() {
      await cancelStartRemoteGame(this.gameId)
    }
  },
};
</script>