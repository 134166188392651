import { signInWithEmailAndPassword } from "firebase/auth";
import { firebaseAuth } from './firebaseConfig';
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './index.css'
import './assets/tailwind.css'
import { store } from './store';
import { detectIncognito } from "detectincognitojs";
import { vfmPlugin } from 'vue-final-modal'

detectIncognito().then((result) => {
  // console.log(result.browserName, result.isPrivate);
  if(!result.isPrivate) {
    signInWithEmailAndPassword(firebaseAuth, process.env.VUE_APP_FIREBASE_AUTH_EMAIL, process.env.VUE_APP_FIREBASE_AUTH_PASSWORD).then(() => {
      // console.warn("Signed into Firebase")
      const app = createApp(App);
      app.use(vfmPlugin);
      app.use(router);
      app.use(store);
      app.mount('#app');
    }).catch((error) => {
      console.error(`FirebaseAuth sign in error: ${error.code}, ${error.message}`)
    });  
  } else {
    const element = document.getElementById("incognito-msg")
    let msg = `<h1 class='text-xl mb-6 font-semibold'>Are you using incognito mode?</h1>`
    msg += `<p>😱 It looks like your browser (${result.browserName}) is set to a private or incognito mode!!</p>`
    msg += `<br /> To play the game you will need to switch this browser mode off or use a different browser 😀.`
    element.innerHTML = msg
    element.classList.remove('hidden')
  }
});  

