<template>
  <div
    v-for="(team, index) in sortedTeams"
    :key="team.id"
    class="mx-auto flex flex-row space-x-48"
  >
    <div>{{ index + 1 }}. {{ team.name }}</div>
    <div>{{ team.score }}</div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import {
  query,
  collection,
  where,
  onSnapshot,
} from "@firebase/firestore";
import { db } from "../../firebase";

export default {
  name: "TeamLeaderboard",
  components: {},
  props: ["gameId", "stepId", "playerCount"],
  data() {
    return {
      unsubTeamState: Function,
      sortedTeams: [],
    };
  },
  computed: {
    ...mapState({
      currentGame: state => state.gameActionsModule.currentGame,
      currentGameState: state => state.gameStateModule.currentGameState,
      teams: state => state.teamsModule.teams,
    }),
  },

  created() {
    // listen for other teams updated scores
    const q = query(
      collection(db, "teams"),
      where("gameId", "==", this.currentGame.id)
    )

    this.unsubTeamState = onSnapshot(q, (docs) => {
      let teamsList = [];
      docs.forEach((doc) => {
        const response = {
          id: doc.id,
          name: doc.data().name,
          hexColour: doc.data().hexColour,
          players: doc.data().players,
          score: doc.data().score,
        }
        teamsList.push(response)
      })
      this.UPDATE_TEAM_STATES(teamsList)
      this.sortTeamsByScore();
    })

  },
  beforeUnmount() {
    this.unsubTeamState();
  },
  methods: {
    ...mapMutations(["UPDATE_TEAM_STATES"]),
    
    compareScores(a, b) {
      if (a.score < b.score) {
        return 1;
      }
      if (a.score > b.score) {
        return -1;
      }
      return 0;
    },

    sortTeamsByScore() {
      this.sortedTeams = this.teams.sort(this.compareScores)
    },
  }
}
</script>