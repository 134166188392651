<template>
  <header class="fixed top-0 left-0 w-full px-2 py-2 border-t border-gray-500 z-30"
          :class="{
            'header-bg-spectating': mode == 'spectating',
            'header-bg-playing': mode == 'playing'
          }">
    <div class="w-full flex text-sm items-center">
      <div class="flex-none w-10 md:w-20 lg:w-1/6 text-left">&nbsp;</div>
      <h5 class="flex-grow text-center text-xs md:text-sm font-semibold">
        You are on Team <TeamName /> - {{ capitaliseFirst(verb) }}
      </h5>
      <div class="flex-none w-10 md:w-20 lg:w-1/6 text-right text-xs">
        <span class='inline lg:hidden normal-font'>
          <Popper offsetDistance="14">
            <button class='black-font outline-none focus:outline-none'>{{ gameTimeRemaining('small', false) }}</button>
            <template #content>
              <div class="popper-content flex items-center justify-start space-x-2 text-center">
                <span>🕰️</span>
                <span v-html="gameTimeRemaining('small', true)"></span>
              </div>
            </template>
          </Popper>
        </span>
        <span class='hidden lg:inline black-font'>{{ gameTimeRemaining('large', true) }}</span>
      </div>
    </div>
  </header>
</template>

<script>
import TeamName from './TeamName.vue';
import StringUtils from '../../mixins/string_utils';
import Popper from "vue3-popper";
import { mapState } from 'vuex';

export default {
  name: 'InfoHeader',
  props: [
    "mode", 
    "verb", 
    "gameFinishedFunction"
  ],
  computed: {
    ...mapState({
      currentGame: state => state.gameActionsModule.currentGame,
    }),
  },
  components: { 
    TeamName,
    Popper
  },
  data() {
    return {
      countDownInterval: Function,
      gameTimeReamainingSeconds: 60
    }
  },
  beforeUnmount() {
    clearInterval(this.countDownInterval)
  },
  created() {
    this.countDownInterval = setInterval(() => {
      const endTime = this.currentGame.gameEndsAt.seconds * 1000
      this.gameTimeReamainingSeconds = Math.round((endTime - Date.now()) / 1000)
    }, 1000)
  },
  mixins: [ StringUtils ],
  methods: {
    gameTimeRemaining(displayType = 'small', includeRemainingSuffix=false) {
      if (this.gameTimeReamainingSeconds <= 0) {
        this.gameFinishedFunction()
        return ""
      }

      let remaining
      let suffix = ''
      if (this.gameTimeReamainingSeconds <= 60) {
        // Less than a minute, display in seconds
        const displaySecs = String(this.gameTimeReamainingSeconds).padStart(2, '0')
        remaining = `00:${displaySecs}`
      } else if (this.gameTimeReamainingSeconds >= 3600) {
        // More than an hour, display in hours
        remaining = Math.round(this.gameTimeReamainingSeconds / 60 / 60)
        suffix = displayType == 'small' && !includeRemainingSuffix ? 'h' : ` ${ remaining <= 1 ? "hour" : "hours" }`
      } else {
        // Within an hour, display in minutes
        remaining = Math.round(this.gameTimeReamainingSeconds / 60)
        suffix = displayType == 'small' && !includeRemainingSuffix ? 'm' : ` ${ remaining <=  1 ? "minute" : "minutes" }`
      }

      if (includeRemainingSuffix) {
        suffix += displayType == 'large' ? ' remaining' : '<br /> remaining'
      }
      
      return `${remaining}${suffix}`
    }
  }
}
</script>

<style scoped>
  .header-bg-spectating {
    background-color: #edf2f7;
  }

  .header-bg-playing {
    background-color: #e0f3ec;
  }

  :deep(.popper) > .popper-content {
    background: #f7fafc;
    padding: 8px 14px;
    border: 1px solid #cbd5e0;
    border-radius: 7px;
    color: #000000;
    font-size: 14px;
    left: -3px;
    position: relative;
  }

  :deep(.popper:hover) > .popper-content {
    background: #f7fafc;
    border: 1px solid #cbd5e0;
  }

</style>