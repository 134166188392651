<template>
  <section v-if="teamsLoadedAndSorted" class="pt-16 md:pt-20 lg:pt-8 pb-20 px-10 md:px-32 relative">
    <router-link v-if="isFromMenu" 
                class="text-base absolute top-3 md:top-10 left-3 md:left-5" 
                :to="{ name: 'ScoresMenu' }"><span class='text-xl font-bold mr-2'>&#8592;</span> Score Selection</router-link>

    <header class="space-y-4">
      <h2 class="text-center text-3xl font-semibold">Your breakout room scores</h2>
  
      <p class="text-center px-16 md:px-0">How did you rank in your breakout room competition today?</p>
    </header>

    <div class="mb-8 grid grid-cols-1 lg:grid-cols-2 gap-6 md:gap-8 mt-10">
      <div v-for="(team, index) in sortedTeams" v-bind:key="team.id" :set="rank = index + 1" 
          class="overflow-visible rounded-3xl px-8 py-12 text-center relative border-2"
          :class="{'border-gray-300 bg-gray-100': !isCurrentTeam(team), 'current-team-box': isCurrentTeam(team)}">
        <img v-if="rank == 1" 
            class="w-24 md:w-32 absolute -top-6 md:-top-4 -left-10 md:-left-10 transform -rotate-12" 
            src="https://wildgoose-production.s3.amazonaws.com/emu-resources/gold-trophy.png" 
            alt="1st Place"
            id="gold_trophy" />

        <img v-if="rank == 2" 
            class="w-20 md:w-28 absolute -top-4 -left-6 md:-top-6 md:-right-10 md:left-auto transform -rotate-12 md:rotate-12" 
            src="https://wildgoose-production.s3.amazonaws.com/emu-resources/silver-trophy.png" 
            alt="2nd Place"
            id="silver_trophy" />

        <img v-if="rank == 3" 
            class="w-16 md:w-24 absolute -top-3 -left-5 md:-top-6 md:-left-8 transform -rotate-12" 
            src="https://wildgoose-production.s3.amazonaws.com/emu-resources/bronze-trophy.png" 
            alt="3rd Place"
            id="bronze_trophy" />
        
        <p class="absolute top-3 right-5 text-base font-semibold"
            :class="{ 'md:left-5 md:right-auto': rank % 2 == 0}">
            {{ordinalize(rank)}}
        </p>
        
        <div class="">
          <p class="px-8 md:px-20 lg:px-10 xl:px-20 text-xl font-semibold mb-3">
            <span v-if="rank == 1">
              Team {{team.name}}!<br /> You have won the head to head!
            </span>
            <span v-else>
              Team {{team.name}} placed {{ordinalize(rank)}} within the breakout room!
            </span>
          </p>
  
          <p class="text-4xl font-bold mb-6 black-font text-gray-800">{{team.score}} points!</p>
  
          <ul class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 text-sm px-10 md:px-0 xl:px-8">
            <li v-for="(player) in fullPlayersForTeam(team)" 
                v-bind:key="player.id" 
                class="flex items-center justify-start space-x-2">
              <div class="rounded-full flex items-center justify-center w-10 h-10 px-4 py-2 font-bold"
                    :class="{ 
                      'player-initials': currentPlayer.id != player.id && !isCurrentTeam(team), 
                      'current-team-initials': currentPlayer.id != player.id && isCurrentTeam(team),
                      'current-player-initials': currentPlayer.id == player.id
                    }">
                {{ player.initials }}
              </div>
              <span class="pl-1 text-black">
                {{ player.name }}
                <span v-if="currentPlayer.id == player.id">&nbsp;(you)</span>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <NotificationsCallout :gameId="gameId" :breakoutRoomId="breakoutRoomId" :screenType="'podium'" />
    <GameplayFooter />
  </section>

  <section v-if="!isFromMenu" class="fixed bottom-0 left-0 w-full bg-white text-center py-6">
    <router-link class="py-3 px-5 rounded-full text-white font-semibold view-all-button" 
            :to="{ name: 'ScoresMenu' }">View All <span class='text-xl font-bold'>&#8594;</span></router-link>
  </section>

  <GameResetWatcher :breakoutRoomId="breakoutRoomId" />
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import GameplayFooter from "../../components/game/GameplayFooter.vue";
import NumberUtils from '@/mixins/NumberUtils'
import {
  query,
  collection,
  where,
  onSnapshot
} from "@firebase/firestore";
import { db } from "../../firebase";
import GameResetWatcher from "../../components/post_game/GameResetWatcher.vue";
import NotificationsCallout from "../../components/game/NotificationsCallout.vue";

export default {
  name: "BreakoutRoomScores",
  components: { GameplayFooter, GameResetWatcher, NotificationsCallout },
  props: { 
    fromMenu: String,
    gameId: String, 
    breakoutRoomId: String
  },
  async created() {
    await this.loadGameState()
    await this.setupTeamsListener()
  },
  data() {
    return {
      sortedTeams: [],
      teamsLoadedAndSorted: false,
      unsubTeamsListener: Function
    }
  },
  beforeUnmount() {
    this.unsubTeamsListener();
  },
  mixins: [NumberUtils],
  computed: {
    ...mapGetters(["getPlayerById", "getTeamForPlayer"]),
    
    ...mapState({
      currentGame: state => state.gameActionsModule.currentGame,
      currentGameState: state => state.gameStateModule.currentGameState,
      historicalPlayers: state => state.playersModule.historicalPlayers,
      allTeams: state => state.teamsModule.teams,
      currentPlayer: state => state.playersModule.currentPlayer
    }),

    isFromMenu() {
      return this.fromMenu == 'true'
    }
  },
  methods: {
    ...mapActions(["GET_GAME_STATE"]),
    ...mapMutations(["UPDATE_TEAM_STATES"]),

    async setupTeamsListener() {
      const q = query(collection(db, "teams"), where("gameId", "==", this.gameId), where("breakoutRoomId", "==", this.breakoutRoomId));
      this.unsubTeamsListener = onSnapshot(q, (docs) => {
        let teams = [];
        docs.forEach((teamDoc) => {
          const team = {
            id: teamDoc.id,
            breakoutRoomId: teamDoc.data().breakoutRoomId,
            hexColour: teamDoc.data().hexColour,
            name: teamDoc.data().name,
            players: teamDoc.data().players,
            score: teamDoc.data().score,
            minPlayerCount: teamDoc.data().minPlayerCount,
            maxPlayerCount: teamDoc.data().maxPlayerCount,
          };
          teams.push(team);
        });
        this.UPDATE_TEAM_STATES(teams);
        this.loadAndSortTeams()
      });
    },

    isCurrentTeam(team) {
      const currentTeam = this.getTeamForPlayer(this.currentPlayer.id)
      return currentTeam ? (currentTeam.id == team.id) : false
    },

    async loadGameState() {
      await this.GET_GAME_STATE({ gameId: this.gameId, breakoutRoomId: this.breakoutRoomId, getHistorical: true });
    },

    compareTeamsByScore(a, b) {
      if (a.score < b.score) {
        return 1;
      }
      if (a.score > b.score) {
        return -1;
      }
      return 0;
    },

    loadAndSortTeams() {
      const teamsInBreakoutRoom = this.allTeams.filter(t => t.breakoutRoomId == this.breakoutRoomId)
      this.sortedTeams = teamsInBreakoutRoom.sort(this.compareTeamsByScore)
      this.teamsLoadedAndSorted = true
    },

    fullPlayersForTeam(team) {
      const list = team.players.map((playerId) => {
        const player = this.getPlayerById(playerId)
        return player ? player : null
      })
      return list.filter((pl) => pl !== null)
    }
  },
};
</script>
