const notificationsModule = {
  state: () => ({
    notifications: []
  }),
  getters: {
    getNotificationIsUnread: (state) => (notificationId) => {
      return !state.notifications.includes(notificationId)
    }
  },
  mutations: {
    MARK_NOTIFICATION_READ(state, notificationId) {
      state.notifications.push(notificationId)
    },
    CLEAR_READ_NOTIFICATIONS(state) {
      state.notifications = []
    },
  },
  actions: {
  }
}

export default notificationsModule;