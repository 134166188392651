<template>
  <div class="w-full flex items-center justify-center">
    <div v-for="(notification, index) in notifications" :key="notification.id"
          :class="[unread.includes(notification.id) ?  `opacity-100 z-${(index+1) * 10} bottom-5` : `opacity-0 z-0 -bottom-5`]"
          class="fixed bg-white py-4 px-3 left-5 w-11/12 md:w-1/3 lg:w-1/4 xl:w-1/5 rounded-xl text-base md:text-sm font-semibold leading-6 transition-all duration-500 ease-linear shadow-lg ring-1 ring-slate-600/[0.04]">
  
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" 
            class="w-5 h-5 absolute right-2 top-2 cursor-pointer"
            @click="closeBox(notification.id)">
        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
      </svg>
          
      <div class="px-5">{{ notification.message }}</div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from "vuex";
  import { collection, where, query, onSnapshot } from "firebase/firestore";
  import { db } from "../../firebase";

  export default {
    props: [
      "gameId", 
      "breakoutRoomId", 
      "screenType"
    ],
    name: 'NotificationsCallout',
    data() {
      return {
        notifications: [],
        unread: [],
        unsubNotifications: Function
      }
    },
    computed: {
      ...mapGetters(["getNotificationIsUnread"]),
    },
    beforeUnmount() {
      this.unsubNotifications()
    },
    created() {
      // this.CLEAR_READ_NOTIFICATIONS()
      this.setupNotificationListener()
    },
    methods: {
      ...mapMutations([
        "MARK_NOTIFICATION_READ",
        "CLEAR_READ_NOTIFICATIONS"
      ]),

      setupNotificationListener() {
        const notificationsQuery = query(
          collection(db, "notifications"),
          where("gameId", "==", this.gameId),
          where("breakoutRoomId", "==", this.breakoutRoomId),
          where("displayOn", "==", this.screenType),
          where("for", "==", "all")
        )
        this.unsubNotifications = onSnapshot(notificationsQuery, (docs) => {
          docs.forEach((doc) => {
            if (this.getNotificationIsUnread(doc.id) && doc.data()["message"].length > 0) {
              const notification = {
                id: doc.id,
                message: doc.data()["message"],
              }
              setTimeout(() => { // Gives us a nice fade in of the notifications
                this.unread.push(notification.id)
                this.notifications.push(notification)
                this.MARK_NOTIFICATION_READ(doc.id)
              }, 1000)
            }
          })
        });
      },

      closeBox(id) {
        const index = this.unread.indexOf(id)
        this.unread.splice(index, 1)
      }
    }
  }
</script>