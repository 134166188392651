<template>
  <div class="flex flex-col mx-auto space-y-4">
    <h2 class="max-w-2xl font-bold text-xl">
      {{ step.position }}. {{ step.question }}
    </h2>
    <img
      v-bind:src="`https://picsum.photos/350?v=${step.id}`"
      style="width: 350px; height: 350px; margin: 15px auto"
      class="rounded-md"
    />
  </div>
</template>

<script>
export default {
  name: "ShowQuestion",
  props: ["step"],
};
</script>