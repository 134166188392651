<template>
  <div
    v-if="
      currentGame &&
      gameStatsLoaded
    "
    class="flex mx-auto flex-col space-y-8 w-1/2 pb-12"
  >
    <h1 class="text-xl">The Game</h1>

    <div class="grid grid-cols-2 gap-x-12 gap-y-2 mx-auto text-left">
      <p>Fastest Player</p>
      <p>{{ getPlayerById(this.gameStats.fastestPlayer).name }}</p>
      <p>Slowest Player</p>
      <p>{{ getPlayerById(this.gameStats.slowestPlayer).name }}</p>
      <p>Average Time (all players)</p>
      <p>{{ gameStats.averageTimeToRespond }} secs</p>
      <p>Average Correct</p>
      <p>
        {{ Math.round(gameStats.averageCorrectResponses * 10) / 10 }}/{{ gameStats.totalStepCount }}
      </p>
    </div>

    <h1 class="text-xl">Your Game</h1>

    <div class="grid grid-cols-2 gap-x-12 gap-y-2 mx-auto text-left">
      <p>Correct Answers</p>
      <p>{{ currentPlayerGameStats.correctAnswers }}/10</p>
      <p>Fastest Question</p>
      <p>{{ currentPlayerGameStats.fastestQuestion }}</p>
      <p>Fastest Time</p>
      <p>
        {{
          currentPlayerGameStats.fastestTime
        }}
        secs
      </p>
      <p>Slowest Question</p>
      <p>{{ currentPlayerGameStats.slowestQuestion }}</p>
      <p>Slowest Time</p>
      <p>{{ currentPlayerGameStats.slowestTime }} secs</p>
      <p>Average Time</p>
      <p>
        {{
          Math.round(
            (currentPlayerGameStats.averageTimeToAnswer / 1000) * 100
          ) / 100
        }}
        secs
      </p>
    </div>

    <div class="flex justify-evenly">
      <router-link
        :to="{ name: 'BreakoutRoomScores', params: { gameId: gameId } }"
        class="text-black border-2 border-black p-2 rounded-md"
        >Podium</router-link
      >
      <router-link
        :to="{ name: 'ExitGame' }"
        class="text-black bg-yellow-600 p-2 px-4 rounded-md cursor-pointer"
        >Leave</router-link
      >
    </div>

    <question-stats :stepId="stepId" />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import QuestionStats from "./QuestionStats.vue";

export default {
  name: "GameStats",
  components: {
    QuestionStats,
  },
  props: ["gameId"],
  computed: {
    ...mapState({
      currentPlayer: state => state.playersModule.currentPlayer,
      currentGame: state => state.gameActionsModule.currentGame,
      gameStats: state => state.gameStatsModule.gameStats,
      gameStatsLoaded: state => state.gameStatsModule.gameStatsLoaded,
      currentPlayerGameStats: state => state.gameStatsModule.currentPlayerGameStats,
    }),
    ...mapGetters(["getPlayerById"])
  },
  data() {
    return {
      showQuestionResult: false,
      stepId: null,
    };
  },
  created() {
    if (this.currentGame === null) this.getGame();
    this.loadGameState();
    this.calculateGameStats();
    this.calculatePlayerGameStats();
    this.loadResponses();
  },
  methods: {
    ...mapActions([
      "GET_GAME_STATE",
      "GET_GAME_BY_ID",
      "CALCULATE_GAME_STATS",
      "CALCULATE_PLAYER_GAME_STATS",
      "LOAD_PLAYER_RESPONSES",
    ]),

    loadGameState() {
      this.GET_GAME_STATE({ gameId: this.gameId });
    },

    getGame() {
      this.GET_GAME_BY_ID({ gameId: this.gameId });
    },

    calculateGameStats() {
      this.CALCULATE_GAME_STATS({ gameId: this.gameId });
    },

    calculatePlayerGameStats() {
      this.CALCULATE_PLAYER_GAME_STATS({
        gameId: this.gameId,
        playerId: this.currentPlayer.id,
      });
    },

    getPlayer(playerId) {
      return this.getPlayerById(playerId);
    },

    loadResponses() {
      this.LOAD_PLAYER_RESPONSES({ gameId: this.gameId });
    },
  },
};
</script>