import { createRouter, createWebHistory } from 'vue-router'

// Auth / Enter
import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'
import ExitGame from '../views/ExitGame.vue'

// Lobbies
import PlayerLobby from '../views/PlayerLobby'
import TeamLobby from '../views/TeamLobby.vue'

// Game
import BreakoutRoomLobby from '../views/BreakoutRoomLobby'
import Question from '../views/Question'
import PromptQuestion from '../views/PromptQuestion'
import PromptQuestionPreview from '../views/PromptQuestionPreview'
import Instruction from '../views/Instruction'

// Post-game
import ScoresMenu from '../views/post_game/ScoresMenu'
import BreakoutRoomScores from '../views/post_game/BreakoutRoomScores'
import TeamScores from '../views/post_game/TeamScores'
import OverallScores from '../views/post_game/OverallScores'
import GameStats from '../components/post_game/GameStats'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/exit-game',
    name: 'ExitGame',
    component: ExitGame
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/breakout-room-lobby/:gameId',
    name: 'BreakoutRoomLobby',
    props: true,
    component: BreakoutRoomLobby
  },
  {
    path: '/player-lobby/:gameId',
    name: 'PlayerLobby',
    props: true,
    component: PlayerLobby
  },
  {
    path: '/team-lobby/:gameId/:breakoutRoomId',
    name: 'TeamLobby',
    props: true,
    component: TeamLobby
  },
  {
    path: '/instruction/:gameId/:breakoutRoomId/:taskGroupId/:taskId/:stepId',
    name: 'Instruction',
    component: Instruction,
    props: true
  },
  {
    path: '/prompt/:gameId/:breakoutRoomId/:taskGroupId/:taskId/:stepId',
    name: 'PromptQuestion',
    component: PromptQuestion,
    props: true
  },
  {
    path: '/prompt-preview/:stepPreviewId/:screenType',
    name: 'PromptQuestionPreview',
    component: PromptQuestionPreview,
    props: true
  },
  {
    path: '/question/:gameId/:taskGroupId/:taskId/:stepId',
    name: 'Question',
    component: Question,
    props: true
  },
  {
    path: '/scores-menu/:gameId/:breakoutRoomId/', // Choose which of the following scores screens to show
    name: 'ScoresMenu',
    component: ScoresMenu,
    props: true
  },
  {
    path: '/breakout-room-scores/:gameId/:breakoutRoomId/:fromMenu', // Scores team vs team within your breakout room
    name: 'BreakoutRoomScores',
    component: BreakoutRoomScores,
    props: true
  },
  {
    path: '/team-scores/:gameId/:breakoutRoomId/:section', // Detailed scores for your team including person vs person within your team
    name: 'TeamScores',
    component: TeamScores,
    props: true
  },
  {
    path: '/overall-scores/:gameId/:breakoutRoomId', // Scores across breakout rooms, requires all BR games to finish before ready
    name: 'OverallScores',
    component: OverallScores,
    props: true
  },
  {
    path: '/game-stats/:gameId',
    name: 'GameStats',
    component: GameStats,
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
