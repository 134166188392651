<template>

  <div class="w-2/3 mx-auto">
    <p v-if="!team.players.includes(currentPlayer.id) && !(team.players.length >= team.maxPlayerCount)" @click="joinTeam(team.id)" class="p-2 mb-4 font-semibold border border-black rounded-3xl cursor-pointer hover:bg-green-500 hover:text-white hover:border-green-600 transition ease-in-out">Join this team!</p>
    <p v-if="team.players.includes(currentPlayer.id) && !(team.players.length >= team.maxPlayerCount)" @click="leaveTeam(team.id)" class="p-2 mb-4 font-semibold border border-green-500 text-white bg-green-500 rounded-3xl cursor-pointer">Leave Team</p>
    <div v-if="team.players.length >= team.maxPlayerCount" @click="showTeamFullModal = true" class="p-2 mb-4 font-semibold border border-black rounded-3xl bg-gray-200 cursor-pointer">This team is full!</div>
  </div>

  <vue-final-modal v-model="showTeamFullModal" 
    classes="flex justify-center items-center" 
    content-class="flex flex-col space-y-7 items-center w-11/12 md:w-1/3 lg:w-1/4 xl:w-1/5 px-6 py-6 bg-white rounded-3xl border-2 shadow border-gray-700">
      <div class="flex flex-col items-center space-y-2">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 9V12.75M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM12 15.75H12.0075V15.7575H12V15.75Z" stroke="#E53E3E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <h3 class="font-semibold text-lg">Unable to join!</h3>
      </div>
      <p class="px-4 text-base">This team is currently full! Please select another team!</p>
      <button @click="showTeamFullModal = false" class="rounded-full py-3 px-5 font-semibold border-2 border-black">Back to Team Select</button>
  </vue-final-modal>

  <div :class="[mobileView ? 'p-10' : 'p-6', 'flex flex-col h-full w-full border rounded-2xl border-gray-300 space-y-4 team-box']">
    <p class="font-semibold">Team {{ alphabet[idx] }}</p>
    
    <div class="text-2xl font-bold">
      <div v-if="team.players.includes(currentPlayer.id)" class="flex items-center justify-center pl-2">
        <input type="text" 
                v-bind:value="team.name"
                v-bind:disabled="!teamEditMode"
                @blur="submitTeamName()"
                @keyup.enter="enterTeamName()"
                ref="teamEdit"
                class="bg-transparent text-center font-bold outline-none" />
        <!-- Pencil icon to edit team name -->
        <span class="hidden md:block text-sm cursor-pointer relative -left-2 pencil" @click="toggleTeamEditMode()">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-5 h-5">
            <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
          </svg>
        </span>
      </div>
      
      <p v-else>{{ team.name }}</p>
    </div>

    <p v-if="team.maxPlayerCount" class="font-bold">Spaces available: {{ team.maxPlayerCount - team.players.length }}</p>
    <p v-else class="h-16"></p>

    <div class="grow">
      <div v-if="team.players.length > 0" :class="[ mobileView ? 'flex flex-col space-y-4' : 'grid grid-cols-2 auto-rows-auto gap-4', 'mt-3']">
        <div v-for="playerId in currentPlayerFirst(team.players)" v-bind:key="playerId" :set="teamPlayer = getPlayerById(playerId)">
          <div v-if="teamPlayer" class="flex">
            <div>
              <p :class="[playerId == currentPlayer.id ? 'space-taken-current' : 'space-taken-other']" 
                  class="align-middle h-12 w-12 text-sm text-center leading-loose lg:leading-normal rounded-full p-3.5 flex justify-center items-center font-semibold">
                {{ teamPlayer.initials }}
              </p>
            </div>
            <div class="flex flex-col ml-3 my-auto text-sm">
              <p class="text-left">
                {{ teamPlayer.name }}
                <span v-if="playerId == currentPlayer.id"> (you)</span>
              </p>
            </div>
          </div>
        </div>
        <div v-if="team.players.length < team.maxPlayerCount" class="flex">
          <div>
            <p class="text-left align-middle space-available h-12 w-12 text-sm rounded-full p-3 lg:p-4 flex justify-center items-center font-semibold">-</p>
          </div>
          <p class="text-left ml-3 my-auto text-sm">Space Available</p>
        </div>
      </div>
      <div v-else :class="[mobileView ? 'grid-cols-1' : 'grid-cols-2', 'grid auto-rows-auto gap-4 mt-3']">
        <div class="flex">
          <div>
            <p class="text-left align-middle space-available h-12 w-12 text-sm rounded-full p-3 lg:p-4 flex justify-center items-center font-semibold">-</p>
          </div>
          <p class="text-left ml-3 my-auto text-sm">Space Available</p>
        </div>
        <div class="flex">&nbsp;</div>
      </div>
    </div>
  </div>
  
</template>

<script>
  import { mapGetters, mapState, mapMutations } from "vuex";
  import { getTeamRefByDocumentId, updateTeamName } from "../../firebase";
  import { onSnapshot } from "@firebase/firestore";

  export default {
    name: "MobileShowTeam",
    props: ["joinTeam", "leaveTeam", "team", "idx", "currentPlayerFirst", "mobileView"],
    data() {
      return {
        teamEditMode: false,
        unsubTeamState: Function,
        teamRef: null,
        showTeamFullModal: false,
        alphabet: []
      }
    },
    mounted() {
      this.generateAlphabet();
    },
    computed: {
      ...mapGetters(["getPlayerById"]),
      ...mapState({
        currentPlayer: state => state.playersModule.currentPlayer
      })
    },
    async created() {
      this.teamRef = await getTeamRefByDocumentId(this.team.id)
      this.setupTeamListener()
    },  
    beforeUnmount() {
      this.unsubTeamState()
    },
    methods: {
      ...mapMutations(["UPDATE_TEAM_NAME"]),

      toggleTeamEditMode() {
        this.teamEditMode = !this.teamEditMode
        
        setTimeout(() => {
          if(this.teamEditMode == true) this.$refs.teamEdit.focus() 
        }, 200) 
      },

      enterTeamName() {
        this.$refs.teamEdit.blur()
      },

      async submitTeamName() {
        await updateTeamName(this.team.id, this.$refs.teamEdit.value)
      },

      setupTeamListener() {
        this.unsubTeamState = onSnapshot(this.teamRef, (doc) => {
          if (!doc.data() || doc.data().name == this.team.name) return

          this.UPDATE_TEAM_NAME({
            teamId: this.team.id,
            name: doc.data().name,
          })
          this.toggleTeamEditMode()
        })
      },

      generateAlphabet() {
        const startChar = 'A'; // Change this to the starting letter you want
        const endChar = 'Z'; // Change this to the ending letter you want
        for (let i = startChar.charCodeAt(0); i <= endChar.charCodeAt(0); i++) {
          this.alphabet.push(String.fromCharCode(i));
        }
      }
    }
  }
</script>

<style scoped>
  .team-box {
    background-color: #F7FAFC;
  }
  .space-available {
    background: #d2d2d2;
  }
  .space-taken-current {
    background: #55C0AB;
    color: white;
  }
  .space-taken-other {
    color: #55C0AB;
    background: #E0F3EC;
  }
  .pencil {
    color: #55C0AB;
  }
</style>