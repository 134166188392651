<template>
  <div v-if="currentGameState" class="flex flex-col w-1/2 mx-auto space-y-4">
    <div v-if="currentPlayerAnswerLoaded">
      <h2 v-if="answeredCorrectly" class="font-bold text-xl">CORRECT!</h2>
      <h2 v-else class="font-bold text-xl">Better luck next time!</h2>
    </div>

    <div
      v-for="answer in $store.getters.getAnswersForStepId(stepId)"
      :key="answer.text"
      class="space-y-4"
    >
      <div
        class="p-2"
        :class="[
          answer.isCorrect ? 'bg-green-300' : 'bg-red-300',
          'rounded-xl',
          'font-semibold',
        ]"
      >
        {{ answer.text }}
      </div>
      <div v-if="players" class="flex flex-row justify-evenly">
        <div v-for="response in responses" :key="response.id">
          <p v-if="answer.id === response.answerId && response.playerId !== null" class="rounded-full p-4 px-5">
            {{ $store.getters.getPlayerById(response.playerId).initials }}
          </p>
        </div>
      </div>
    </div>
  </div>

  <h1 class="font-bold">LEADERBOARD</h1>

  <div v-if="!currentGame.teamsEnabled" class="mx-auto">
    <PlayerLeaderboard />
  </div>

  <div v-if="currentGame.teamsEnabled" class="mx-auto">
    <TeamLeaderboard />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import {
  query,
  collection,
  where,
  orderBy,
  onSnapshot,
} from "@firebase/firestore";
import { db } from "../../firebase";
import { getFunctions, httpsCallable } from "@firebase/functions";
import TeamLeaderboard from './TeamLeaderboard.vue';
import PlayerLeaderboard from './PlayerLeaderboard.vue';

export default {
  name: "QuestionResults",
  components: {
    PlayerLeaderboard,
    TeamLeaderboard,
  },
  props: ["gameId", "stepId", "playerCount"],
  data() {
    return {
      showQuestionResult: false,
      unsubGameState: Function,
      answeredCorrectly: false,
      currentPlayerAnswerLoaded: false,
      answerSentToStatsPipeline: false,
      nextQuestionTriggerDelay: 4750,
      gameEndingEarlyWarning: false,
    };
  },
  computed: {
    ...mapState({
      currentPlayer: state => state.playersModule.currentPlayer,
      players: state => state.playersModule.players,
      currentGame: state => state.gameActionsModule.currentGame,
      currentGameLoaded: state => state.gameActionsModule.currentGameLoaded,
      currentGameState: state => state.gameStateModule.currentGameState,
      responses: state => state.responsesModule.responses,
    }),
    ...mapGetters(["getPlayers", "getPlayerResponse", "getAnswerOptionById"]),
  },
  watch: {
    currentPlayerAnswerLoaded: function () {
      // if (val == true && this.answerSentToStatsPipeline == false)
        // this.submitAnswerToStatsPipeline(); uncomment later
    },
  },
  created() {
    // listening for other players' answers
    const playerList = this.getPlayers();

    const q = query(
      collection(db, "responses"),
      where("gameId", "==", this.gameId),
      where("stepId", "==", this.stepId),
      where(
        "playerId",
        "in",
        playerList.map((pl) => pl.id)
      ),
      orderBy("timeTaken", "desc")
    );
    this.unsubGameState = onSnapshot(q, (docs) => {
      let responseItems = [];
      docs.forEach((doc) => {
        const response = {
          id: doc.id,
          answerId: doc.data().answerId,
          gameId: doc.data().gameId,
          playerId: doc.data().playerId,
          stepId: doc.data().stepId,
          timeTaken: doc.data().timeTaken,
        };
        responseItems.push(response);
      });
      this.UPDATE_STEP_RESPONSES(responseItems);

      if (!this.currentPlayerAnswerLoaded) {
        let response = this.playerResponse();
        this.answeredCorrectly =
          response &&
          response.answerId !== null &&
          this.getAnswerOptionById({
            stepId: this.stepId,
            answerId: response.answerId,
          }).isCorrect;
        this.currentPlayerAnswerLoaded = true;
      }

      if (
        this.playerCount > 0 &&
        responseItems.length > 0 &&
        responseItems.length >= this.playerCount &&
        responseItems.at(0).playerId == this.currentPlayer.id
      ) {
        // Give the final player some time to see
        // the QuestionResults before triggering the next step
        window.setTimeout(() => {
          this.NEXT_STEP({
            gameId: this.gameId,
            currentStepId: this.stepId,
          });
        }, this.nextQuestionTriggerDelay);
      }
    });
  },
  beforeUnmount() {
    this.unsubGameState();
  },
  methods: {
    ...mapMutations(["UPDATE_STEP_RESPONSES"]),
    ...mapActions(["NEXT_STEP"]),

    showScores() {
      this.showQuestionResult = !this.showQuestionResult;
    },

    playerResponse() {
      return this.getPlayerResponse(
        this.currentPlayer.id,
        this.stepId
      );
    },

    submitAnswerToStatsPipeline() {
      const response = this.playerResponse();
      const functions = getFunctions();
      const playerAnsweredStep = httpsCallable(functions, "playerAnsweredStep");
      playerAnsweredStep({
        playerId: this.currentPlayer.id,
        stepId: this.stepId,
        gameId: this.gameId,
        isCorrect: this.answeredCorrectly,
        answerId: response.answerId,
        newScore: this.currentPlayer.score,
      });

      this.answerSentToStatsPipeline = true;
    },
  },
};
</script>