<template>
  <div class="space-y-3 md:space-y-5 mt-4 md:mt-3">
    <p class="text-base text-center">It is currently</p>
    <h2 class="font-bold text-2xl lg:text-3xl text-center">Your Turn</h2>
    
    <div class="mt-4 lg:mt-6" v-if="canDisplayTimer">
      <Countdown :timerSeconds="timer" :colour="fontColour" :waitingMessage="'Finishing round...'" />
    </div>

    <ShowPrompt
      v-if="currentPrompt"
      :prompt="prompt"
      :respondToPrompt="respondToPrompt"
      :spectatorView="false"
      :tooLate="tooLate"
      :step="step"
      :promptNumber="promptNumber"
      :team="team"
    />
  </div>
</template>

<script>
import ShowPrompt from './ShowPrompt.vue';
import Countdown from './Countdown.vue';

export default {
  name: "ActorPromptQuestion",
  props: [
    "player", 
    "team", 
    "prompt", 
    "respondToPrompt", 
    "tooLate", 
    "timer", 
    "currentPrompt", 
    "canDisplayTimer",
    "step",
    "previewTeamName",
    "fontColour",
    "promptNumber"
  ],
  components: {
    ShowPrompt,
    Countdown
  }
}
</script>
