import {
  getTeamByDocumentId,
  addPlayerToTeam,
  removePlayerFromTeam,
  getUserByDocumentId
} from '../../firebase'

const teamsModule = {
  state: () => ({
    teams: [],
  }),
  getters: {
    getTeamForPlayer: (state) => (playerId) => {
      return state.teams.find(team => {
        return team.players.some(pid => pid == playerId)
      })
    },
    getTeams: (state) => {
      return state.teams
    },
    getTeamById: (state) => (teamId) => {
      return state.teams.find(team => team.id == teamId);
    },
    getIfPlayerIsInTeam: (state) => (playerId) => {
      return state.teams.map(team => team.players).flat().some((pid) => pid === playerId)
    },
    getTeamIdForNextStep: (state, getters, rootState, rootGetters) => (breakoutRoomId) => {
      const gameState = rootGetters.getCurrentGameState
      const teamsHaveAnsweredStep = rootGetters.getTeamsWhoHaveAnsweredCurrentStep(gameState.currentStep)
      // we only care about teams in the current breakoutRoom here
      const breakoutRoom = rootGetters.getBreakoutRoomById(breakoutRoomId)
      const teamIdsInCurrentBreakoutRoom = breakoutRoom.teamIds

      return teamIdsInCurrentBreakoutRoom.filter(t => !teamsHaveAnsweredStep.includes(t))[0]
    }
  },
  mutations: {
    ADD_OR_SET_TEAM(state, payload) {
      if (!state.teams.some((team) => team.id === payload.id)) {
        state.teams.push(payload)
      } else {
        const index = state.teams.findIndex((team) => team.id == payload.id)
        state.teams[index] = payload
      }
    },
    UPDATE_TEAM_STATES(state, payload) {
      state.teams = payload;
    },
    UPDATE_TEAM_NAME(state, payload) {
      const teamIndex = state.teams.findIndex(team => team.id == payload.teamId)
      state.teams[teamIndex].name = payload.name
    },
    UPDATE_TEAM_PLAYERS(state, payload) {
      const teamIndex = state.teams.findIndex(team => team.id == payload.teamId)
      state.teams[teamIndex].players = payload.players
    },
    CLEAR_TEAMS(state) {
      state.teams = []
    },
    UPDATE_TEAM_SCORE(state, payload) {
      const teamIndex = state.teams.findIndex(team => team.id == payload.teamId)
      state.teams[teamIndex].score += payload.pointsAwarded
    },
    SET_TEAM_PLAYER_PLAYED(state, payload) {
      const teamIndex = state.teams.findIndex(team => team.id == payload.teamId)
      const team = state.teams[teamIndex]
  
      team.playersPlayed.indexOf(payload.playerId) === -1 ? team.playersPlayed.push(payload.playerId) : null
    }
  },
  actions: {
    async FETCH_TEAM({ commit }, { teamId, getPlayers=false }) {
      const teamDoc = await getTeamByDocumentId(teamId);
      const data = {
        id: teamId,
        name: teamDoc.data().name,
        hexColour: teamDoc.data().hexColour,
        players: teamDoc.data().players,
        playersPlayed: teamDoc.data().playersPlayed,
        score: teamDoc.data().score,
        minPlayerCount: teamDoc.data().minPlayerCount,
        maxPlayerCount: teamDoc.data().maxPlayerCount,
        breakoutRoomId: teamDoc.data().breakoutRoomId,
      }

      if(getPlayers) {
        const players = [];
        for (const playerId of teamDoc.data().players) {
          const userDoc = await getUserByDocumentId(playerId)
          if (userDoc.exists()) {
            const userData = {
              id: userDoc.id,
              name: userDoc.data().name,
              initials: userDoc.data().initials,
              score: userDoc.data().score
            }
            players.push(userData)
          }
        }
        // console.log(players)
        data.fullPlayers = players
      }

      commit("ADD_OR_SET_TEAM", data)
    },

    async ADD_PLAYER_TO_TEAM({ commit }, { playerId, teamId }) {
      const teamDoc = await getTeamByDocumentId(teamId);
      const data = {
        teamId: teamId,
        players: teamDoc.data().players,
      }
  
      data.players.indexOf(playerId) === -1 ? data.players.push(playerId) : null
      commit("UPDATE_TEAM_PLAYERS", data)
      await addPlayerToTeam(teamId, playerId)
    },
        
    async REMOVE_PLAYER_FROM_TEAM({ commit }, { playerId, teamId }) {
      const teamDoc = await getTeamByDocumentId(teamId);
      const data = {
        teamId: teamId,
        players: teamDoc.data().players.filter(player => player !== playerId),
      }
  
      commit("UPDATE_TEAM_PLAYERS", data)
      await removePlayerFromTeam(teamId, playerId)
    },

    UPDATE_PLAYERS_PLAYED_FOR_TEAM({ commit }, { playerId, teamId }) {
      commit("SET_TEAM_PLAYER_PLAYED", { teamId: teamId, playerId: playerId })
    }
  },
}

export default teamsModule