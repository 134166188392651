import { checkIntoStep } from '../firebase'

const FirebaseCheckIn = {
  data() {
    return {
      checkingInInterval: null,
      checkedInCount: 0,
      intervalSeconds: 6
    }
  },
  beforeUnmount() {
    clearInterval(this.checkingInInterval)
  },
  methods: {
    async startCheckingIn(stepId, playerId, gameId, breakoutRoomId, stepLength=120) {
      const timesToCheckIn = Math.round((stepLength + this.intervalSeconds) / this.intervalSeconds) + 1
  
      this.checkingInInterval = window.setInterval(async () => {
        await checkIntoStep(stepId, playerId, gameId, breakoutRoomId)
        
        if(this.checkedInCount == timesToCheckIn) {
          clearInterval(this.checkingInInterval)
        } else {
          this.checkedInCount++
        }
      }, this.intervalSeconds * 1000)
    }
  }
}

export default FirebaseCheckIn