<template>
  <footer class="mb-auto pb-10 md:pb-5 pt-16 w-full flex text-sm" v-if="footerReady">
    <p class="flex mx-auto text-xs justify-center items-center space-x-4 text-gray-700 border rounded-md px-6 border-gray-400 bg-white font-bold">
      <button @click="showExitGameModal = true" class="font-bold">Exit Game</button>
      <span class="text-lg font-normal relative">|</span>
      <button @click="showLogoutModal = true" class="font-bold">Log Out</button>
    </p>
  </footer>

  <vue-final-modal v-model="showExitGameModal" 
    classes="flex justify-center items-center" 
    content-class="flex flex-col space-y-7 items-center w-11/12 md:w-1/3 lg:w-1/4 xl:w-1/5 px-6 py-6 bg-white rounded-3xl border-2 shadow border-gray-700">
      <h3 class="font-semibold text-lg">Exit Game?</h3>
      <p class="px-6 text-base">Are you sure you want to leave the game?</p>
      <router-link :to="{ name: 'ExitGame' }" class="rounded-full w-1/2 py-3 px-5 font-semibold border-2 border-black text-sm">Exit game</router-link>
      <button @click="showExitGameModal = false" class="text-red-500 text-sm font-semibold underline hover:no-underline">Cancel</button>
  </vue-final-modal>

  <vue-final-modal v-model="showLogoutModal" 
    classes="flex justify-center items-center" 
    content-class="flex flex-col space-y-7 items-center w-11/12 md:w-1/2 lg:w-1/4 xl:w-1/4 px-6 py-6 bg-white rounded-3xl border-2 shadow border-gray-700">
      <h3 class="font-semibold text-lg">Log Out?</h3>
      <p class="px-4 text-base">Are you sure you want to exit the game and completely log out?</p>
      <router-link :to="{ name: 'Logout' }" class="rounded-full w-1/2 py-3 px-5 font-semibold border-2 border-black text-sm">Log Out</router-link>
      <button @click="showLogoutModal = false" class="text-red-500 text-sm font-semibold underline hover:no-underline">Cancel</button>
  </vue-final-modal>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: "GameplayFooter",
  data() {
    return {
      showExitGameModal: false,
      showLogoutModal: false,
      footerReady: false
    }
  },
  created() {
    setTimeout(() => this.footerReady = true, 2000)
  },
  computed: {
    ...mapState({
      currentPlayer: state => state.playersModule.currentPlayer,
    })
  }
}
</script>

<style scoped>
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #555;
  border-radius: 0.25rem;
  background: #fff;
}
</style>
