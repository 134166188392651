export function compareAnsweredAt(a, b) {
  if (a.answeredAt < b.answeredAt) {
    return -1
  }
  if (a.answeredAt > b.answeredAt) {
    return 1
  }
  return 0
}

export function gameStepMap(key) {
  switch(key) {
    case "charades":
      return "PromptQuestion"
    case "whoami":
      return "PromptQuestion"
    case "taboo":
      return "PromptQuestion"
    case "instruction":
      return "Instruction"
    default:
      return "Question"
  }
}