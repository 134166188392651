<template>
  <div class="flex flex-col space-y-24">
    <Header />
    <div v-if="showGameCodeForm" class="flex flex-1 flex-col h-full mx-auto md:mt-6 content-evenly space-y-16 lg:space-y-24">
      <div class="space-y-4">
        <p class="font-bold mx-4">Enter the game code below to join the lobby!</p>
        <input
          type="text"
          class="border-2 mx-4 rounded-xl p-6 text-center"
          style="background: #E0F3EC"
          v-model="game.code"
          @keyup.enter="confirmCode()"
        />
      </div>

      <button class="py-2 mx-4 font-semibold rounded-full border bg-white cursor-pointer" @click="confirmCode()">Confirm and Join</button>
    </div>

    <UserForm v-if="!showGameCodeForm" @loggedIn="ready" />
  </div>
</template>

<script>
import UserForm from "../components/pre_game/UserForm.vue";
import Header from "../components/Header";
import { addPlayerToGameState, createBlankSkippedResponses } from '../firebase'
import { mapState, mapActions, mapGetters } from "vuex";
import { getFunctions, httpsCallable } from "@firebase/functions";

export default {
  components: {
    UserForm,
    Header,
  },
  name: "Login",
  data() {
    return {
      currentPlayerLoaded: false,
      showGameCodeForm: true,
      game: {
        code: "",
      },
    };
  },
  created() {
    this.loadCurrentPlayer();
    this.detectUrlGameCode()
  },
  async beforeMount() {
    await this.EXIT_GAME_EARLY({ teamId: null });
  },
  computed: {
    ...mapState({
      currentPlayer: state => state.playersModule.currentPlayer,
      currentGame: state => state.gameActionsModule.currentGame
    }),
    ...mapGetters(["getStepById", "getCurrentGameSteps", "getCurrentGameId", "getCurrentPlayer", "getCurrentGameState"]),
  },
  methods: {
    ...mapActions(["EXIT_GAME_EARLY", "GET_GAME", "GET_GAME_STATE"]),

    loadCurrentPlayer() {
      this.getCurrentPlayer;
      if (Object.keys(this.currentPlayer).length != 0) this.currentPlayerLoaded = true
    },

    detectUrlGameCode() {
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      })
      if (params.code) this.game.code = params.code
    },

    async loadGameState(gameId) {
      await this.GET_GAME_STATE({ gameId: gameId });
    },

    async confirmCode() {
      if (this.game.code.length == 0) return alert("Please enter a game code");

      try {
        await this.GET_GAME({ gameCode: this.game.code })
        const gameId = this.getCurrentGameId;

        // if no breakout Rooms, fetch the gameState and add player
        if (!this.currentGame.breakoutRoomsEnabled) {
          await addPlayerToGameState(gameId, null, this.currentPlayer.id);
          await this.loadGameState(gameId);
        }
      } catch (TypeError) {
        console.log(TypeError)
        // this.submitAttemptToStatsPipeline(null); uncomment later
        alert("You have entered an incorrect game code, please try again.");
        return
      }

      if (this.currentPlayerLoaded) {
        this.ready()
      } else {
        this.showGameCodeForm = false
      }
    },

    submitAttemptToStatsPipeline(gameId) {
      const functions = getFunctions();
      const playerEntersGameCode = httpsCallable(
        functions,
        "playerEntersGameCode"
      );
      playerEntersGameCode({
        playerId: this.currentPlayer.id,
        gameCode: this.game.code,
        gameId: gameId,
        status: gameId == null ? "fail" : "success",
      });
    },

    ready() {
      const gameId = this.getCurrentGameId
      // this.submitAttemptToStatsPipeline(gameId); uncomment later
      this.redirect(gameId)
    },

    async redirect(gameId) {
      if (this.currentGame.breakoutRoomsEnabled) {
        return this.$router.push({ name: "BreakoutRoomLobby", params: { gameId: gameId } });
      } else {
        const currentGameState = this.getCurrentGameState;
        if (currentGameState.status == "lobby") {
          return this.$router.push({ name: "PlayerLobby", params: { gameId: gameId } });
        } else if (currentGameState.status == "started") {
          const currentStep = this.getStepById(
            currentGameState.currentStep
          );
          if (currentStep.position > 1) {
            // add 'skipped' blank responses if joining game after question 1
            await createBlankSkippedResponses(
              gameId,
              currentStep,
              this.getCurrentGameSteps,
              this.currentPlayer
            );
          }
          return this.$router.push({
            name: "Question",
            params: {
              gameId: currentGameState.gameId,
              taskGroupId: currentGameState.currentTaskGroup,
              taskId: currentGameState.currentTask,
              stepId: currentGameState.currentStep,
            },
          });
        } else if (currentGameState.status == "pending") {
          alert("This game not started yet dude!");
        } else if (currentGameState.status == "ended") {
          alert("This game be over dude!");
        }
      }
    },
  },
};
</script>