<template>
  <section class="pt-16 md:pt-20 lg:pt-12 xl:pt-8 pb-20 px-6 md:px-20 lg:px-32 relative">
    <router-link class="text-base absolute top-3 md:top-10 left-3 md:left-5" 
                :to="{ name: 'ScoresMenu' }"><span class='text-xl font-bold mr-2'>&#8592;</span> Score Selection</router-link>

    <header class="space-y-4">
      <h2 class="text-center text-3xl font-semibold">How your team performed</h2>
  
      <p class="text-center px-4 md:px-0">
        Here you can see your total points, round breakdowns and individual scores.
      </p>
    </header>

    <!-- Tabs -->
    <div class="font-medium text-center md:mt-10 max-w-lg md:max-w-full min-w-0">
      <div class="flex md:justify-center md:space-x-8 overflow-x-auto md:overflow-x-visible md:w-full pt-8">
        <div class="flex-none p-4">
          <router-link class="inline-block rounded-t-lg md:pb-3 md:border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
              :class="{'selected_tab': section == 'overall'}"
              :to="{ name: 'TeamScores', params: { gameId: gameId, breakoutRoomId: breakoutRoomId, section: 'overall' } }">Overall team performance</router-link>
        </div>
        <div class="flex-none p-4">
          <router-link class="inline-block rounded-t-lg md:pb-3 md:border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
              :class="{'selected_tab': section == 'correct_and_skipped'}"
              :to="{ name: 'TeamScores', params: { gameId: gameId, breakoutRoomId: breakoutRoomId, section: 'correct_and_skipped' } }">Correct and Skipped</router-link>
        </div>
        <div class="flex-none p-4">
          <router-link class="inline-block rounded-t-lg md:pb-3 md:border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
              :class="{'selected_tab': section == 'individual'}"
              :to="{ name: 'TeamScores', params: { gameId: gameId, breakoutRoomId: breakoutRoomId, section: 'individual' } }">Individual performances</router-link>
        </div>
      </div>
    </div>
    <!-- End Tabs -->

    <!-- Overall team performance -->
    <div v-if="section == 'overall'">
      <div v-if="teamResponsesLoaded" 
          class="flex flex-col w-full md:w-3/4 lg:w-1/2 correct_box border-2 p-8 rounded-2xl space-y-6 mx-auto mt-12">
        <h2 class="font-semibold text-3xl pb-6">{{ currentTeam.name }}</h2>
        <p class="text-4xl font-bold black-font text-gray-800">{{ currentTeam.score }} Points!</p>
        <p class="text-4xl font-bold black-font text-gray-800">You got {{ correctAnswersCount }} answer<span v-if="correctAnswersCount > 1 || correctAnswersCount == 0">s</span> correct</p>
        <p class="text-xl">Out of a possible {{ allTeamAnswersCount }}</p>
        <p class="text-5xl">👏</p>
      </div>

      <div v-else class="py-32 text-xl">
        Loading overall team performance.....
      </div>
    </div>
    <!-- End Overall team performance -->

    <!-- Correct and Skipped -->
    <div v-else-if="section=='correct_and_skipped'">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mx-auto mt-6 md:mt-12 px-4 lg:px-16 xl:px-32" v-if="teamResponsesLoaded">
  
        <div class="rounded-3xl px-6 md:px-8 pb-2 md:pb-10 pt-3 md:pt-6 border-2 correct_box">
          <h3 class="mb-3 md:mb-5 text-xl font-semibold">Correct</h3>
          <ul v-if="correctAnswersForTeam.length > 0" class="md:py-6 list-disc pl-3 grid grid-cols-1 lg:grid-cols-2 gap-y-3 gap-x-6">
            <li class="list-item text-left text-lg mb-2 md:mb-3" 
                v-for="(answer) in correctAnswersForTeam" 
                :key="answer">{{answer}}</li>
          </ul> 
          <p v-else class="text-lg px-4">😭 Your team did not get any correct answers</p>
        </div>
  
        <div class="rounded-3xl px-6 md:px-8 pb-2 md:pb-10 pt-3 md:pt-6 border-2 incorrect_box">
          <h3 class="mb-3 md:mb-5 text-xl font-semibold">Skipped</h3>
        
          <ul v-if="skippedAnswersForTeam.length > 0" class="md:py-6 list-disc pl-3 grid grid-cols-1 lg:grid-cols-2 gap-y-3 gap-x-6">
            <li class="list-item text-left text-lg mb-2 md:mb-3" 
                v-for="(answer) in skippedAnswersForTeam" 
                :key="answer">{{answer}}</li>
          </ul>
          <p v-else class="text-lg px-4">🥳 Wow, you did not skip any answers!!</p>
        </div>
      </div>
      
      <div v-else class="py-32 text-xl">
        Loading correct and skipped answers.....
      </div>
    </div>
    <!-- End Correct and Skipped -->

    <!-- Individual -->
    <div v-if="section == 'individual'">
      <div v-if="teamResponsesLoaded">
        <div class="bg-gray-100 border border-gray-300 text-lg rounded-lg w-full md:w-3/4 lg:w-1/2 mx-auto mt-6 md:mt-12 lg:mt-8 xl:mt-12 relative">
          <select v-model="selectedPlayerId"
                  class="w-full  px-10 py-3 text-center">
            <option v-bind:value="currentPlayer.id" selected>
              {{currentPlayer.name}} (you)
            </option>
            <option v-for="(playerId) in allOtherTeamPlayers" 
                    :set="player = getPlayerById(playerId)"
                    v-bind:key="playerId"
                    v-bind:value="player.id">
              {{ player.name }}
            </option>
          </select>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mx-auto mt-8 md:mt-16 lg:mt-10 xl:mt-16 px-4 md:px-4 lg:px-20 xl:px-32">
          <div class="rounded-3xl px-8 pb-2 md:pb-10 pt-3 md:pt-6 border-2 correct_box">
            <h3 class="mb-3 md:mb-5 text-xl font-semibold">Correct</h3>
            <ul v-if="correctAnswersForSelectedPlayer.length > 0" class="md:py-6 list-disc pl-3 grid grid-cols-1 lg:grid-cols-2 gap-y-3 gap-x-6">
              <li class="list-item text-left text-lg mb-2 md:mb-3" 
                  v-for="(answer) in correctAnswersForSelectedPlayer" 
                  :key="answer">{{answer}}</li>
            </ul>
            <p v-else class="text-lg px-4">😭 You did not get any correct answers.</p>          
          </div>

          <div class="rounded-3xl px-8 pb-2 md:pb-10 pt-3 md:pt-6 border-2 incorrect_box">
            <h3 class="mb-3 md:mb-5 text-xl font-semibold">Skipped</h3>
          
            <ul v-if="skippedAnswersForSelectedPlayer.length > 0" class="md:py-6 list-disc pl-3 grid grid-cols-1 lg:grid-cols-2 gap-y-3 gap-x-6">
              <li class="list-item text-left text-lg mb-2 md:mb-3" 
                  v-for="(answer) in skippedAnswersForSelectedPlayer" 
                  :key="answer">{{answer}}</li>
            </ul>
            <p v-else class="text-lg px-4">🥳 Wow, you did not skip any answers!!</p>
          </div>
        </div>
      </div>
      <div v-else class="py-32 text-xl">
        Loading individual player answers.....
      </div>
    </div>
    <!-- End Individual -->

    <NotificationsCallout :gameId="gameId" :breakoutRoomId="breakoutRoomId" :screenType="'podium'" />
    <GameplayFooter />
  </section>

  <GameResetWatcher :breakoutRoomId="breakoutRoomId" />
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import GameplayFooter from "../../components/game/GameplayFooter.vue";
import NotificationsCallout from "../../components/game/NotificationsCallout.vue";
import GameResetWatcher from "../../components/post_game/GameResetWatcher.vue";

export default {
  name: "TeamScores",
  components: {
    GameplayFooter,
    GameResetWatcher,
    NotificationsCallout
},
  data() {
    return {
      responses: [],
      teamResponsesLoaded: false,
      selectedPlayerId: null,
    }
  },
  props: ["gameId", "breakoutRoomId", "section"],
  async created() {
    await this.loadGameState()
    await this.LOAD_PLAYER_RESPONSES({ gameId: this.gameId, breakoutRoomId: this.breakoutRoomId })
    this.responses = this.getAllResponses
    this.selectedPlayerId = this.currentPlayer.id
    this.teamResponsesLoaded = true
  },
  computed: {
    ...mapGetters([
      "getTeamForPlayer", 
      "getAllResponses", 
      "getPromptById",
      "getPlayerById"
    ]),
    ...mapState({
      currentGame: state => state.gameActionsModule.currentGame,
      currentGameState: state => state.gameStateModule.currentGameState,
      historicalPlayers: state => state.playersModule.historicalPlayers,
      currentPlayer: state => state.playersModule.currentPlayer
    }),

    currentTeam() {
      return this.getTeamForPlayer(this.currentPlayer.id)
    },

    correctAnswersCount() {
      return this.allTeamAnswers.filter(a => a.correct).length
    },

    allTeamAnswers() {
      return this.responses.filter(a => a.teamId == this.currentTeam.id)
    },

    allTeamAnswersCount() {
      return this.allTeamAnswers.length
    },

    correctAnswersForTeam() {
      const responses = this.allTeamAnswers.filter((a) => a.correct && a.status == 'answered')
      const prompts = responses.map((r) => this.getPromptById(r.promptId))      
      const promptTexts = prompts.map((p) => {
        return p ? p.text : null
      })
      return promptTexts.filter((pt) => pt !== null)
    },
    
    skippedAnswersForTeam() {
      const responses = this.allTeamAnswers.filter((a) => !a.correct && a.status == 'answered')
      const prompts = responses.map((r) => this.getPromptById(r.promptId))      
      const promptTexts = prompts.map((p) => {
        return p ? p.text : null
      })
      return promptTexts.filter((pt) => pt !== null)
    },

    allOtherTeamPlayers() {
      return this.currentTeam.players.filter((pl) => pl !== this.currentPlayer.id)
    },

    correctAnswersForSelectedPlayer() {
      const responses = this.allTeamAnswers.filter((a) => a.playerId == this.selectedPlayerId && a.correct && a.status == 'answered')
      const prompts = responses.map((r) => this.getPromptById(r.promptId))      
      const promptTexts = prompts.map((p) => {
        return p ? p.text : null
      })
      return promptTexts.filter((pt) => pt !== null)
    },

    skippedAnswersForSelectedPlayer() {
      const responses = this.allTeamAnswers.filter((a) => a.playerId == this.selectedPlayerId && !a.correct && a.status == 'answered')
      const prompts = responses.map((r) => this.getPromptById(r.promptId))      
      const promptTexts = prompts.map((p) => {
        return p ? p.text : null
      })
      return promptTexts.filter((pt) => pt !== null)
    }
    
  },
  methods: {
    ...mapActions(["GET_GAME_STATE", "LOAD_PLAYER_RESPONSES"]),

    async loadGameState() {
      await this.GET_GAME_STATE({ gameId: this.gameId, breakoutRoomId: this.breakoutRoomId, getHistorical: true });
    }
  },
};
</script>

<style scoped>
  .selected_tab {
    border-color: #02BA94;
    font-weight: bold;
  }
  .incorrect_box {
    background: #FCE6E6;
    border-color: #feb3b2;
  }
  .correct_box {
    background: #DFF3EC;
    border-color: #55c0aa;
  }
  select {
    border-right: 16px solid transparent;
    background: transparent;
  }
</style>