<template>
  <div class="grid grid-cols-3 py-8 items-center">
    <button v-if="backButton" class="flex items-center space-x-1 rounded-xl font-semibold ml-3 lg:ml-4 w-18" @click="backButton">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.75 15.75L3 12M3 12L6.75 8.25M3 12H21" stroke="#0F172A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <p class="text-sm md:text-base">{{ backButtonText }}</p>
    </button>

    <div v-else class="w-18"></div>

    <p class="mt-2 text-2xl font-semibold">{{ title }}</p>

    <div class="w-18"></div>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: ["backButton", "backButtonText", "title"]
}
</script>