<template>
  <div class="flex flex-col">

    <div class="grid grid-cols-1 md:hidden mx-auto">
      <carousel :items-to-show="1.1" :wrap-around="false">
        <slide v-for="(team, idx) in filterTeamsForBreakoutRoom" :key="team.id" class="flex flex-col space-y-3 mx-1">
          <ShowTeam :team="team" :idx="idx" :joinTeam="joinTeam" :leaveTeam="leaveTeam" :currentPlayerFirst="currentPlayerFirst" :mobileView="true" />
        </slide>

        <template #addons>
          <Navigation />
          <Pagination />
        </template>
      </carousel>
    </div>

    <div class="hidden md:grid grid-cols-2 gap-x-6 gap-y-28 auto-rows-fr">
      <div v-for="(team, idx) in filterTeamsForBreakoutRoom" v-bind:key="team.id" class="space-y-6">
        <ShowTeam :team="team" :idx="idx" :joinTeam="joinTeam" :leaveTeam="leaveTeam" :currentPlayerFirst="currentPlayerFirst" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import { query, collection, where, onSnapshot } from "@firebase/firestore";
import { db } from '../../firebase';
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import ShowTeam from "./ShowTeam.vue";

export default {
  name: "TeamList",
  components: {
    Carousel,
    Slide,
    Pagination,
    ShowTeam,
    Navigation
  },
  props: {
    showName: Boolean,
    showScore: Boolean,
    gameId: String,
    breakoutRoomId: String,
  },
  data() {
    return {
      unsubTeamStates: Function,
    }
  },
  computed: {
    ...mapState({
      currentPlayer: state => state.playersModule.currentPlayer,
      players: state => state.playersModule.players,
      teams: state => state.teamsModule.teams
    }),

    ...mapGetters(["getPlayerById", "getIfPlayerIsInTeam", "getBreakoutRoomById"]),

    filterTeamsForBreakoutRoom() {
      return this.teams.filter(team => this.getBreakoutRoomById(this.breakoutRoomId).teamIds.includes(team.id))
    },
  },
  async created() {
    const q = query(
      collection(db, "teams"),
      where("gameId", "==", this.gameId),
      where("breakoutRoomId", "==", this.breakoutRoomId)
    )
    this.unsubTeamStates = onSnapshot(q, (docs) => {
      docs.forEach((doc) => {
        const teamData = {
          teamId: doc.id,
          players: doc.data().players,
        }
        this.UPDATE_TEAM_PLAYERS(teamData)
      })
    });
  },
  beforeUnmount() {
    this.unsubTeamStates();
  },
  methods: {
    ...mapMutations(["UPDATE_TEAM_PLAYERS"]),
    ...mapActions(["REMOVE_PLAYER_FROM_TEAM", "ADD_PLAYER_TO_TEAM"]),

    async joinTeam(teamId) {
      const currentTeam = this.teams.find(team => team.players.includes(this.currentPlayer.id))
      if (currentTeam) this.REMOVE_PLAYER_FROM_TEAM({ playerId: this.currentPlayer.id, teamId: currentTeam.id })
      this.ADD_PLAYER_TO_TEAM({ playerId: this.currentPlayer.id, teamId: teamId })
    },

    async leaveTeam(teamId) {
      this.REMOVE_PLAYER_FROM_TEAM({ playerId: this.currentPlayer.id, teamId: teamId })
    },

    currentPlayerFirst(playerIdList) {
      if (playerIdList.some(playerId => playerId == this.currentPlayer.id)) {
        const otherPlayers = playerIdList.filter(playerId => playerId != this.currentPlayer.id)
        let returnList = []
        returnList[0] = this.currentPlayer.id
        otherPlayers.forEach((playerId) => {
          returnList.push(playerId)
        })
        return returnList
      } else {
        return playerIdList
      }
    }
  }
}
</script>