import {
  getAllPlayerResponses,
  getPlayerResponses,
} from "../../firebase"

const gameStatsModule = {
  state: () => ({
    gameStats: {},
    gameStatsLoaded: false,
    currentPlayerGameStats: {},
  }),
  mutations: {
    UPDATE_GAME_STATS(state, payload) {
      state.gameStats = payload
    },
    GAME_STATS_LOADED(state) {
      state.gameStatsLoaded = true
    },
    CLEAR_GAME_STATS(state) {
      state.gameStats = {}
      state.gameStatsLoaded = false
    },
    UPDATE_PLAYER_GAME_STATS(state, payload) {
      state.currentPlayerGameStats = payload
    },
  },
  actions: {
    async CALCULATE_GAME_STATS({ commit, getters, rootState }, { gameId }) {
      const stepCount = getters.getCurrentGameSteps.length
      let responses = rootState.responsesModule.responses
      if (responses.length != stepCount * rootState.gameStateModule.currentGameState.historicalPlayers.length) {
        responses = await getAllPlayerResponses(gameId)
      }

      function correctResponse(r) {
        if (r.status == "answered" && getters.getAnswerOptionById({ stepId: r.stepId, answerId: r.answerId }).isCorrect) {
          return r
        }
      }

      function sortResponses(a, b) {
        if (a.averageTime < b.averageTime) {
          return -1
        }
        if (a.averageTime > b.averageTime) {
          return 1
        }
        return 0
      }

      let correctResponses = responses.map(correctResponse)
      correctResponses = correctResponses.filter((x) => x !== undefined)
      let averageTimes = []
      const respondedCorrectly = []

      rootState.gameStateModule.currentGameState.historicalPlayers.forEach((pl) => {
        const playerResponses = correctResponses.filter((res) => res.playerId == pl)
        const averageTime = playerResponses.reduce(function (a, b) { return a + b.timeTaken }, 0) / playerResponses.length
        averageTimes.push({ playerId: pl, averageTime: averageTime })
        respondedCorrectly.push({ playerId: pl, correctResponseCount: playerResponses.length })
      })
      const averageTimeToRespond = correctResponses.reduce(function (a, b) { return a + b.timeTaken }, 0) / correctResponses.length
      const averageCorrectResponses = respondedCorrectly.reduce(function (a, b) { return a + b.correctResponseCount }, 0) / rootState.gameStateModule.currentGameState.historicalPlayers.length
      averageTimes.sort(sortResponses)
      let data = {
        fastestPlayer: averageTimes[0].playerId,
        slowestPlayer: averageTimes[averageTimes.length - 1].playerId,
        averageTimeToRespond: Math.round((averageTimeToRespond / 1000) * 100) / 100,
        averageCorrectResponses: averageCorrectResponses,
        totalStepCount: stepCount
      }
      commit("UPDATE_GAME_STATS", data)
      commit("GAME_STATS_LOADED")
    },
    async CALCULATE_PLAYER_GAME_STATS({ commit, getters, rootState }, { gameId, playerId }) {
      const stepCount = getters.getCurrentGameSteps.length
      let responses = rootState.responsesModule.responses.filter((res) => res.playerId === playerId)
      if (responses.length != stepCount) {
        responses = await getPlayerResponses(gameId, playerId)
      }

      function correctResponse(r) {
        if (r.status == "answered" && getters.getAnswerOptionById({ stepId: r.stepId, answerId: r.answerId }).isCorrect) {
          return r
        }
      }

      function sortResponsesByTime(a, b) {
        if (a.timeTaken < b.timeTaken) {
          return -1
        }
        if (a.timeTaken > b.timeTaken) {
          return 1
        }
        return 0
      }

      let correctResponses = responses.map(correctResponse).filter((x) => x !== undefined)
      let fastestResponse = responses.sort(sortResponsesByTime)[0]
      let fastestQuestion = getters.getStepById(fastestResponse.stepId).question
      let fastestTime = Math.round((fastestResponse.timeTaken / 1000) * 100) / 100;
      let slowestResponse = responses.sort(sortResponsesByTime)[responses.length - 1]
      let slowestQuestion = getters.getStepById(slowestResponse.stepId).question
      let slowestTime = Math.round((slowestResponse.timeTaken / 1000) * 100) / 100;
      const averageTime = responses.reduce(function (a, b) { return a + b.timeTaken }, 0) / responses.length
      const data = {
        correctAnswers: correctResponses.length,
        fastestQuestion: fastestQuestion,
        fastestTime: fastestTime,
        slowestQuestion: slowestQuestion,
        slowestTime: slowestTime,
        averageTimeToAnswer: Math.round(averageTime * 100) / 100,
        stepCount: stepCount
      }
      commit("UPDATE_PLAYER_GAME_STATS", data)
    },
  }
}

export default gameStatsModule