import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist';
import teamsModule from "./modules/teams"
import gameStatsModule from "./modules/game_stats"
import gameActionsModule from "./modules/game_actions"
import playersModule from './modules/players';
import responsesModule from "./modules/responses";
import gameStateModule from "./modules/game_state";
import promptsModule from './modules/prompts';
import breakoutRoomsModule from './modules/breakout_rooms';
import notificationsModule from './modules/notifications';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export const store = createStore({
  plugins: [vuexLocal.plugin],
  modules: {
    teamsModule,
    gameStatsModule,
    gameActionsModule,
    playersModule,
    responsesModule,
    gameStateModule,
    promptsModule,
    breakoutRoomsModule,
    notificationsModule,
  },
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
});