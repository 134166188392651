import {
  getCharadesPrompts,
  getWhoAmIPrompts,
  getTabooPrompts,
} from "../../firebase"

const promptsModule = {
  state: () => ({
    prompts: [],
  }),
  getters: {
    getUnansweredPrompt: (state, getters) => (category) => {
      const promptIdsWithResponses = getters.getAllResponses.map(res => res.promptId)
      const unansweredPrompts = state.prompts.filter((pr) => {
        return pr.categories.includes(category) && !promptIdsWithResponses.includes(pr.id)
      });
      // console.log(`unansweredPrompts`, unansweredPrompts)
      return unansweredPrompts[Math.floor(Math.random() * unansweredPrompts.length)]
    },
    getPromptById: (state) => (promptId) => {
      return state.prompts.find(pr => pr.id === promptId)
    },
  },
  mutations: {
    ADD_PROMPT(state, payload) {
      if (!state.prompts.some(prompt => prompt.id === payload.id)) {
        state.prompts.push(payload)
      }
    },
    CLEAR_PROMPTS(state) {
      state.prompts = []
    }
  },
  actions: {
    async FETCH_CHARADES_PROMPTS({ commit }, { category }) {
      const prompts = await getCharadesPrompts(category)
      prompts.forEach(prompt => commit("ADD_PROMPT", prompt))
    },
    async FETCH_WHO_AM_I_PROMPTS({ commit }, { category }) {
      const prompts = await getWhoAmIPrompts(category)
      prompts.forEach(prompt => commit("ADD_PROMPT", prompt))
    },
    async FETCH_TABOO_PROMPTS({ commit }, { category }) {
      const prompts = await getTabooPrompts(category)
      prompts.forEach(prompt => commit("ADD_PROMPT", prompt))
    }
  }
}

export default promptsModule;