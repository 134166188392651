<template>
  <section class="pt-16 md:pt-8 px-10 md:px-28 lg:px-16 xl:px-28 relative">
    <router-link class="text-base absolute top-3 md:top-10 left-3 md:left-5" 
                :to="{ name: 'ScoresMenu' }"><span class='text-xl font-bold mr-2'>&#8592;</span> Score Selection</router-link>

    <header class="space-y-4">
      <h2 class="text-center text-3xl font-semibold">Overall Scores</h2>
  
      <p class="text-center px-16 md:px-0">
        <span v-if="allGamesHaveFinished">How did you compare with the other teams today? Did you win it all?</span>
        <span v-else>We're still waiting for all breakout rooms to finish but here are the rankings so far...</span>
      </p>
    </header>

    <div class="flex flex-col w-11/12 lg:w-2/3 xl:w-1/2 mx-auto mt-10">
      <div v-if="collectedAllTeamScores" class="space-y-6">
        <!-- 1st Place -->
        <div class="flex flex-col w-full border-2 px-8 py-8 rounded-3xl relative"
              :class="{'border-gray-300 bg-gray-100': !containsCurrentPlayersTeam(sortedTeams[winningScore]), 'current-team-box': containsCurrentPlayersTeam(sortedTeams[winningScore])}">
          <p class="absolute top-3 right-5 text-xl font-semibold">1st</p>

          <img class="w-24 md:w-32 absolute -top-6 md:-top-8 -left-10 md:-left-12 transform -rotate-12" 
            src="https://wildgoose-production.s3.amazonaws.com/emu-resources/gold-trophy.png" 
            alt="1st Place"
            id="gold_trophy" />

          <img class="hidden md:block w-12 md:w-12 absolute top-28 -left-16" 
            src="https://wildgoose-production.s3.amazonaws.com/emu-resources/party-popper.png" 
            alt="Party popper" />

          <img class="hidden md:block w-12 md:w-10 absolute top-3 -right-14" 
            src="https://wildgoose-production.s3.amazonaws.com/emu-resources/clapping-hands.png" 
            alt="Clapping Hands" />

          <img class="hidden md:block w-16 md:w-12 absolute top-28 -right-16" 
            src="https://wildgoose-production.s3.amazonaws.com/emu-resources/ok-hand.png" 
            alt="OK Hand" />

          <div v-if="sortedTeams[winningScore].length > 1">
            <h2 class="font-semibold text-xl md:text-2xl px-12 md:px-16 lg:px-14 pb-6">
              <span v-if="allGamesHaveFinished">Teams {{ humaniseTeamNames(sortedTeams[winningScore]) }} you are joint winners!</span>
              <span v-else>Teams {{ humaniseTeamNames(sortedTeams[winningScore]) }} you are currently joint 1st!</span>
            </h2>
            <p class="text-2xl md:text-3xl font-bold black-font text-gray-800">They scored {{ singleWinningTeam.score }} points</p>
          </div>
  
          <div v-else>
            <h2 class="font-semibold text-xl md:text-2xl px-12 md:px-16 lg:px-14 pb-6">
              <span v-if="allGamesHaveFinished">Team {{ singleWinningTeam.name }} are the overall winners! Well done!</span>
              <span v-else>Team {{ singleWinningTeam.name }} from {{ getBreakoutRoomForTeam(singleWinningTeam.id).name }} are currently in the lead!</span>
            </h2>
            <p class="text-2xl md:text-3xl font-bold black-font text-gray-800">They scored {{ singleWinningTeam.score }} points</p>
            
            <ul class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 text-sm px-10 md:px-0 xl:px-8 mt-4 md:mt-10">
              <li v-for="(player) in fullPlayersForTeam(singleWinningTeam)" 
                  v-bind:key="player.id" 
                  class="flex items-center justify-start space-x-2">
                <div class="rounded-full flex items-center justify-center w-10 h-10 px-4 py-2 font-bold"
                      :class="{ 
                        'player-initials': currentPlayer.id != player.id && !isCurrentTeam(singleWinningTeam), 
                        'current-team-initials': currentPlayer.id != player.id && isCurrentTeam(singleWinningTeam),
                        'current-player-initials': currentPlayer.id == player.id
                      }">
                  {{ player.initials }}
                </div>
                <span class="pl-1 text-black">
                  {{ player.name }}
                  <span v-if="currentPlayer.id == player.id">&nbsp;(you)</span>
                </span>
              </li>
            </ul>

          </div>
        </div>
        
        <!-- Others -->
        <div v-for="(score, index) in teamScores.slice(1, teamScores.length)" :key="score" :set="teamList = sortedTeams[score]">
          <div v-if="teamList.length > 0" class="flex flex-col w-full border-2 px-8 py-8 rounded-3xl relative"
            :class="{'border-gray-300 bg-gray-100': !containsCurrentPlayersTeam(teamList), 'current-team-box': containsCurrentPlayersTeam(teamList)}"
            :set="rank = index + 2">
            <p class="absolute top-3 right-5 text-xl font-semibold">{{ordinalize(rank)}}</p>
            
            <img v-if="rank == 2" 
                class="w-20 md:w-20 lg:w-18 xl:w-20 absolute -top-4 -left-6 md:top-10 md:left-4 transform -rotate-12 md:rotate-0" 
                src="https://wildgoose-production.s3.amazonaws.com/emu-resources/silver-trophy.png" 
                alt="2nd Place"
                id="silver_trophy" />

            <img class="hidden md:block w-12 md:w-12 absolute top-16 -left-16" v-if="rank == 2"
                src="https://wildgoose-production.s3.amazonaws.com/emu-resources/clapping-hands.png" 
                alt="Clapping Hands" />

            <img class="hidden md:block w-12 md:w-12 absolute top-16 -right-16" v-if="rank == 2"
                src="https://wildgoose-production.s3.amazonaws.com/emu-resources/party-popper.png" 
                alt="Party popper" />  

            <img v-if="rank == 3" 
                class="w-20 md:w-20 lg:w-18 xl:w-20 absolute -top-4 -left-6 md:top-10 md:left-4 transform -rotate-12 md:rotate-0" 
                src="https://wildgoose-production.s3.amazonaws.com/emu-resources/bronze-trophy.png" 
                alt="3rd Place"
                id="bronze_trophy" />

            <img class="hidden md:block w-12 md:w-12 absolute top-16 -left-16" v-if="rank == 3"
                src="https://wildgoose-production.s3.amazonaws.com/emu-resources/ok-hand.png" 
                alt="OK Hand" />

            <img class="hidden md:block w-12 md:w-12 absolute top-16 -right-16" v-if="rank == 3"
                src="https://wildgoose-production.s3.amazonaws.com/emu-resources/thumbs-up.png" 
                alt="Thumbs Up" />  

            <h2 class="font-semibold text-xl md:text-2xl px-12 md:px-16 lg:px-14 pb-6">Team<span v-if="teamList.length > 1">s</span> {{ humaniseTeamNames(teamList) }}</h2>
            <p class="text-2xl md:text-3xl font-bold black-font text-gray-800">They scored {{ teamList[0].score }} points!</p>
          </div>
        </div>

      </div>
    </div>
  </section>

  <NotificationsCallout :gameId="gameId" :breakoutRoomId="breakoutRoomId" :screenType="'podium'" />
  <GameplayFooter />
  <GameResetWatcher :breakoutRoomId="breakoutRoomId" />
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import {
  query,
  collection,
  where,
  onSnapshot
} from "@firebase/firestore";
import { db } from "../../firebase";
import GameplayFooter from "../../components/game/GameplayFooter.vue";
import NumberUtils from '@/mixins/NumberUtils'
import _ from 'lodash'
import GameResetWatcher from "../../components/post_game/GameResetWatcher.vue";
import NotificationsCallout from "../../components/game/NotificationsCallout.vue";

export default {
  name: "OverallScores",
  props: ["gameId", "breakoutRoomId"],
  data() {
    return {
      allGamesHaveFinished: false,
      collectedAllTeamScores: false,
      unsubGameStatesListener: Function,
      unsubTeamsListener: Function,
      sortedTeams: [],
      teamScores: []
    };
  },
  mixins: [NumberUtils],
  components: { GameplayFooter, GameResetWatcher, NotificationsCallout },
  computed: {
    ...mapState({
      currentPlayer: state => state.playersModule.currentPlayer,
      teams: state => state.teamsModule.teams,
      breakoutRooms: state => state.breakoutRoomsModule.breakoutRooms,
      players: state => state.playersModule.players,
    }),
    ...mapGetters(["getPlayerById", "getBreakoutRoomForTeam", "getTeamForPlayer"]),

    currentPlayersTeam() {
      return this.getTeamForPlayer(this.currentPlayer.id)
    },

    winningScore() {
      return this.teamScores[0]
    },

    scores() {
      return _.keys(this.sortedTeams).sort
    },

    singleWinningTeam() {
      return this.sortedTeams[this.winningScore][0]
    }
  },
  async created() {
    this.setupGamesListener();
    this.setupTeamsListener();
  },
  beforeUnmount() {
    this.unsubGameStatesListener();
    this.unsubTeamsListener();
  },
  methods: {
    ...mapMutations(["UPDATE_TEAM_STATES"]),
    ...mapActions(["FETCH_USER"]),

    isCurrentTeam(team) {
      const currentTeam = this.getTeamForPlayer(this.currentPlayer.id)
      return currentTeam ? (currentTeam.id == team.id) : false
    },

    async setupGamesListener() {
      const q = query(collection(db, "gameStates"), where("gameId", "==", this.gameId));
      this.unsubGameStatesListener = onSnapshot(q, (docs) => {
        const totalGames = this.breakoutRooms.length; // should be total number of games being played
        let totalGamesFinished = 0;
        docs.forEach(gameStateDoc => {
          if (gameStateDoc.data().status == "ended")
          totalGamesFinished++;
        });
        if (totalGamesFinished >= totalGames) {
          this.allGamesHaveFinished = true;
          this.unsubGameStatesListener();
        }
      });
    },

    async setupTeamsListener() {
      const q = query(collection(db, "teams"), where("gameId", "==", this.gameId));
      this.unsubTeamsListener = onSnapshot(q, (docs) => {
        let teams = [];
        docs.forEach((teamDoc) => {
          const team = {
            id: teamDoc.id,
            breakoutRoomId: teamDoc.data().breakoutRoomId,
            hexColour: teamDoc.data().hexColour,
            name: teamDoc.data().name,
            players: teamDoc.data().players,
            score: teamDoc.data().score,
            minPlayerCount: teamDoc.data().minPlayerCount,
            maxPlayerCount: teamDoc.data().maxPlayerCount,
          };
          teams.push(team);
        });
        this.UPDATE_TEAM_STATES(teams);
        this.sortTeamsByScore();
        this.fetchPlayersForWinningTeam();
        this.collectedAllTeamScores = true;
      });
    },
    async fetchPlayersForWinningTeam() {
      if (this.sortedTeams)
      this.singleWinningTeam.players.forEach(playerId => this.FETCH_USER({ playerId: playerId }));
    },
    fullPlayersForTeam(team) {
      const list = team.players.map((playerId) => {
        const player = this.getPlayerById(playerId)
        return player ? player : null
      })
      return list.filter((pl) => pl !== null)
    },

    compare(a, b) {
      if (a.score < b.score) {
        return -1;
      }
      if (a.score > b.score) {
        return 1;
      }
      return 0;
    },

    sortTeamsByScore() {
      const sortedTeams = this.teams.sort(this.compare).reverse();
      const groupedTeams = _.groupBy(sortedTeams, 'score')
      this.teamScores = _.keys(groupedTeams).sort(this.compare).reverse()
      this.sortedTeams = Object.keys(groupedTeams).reverse().reduce((acc, key) => { acc[key] = groupedTeams[key]; return acc }, {})
    },

    humaniseTeamNames(teams) {
      return teams.map(el => el.name).join(", ").replace(/,(?=[^,]+$)/, ' and')
    },

    containsCurrentPlayersTeam(teamList) {
      const teamIds = teamList.map((t) => t.id)
      return this.currentPlayersTeam ? _.includes(teamIds, this.currentPlayersTeam.id) : false
    },
  }
}
</script>