import { initializeApp } from '@firebase/app';
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCCIHxLvwoS_qaDnseiIOC7otTosVquO5o",
  authDomain: "v3-prototype-5e34f.firebaseapp.com",
  projectId: "v3-prototype-5e34f",
  storageBucket: "v3-prototype-5e34f.appspot.com",
  messagingSenderId: "1019241280689",
  appId: "1:1019241280689:web:cc54da1b7b19359282de90",
  measurementId: "G-DERPYPECGE"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Sign into Firebase
const firebaseAuth = getAuth();

export { firebaseApp, firebaseAuth }
