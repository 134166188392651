import {
  getAllPlayerResponses,
  addPlayerResponse,
  updatePlayerScore,
  updateTeamScore,
} from "../../firebase"
import { Timestamp } from "firebase/firestore";
import { compareAnsweredAt } from "./utils";

const responsesModule = {
  state: () => ({
    responses: [],
  }),
  getters: {
    getAllResponses: (state) => {
      return state.responses;
    },
    getPlayerResponse: (state) => (playerId, stepId) => {
      return state.responses.filter(r => r.playerId === playerId && r.stepId === stepId)[0];
    },
    getPlayerPromptResponse: (state) => (playerId, stepId, promptId) => {
      return state.resposes.filter(r => r.playerId == playerId && r.stepId == stepId && r.promptId == promptId)[0];
    },
    getPromptResponsesForStepAndTeamId: (state) => (stepId, teamId, correct) => {
      return state.responses.filter(r => r.stepId == stepId && r.teamId == teamId && r.correct == correct && r.status == 'answered').sort(compareAnsweredAt)
    },
    getTeamsWhoHaveAnsweredCurrentStep: (state) => (stepId) => {
      const teamDupes = state.responses.filter(res => res.stepId == stepId).map(res => res.teamId) // will contain dupes
      return [...new Set(teamDupes)]
    },
    getAllTeamResponses: (state) => (teamId) => {
      return state.responses.filter(res => res.teamId == teamId)
    },
  },
  mutations: {
    CLEAR_RESPONSES(state) {
      state.responses = []
    },
    UPDATE_STEP_RESPONSES(state, payload) {
      state.responses = payload
    },
    ADD_PROMPT_RESPONSE(state, payload) {
      state.responses.push(payload)
    },
  },
  actions: {
    async LOAD_PLAYER_RESPONSES({ commit }, { gameId, breakoutRoomId }) {
      const responses = await getAllPlayerResponses(gameId, breakoutRoomId)
      commit("UPDATE_STEP_RESPONSES", responses)
    },
    async RESPOND_TO_STEP({ commit, getters, rootState }, { gameId, teamId, playerId, answerId, stepId, timeTaken }) {
      // Quiz type rounds
      let pointsAwarded = 0
      if (answerId !== null && getters.getAnswerOptionById({ stepId: stepId, answerId: answerId }).isCorrect) {
        let points = Math.round(1000 - (timeTaken / rootState.gameActionsModule.currentGame.secondsPerStep))
        if (points > 0) {
          pointsAwarded = points
        }
      }

      const data = {
        gameId: gameId,
        answerId: answerId,
        playerId: playerId,
        teamId: teamId,
        stepId: stepId,
        timeTaken: timeTaken,
        status: answerId === null ? 'unanswered' : 'answered',
        pointsAwarded: pointsAwarded,
      };

      commit("UPDATE_PLAYER_SCORE", pointsAwarded)
      await addPlayerResponse(data);
      await updatePlayerScore(playerId, pointsAwarded);

      if (getters.getIsTeamGame && teamId !== undefined) {
        commit("UPDATE_TEAM_SCORE", { teamId: teamId, pointsAwarded: pointsAwarded })
        await updateTeamScore(teamId, pointsAwarded)
      }
    },
    async RESPOND_TO_PROMPT({ commit, getters }, {
        gameId, 
        teamId, 
        playerId, 
        promptId, 
        stepId, 
        correctStatus, 
        answeredStatus, 
        breakoutRoomId}
      ) {
      // Charades, Taboo and WhoAmI type rounds
      let pointsAwarded;
      if (answeredStatus == "answered") {
        pointsAwarded = getters.getPromptById(promptId).pointsAwarded
      } else {
        pointsAwarded = 0
      }

      const data = {
        gameId: gameId,
        playerId: playerId,
        stepId: stepId,
        promptId: promptId,
        teamId: teamId,
        correct: correctStatus,
        status: answeredStatus,
        pointsAwarded: pointsAwarded,
        breakoutRoomId: breakoutRoomId,
        answeredAt: Timestamp.now()
      }

      commit("ADD_PROMPT_RESPONSE", data)
      commit("UPDATE_PLAYER_SCORE", pointsAwarded)
      await addPlayerResponse(data);
      await updatePlayerScore(playerId, pointsAwarded)

      if (getters.getIsTeamGame && teamId !== undefined) {
        commit("UPDATE_TEAM_SCORE", { teamId: teamId, pointsAwarded: pointsAwarded })
        await updateTeamScore(teamId, pointsAwarded)
      }
    },
  }
}

export default responsesModule;