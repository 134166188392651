<template>
  <p class="font-semibold text-2xl text-white">{{ playersDisplay }} still waiting to join a team</p>
</template>

<script>
export default {
  name: "PlayerCountdown",
  props: {
    players: Array
  },
  computed: {
    playersDisplay() {
      const n = this.players.length;
      if (n <= 2) {
        return `${this.players.map(player => player.name).join(' and ')} ${n === 1 ? 'is' : 'are'}`;
      }
      return `${this.players[0].name}, ${this.players[1].name} and ${n - 2} ${n - 2 === 1 ? 'other is' : 'others are'}`;
    },
  },
}
</script>