<template>
  <div class="flex flex-col justify-center w-full"
        :style="{'color': fontColour}">
    <div class="w-full text-center">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="inline-block w-8 h-8 md:w-10 md:h-10 mb-1">
        <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
    </div>
  
    <p v-if="timerSeconds > 0" class="font-semibold text-xl md:text-2xl">{{ timerFormatted }}</p>
    <div v-else-if="waitingMessage"  class="font-semibold text-xl md:text-2xl" v-html="waitingMessage"></div>
  </div>
</template>

<script>
  export default {
    name: 'Countdown',
    props: ["timerSeconds", "colour", "waitingMessage"],
    computed: {
      timerFormatted() {
        let minutes = Math.floor(this.timerSeconds / 60)
        if(minutes < 10) minutes = `0${minutes}`
        let seconds = Math.floor(this.timerSeconds % 60)
        if(seconds < 10) seconds = `0${seconds}`
        return `${minutes}:${seconds}`
      },

      fontColour() {
        return this.colour && this.colour.length > 0 ? this.colour : '#444444'
      }
    }
  }
</script>