<template>
  <section class="pt-16 md:pt-12 pb-20 px-10 md:px-32 lg:px-28 xl:px-32 relative">
    <header class="space-y-4">
      <h2 class="text-center text-3xl font-semibold">View scores!</h2>
  
      <p class="text-center px-16 md:px-0">
        How did you compare with the other teams today?<br />
        Did you win it all? Want to see how you did in the head to head again?
      </p>
    </header>

    <ul class="grid grid-cols-1 lg:grid-cols-3 gap-6 mt-16 lg:mt-32">
      <!-- Team Scores -->
      <li class="rounded-xl menu-item border-2 text-gray-800">
        <router-link class="flex flex-col p-6 lg:p-10 h-full w-full items-center justify-between space-y-8 lg:space-y-12" 
                      :to="{ name: 'TeamScores', params: { gameId: gameId, breakoutRoomId: breakoutRoomId, section: 'overall' } }">
          <p class="text-xl">My team's scores</p>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-12 h-12">
            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
          </svg>            
        </router-link>
      </li>

      <!-- Overall Scores -->
      <li v-if="currentGame.overallScoresLocked" class="rounded-xl locked-menu-item border-2 text-gray-800">
        <div class='flex flex-col p-6 lg:p-10 h-full w-full items-center justify-between space-y-8 lg:space-y-12'>
          <p class="text-xl">Overall scores</p>
  
          <p>Your event host will present these when all breakout rooms are ready!</p>
  
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-12 h-12">
            <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
          </svg>        
        </div>
      </li>
      <li v-else class="relative rounded-xl menu-item border-2 text-gray-800 top-0 transition-all duration-700 ease-in-out"
          :class="{'lg:-top-12': allGamesHaveFinished }">
        <router-link class="flex flex-col p-6 lg:p-10 h-full w-full items-center justify-between space-y-8 lg:space-y-12" 
                      :to="{ name: 'OverallScores', params: { gameId: gameId, breakoutRoomId: breakoutRoomId } }">
          <p class="text-xl">Overall scores</p>
          
          <p v-if="allGamesHaveFinished">
            All teams have completed the event!<br />Click here to view overall scores.
          </p>
          <p v-else>
            We are waiting for {{ gamesUnfinishedCount }} breakout room<span v-if="gamesUnfinishedCount > 1">s</span> to complete their game<span v-if="gamesUnfinishedCount > 1">s</span>
          </p>
          
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-12 h-12">
            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
          </svg>            
        </router-link>
      </li>

      <!-- Breakout Room Scores -->
      <li class="rounded-xl menu-item border-2 text-gray-800">
        <router-link class="flex flex-col p-6 lg:p-10 h-full w-full items-center justify-between space-y-8 lg:space-y-12" 
                      :to="{ name: 'BreakoutRoomScores', params: { gameId: gameId, breakoutRoomId: breakoutRoomId, fromMenu: true } }">
          <p class="text-xl">My breakout room scores</p>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-12 h-12">
            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
          </svg>            
        </router-link>
      </li>
    </ul>

  </section>

  <NotificationsCallout :gameId="gameId" :breakoutRoomId="breakoutRoomId" :screenType="'podium'" />
  <GameplayFooter />
  <GameResetWatcher :breakoutRoomId="breakoutRoomId" />
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import {
  query,
  collection,
  where,
  onSnapshot
} from "@firebase/firestore";
import { db, getGameRefByDocumentId } from "../../firebase";
import GameplayFooter from "../../components/game/GameplayFooter.vue";
import GameResetWatcher from "../../components/post_game/GameResetWatcher.vue";
import NotificationsCallout from "../../components/game/NotificationsCallout.vue";

export default {
  name: "ScoresMenu",
  components: {
    GameplayFooter,
    GameResetWatcher,
    NotificationsCallout
},
  props: ["gameId", "breakoutRoomId"],
  data() {
    return {
      allGamesHaveFinished: false,
      gamesFinished: [],
      gameRef: null,
      unsubGameStatesListener: Function,
      unsubGameListener: Function
    }
  },
  async created() {
    await this.loadGameState();
    this.gameRef = await getGameRefByDocumentId(this.gameId)

    this.setupListeners();
  },
  beforeUnmount() {
    this.unsubGameStatesListener()
    this.unsubGameListener()
  },
  computed: {
    ...mapState({
      currentGame: state => state.gameActionsModule.currentGame,
      currentGameState: state => state.gameStateModule.currentGameState,
      historicalPlayers: state => state.playersModule.historicalPlayers,
      breakoutRooms: state => state.breakoutRoomsModule.breakoutRooms
    }),

    gamesUnfinishedCount() {
      return this.breakoutRooms.length - this.gamesFinished.length
    }
  },
  methods: {
    ...mapActions(["GET_GAME_STATE"]),
    ...mapMutations(["UPDATE_OVERALL_SCORES_LOCKED"]),

    async loadGameState() {
      await this.GET_GAME_STATE({ gameId: this.gameId, breakoutRoomId: this.breakoutRoomId, getHistorical: true });
    },

    async setupListeners() {
      this.setupGameStatesListener()
      this.setupGameListener()
    },

    setupGameStatesListener() {
      const q = query(collection(db, "gameStates"), where("gameId", "==", this.gameId));
      this.unsubGameStatesListener = onSnapshot(q, (docs) => {
        docs.forEach(gameStateDoc => {
          if (gameStateDoc.data().status == "ended" && !this.gamesFinished.includes(gameStateDoc.data().breakoutRoomId)) {
            this.gamesFinished.push(gameStateDoc.data().breakoutRoomId);
          }
        });
        if (this.gamesFinished.length >= this.breakoutRooms.length) {
          setTimeout(() => this.allGamesHaveFinished = true, 1000)
          this.unsubGameStatesListener();
        }
      });
    },

    setupGameListener() {
      this.unsubGameListener = onSnapshot(this.gameRef, (doc) => {
        if (!doc.data()) return

        this.UPDATE_OVERALL_SCORES_LOCKED(doc.data()["overallScoresLocked"])
      })
    },

  },
};
</script>

<style>
  .menu-item {
    background: #dff4ec;
    border-color: #1b7865;
  }
  .locked-menu-item {
    background: #edf2f7;
    border-color: #718096;
  }
  .player-initials {
    background: #dff4ec;
    color: #1b7865;
  }
  .current-player-initials {
    background: #222222;
    color: #55c0aa;
  }
  .current-team-initials {
    background: #0f5c4d;
    color: #dff4ec;
  }
  .current-team-box {
    background: #dff4ec;
    border-color: #1b7865;
  }
  .view-all-button {
    background: #07ba94;
    border: 1px solid #1f937c;
  }
</style>