<template>
  <div v-if="stepId" class="flex mx-auto flex-col w-1/2 space-y-4 h-full mb-64">
    <h1 class="text-xl">Question {{ getStepPosition() + 1 }}</h1>
    <h2>{{ $store.getters.getStepById(stepId).question }}</h2>

    <div
      v-for="answer in $store.getters.getAnswersForStepId(stepId)"
      :key="answer.text"
      class="space-y-4"
    >
      <div
        class="p-2"
        :class="[
          answer.isCorrect ? 'bg-green-300' : 'bg-red-300',
          'rounded-xl',
          'font-semibold',
        ]"
      >
        {{ answer.text }}
      </div>
      <div>
        <div
          v-for="response in responses"
          :key="response.playerId"
          class="grid grid-cols-2 gap-y-2 mx-auto"
        >
          <p v-if="answer.id === response.answerId" class="">
            {{ $store.getters.getPlayerById(response.playerId).name }}
          </p>
          <p v-if="answer.id === response.answerId">
            {{ response.timeTaken / 1000 }} secs
          </p>
        </div>
      </div>
    </div>

    <div class="flex flex-row justify-evenly">
      <button
        v-if="getStepPosition() > 0"
        class="text-black border-2 border-black p-2 rounded-md"
        @click="showPreviousStep()"
      >
        Question {{ getStepPosition() }}
      </button>
      <button
        v-if="getStepPosition() < $store.getters.getCurrentGameSteps.length - 1"
        class="text-black border-2 border-black p-2 rounded-md"
        @click="showNextStep()"
      >
        Question {{ getStepPosition() + 2 }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "QuestionStats",
  components: {},
  props: [],
  computed: {
    ...mapState({
      currentPlayer: state => state.playersModule.currentPlayer,
      currentGame: state => state.gameActionsModule.currentGame, 
      responses: state => state.responsesModule.responses,
    }),
    ...mapGetters(["getCurrentGameSteps", "getStepById"]),
  },
  data() {
    return {
      showQuestionResult: false,
      stepId: null,
    };
  },
  created() {
    this.getFirstStep();
  },
  methods: {
    getStepPosition() {
      return this.getCurrentGameSteps.indexOf(
        this.getStepById(this.stepId)
      );
    },

    showPreviousStep() {
      this.stepId =
        this.getCurrentGameSteps[this.getStepPosition() - 1].id;
    },

    showNextStep() {
      this.stepId =
        this.getCurrentGameSteps[this.getStepPosition() + 1].id;
    },

    getFirstStep() {
      this.stepId = this.getCurrentGameSteps[0].id;
    },
  },
};
</script>