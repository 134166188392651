<template>
  <GameEndingEarlyWarning v-if="gameEndingEarlyWarning" />
  
  <div v-if="currentGameLoaded && currentGame && timerLoaded" class="flex flex-col space-y-4">
    <ReloadablePromptQuestion 
      :key="reloadKey"
      :gameId="gameId"
      :breakoutRoomId="breakoutRoomId"
      :taskGroupId="taskGroupId"
      :taskId="taskId"
      :step="step"
      :reRenderFunction="reRenderComponent"
      :timerSeconds="timerSeconds"
      :pauseForDevelopment="pauseForDevelopment"
      :gameEndingEarlyWarning="gameEndingEarlyWarning"
    />
  </div>
</template>

<script>
import ReloadablePromptQuestion from "../components/game/ReloadablePromptQuestion.vue"
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { db, getGameStateRefByBreakoutRoomId, getGameRefByDocumentId, endGameState } from "../firebase";
import { collection, where, query, onSnapshot } from "firebase/firestore";
import { gameStepMap } from "../store/modules/utils"
import GameEndingEarlyWarning from "../components/game/GameEndingEarlyWarning.vue"
import FirebaseCheckIn from '@/mixins/firebase_check_in'

export default {
  name: "PromptQuestion",
  components: {
    ReloadablePromptQuestion,
    GameEndingEarlyWarning,
  },
  props: ["gameId", "breakoutRoomId", "taskGroupId", "taskId", "stepId"],
  computed: {
    pauseForDevelopment: state => state.getGameStatePauseScreenForDevelopment == 'promptQuestion',

    ...mapState({
      currentPlayer: state => state.playersModule.currentPlayer,
      currentGame: state => state.gameActionsModule.currentGame,
      currentGameLoaded: state => state.gameActionsModule.currentGameLoaded,
      currentPrompt: state => state.gameStateModule.currentGameState.currentPrompt,
      currentTeamTurn: state => state.gameStateModule.currentGameState.currentTeamTurn,
      currentPlayerTurn: state => state.gameStateModule.currentGameState.currentPlayerTurn,
      currentStep: state => state.gameStateModule.currentGameState.currentStep,
    }),
    ...mapGetters([
      "getStepById", 
      "getPromptById", 
      "getTeamForPlayer", 
      "getPlayerById", 
      "getTeamById", 
      "getCurrentGameSecondsPerStep",
      "getGameStatePauseScreenForDevelopment",
      "getFullPromptStepTime",
      "getNotificationIsUnread"
    ]),
  },
  data() {
    return {
      reloadKey: 0,
      unsubGameState: Function,
      unsubTeamStates: Function,
      unsubGame: Function,
      playerCount: 0,
      gameStateRef: null,
      gameRef: null,
      gameEndingEarlyWarning: false,
      timerSeconds: 60,
      timerLoaded: false,
      step: null
    };
  },
  mixins: [
    FirebaseCheckIn
  ],
  async created() {
    await this.getCurrentGame();
    this.step = await this.getStepById(this.stepId)    
    this.timerSeconds = await this.setupTimerSeconds()
    this.gameStateRef = await getGameStateRefByBreakoutRoomId(this.breakoutRoomId)
    await this.startCheckingIn(
      this.stepId, 
      this.currentPlayer.id, 
      this.gameId, 
      this.breakoutRoomId,
      this.getFullPromptStepTime(this.stepId)
    )
    this.gameRef = await getGameRefByDocumentId(this.gameId)
    await this.setupListeners();
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.confirmNavChange) 
    // Please don't refresh or fwd/back in the middle of a game
  },
  beforeUnmount() {
    this.unsubGameState()
    this.unsubTeamStates()
    this.unsubGame()
    window.removeEventListener("beforeunload", this.confirmNavChange) 
    // Please don't refresh or fwd/back in the middle of a game
  },
  methods: {
    ...mapActions(["GET_GAME_BY_ID", "SET_CURRENT_TEAM_PLAYER_TURN"]),
    ...mapMutations([
      "SET_CURRENT_GAME_STATE", 
      "UPDATE_TEAM_PLAYERS", 
      "FILTER_PLAYERS_UPDATE",
      "UPDATE_END_GAME_TIME",
    ]),

    async setupListeners() {
      // Listen for game state updates
      this.setupGameStateListener()

      // Listen to see if ppl have been kicked off of a team
      this.setupTeamListener()
    },

    setupGameStateListener() {
      this.unsubGameState = onSnapshot(this.gameStateRef, (doc) => {
        if (!doc.data()) return
        this.playerCount = doc.data().players.length;
        const currentPlayerTurnNotNull = doc.data().currentPlayerTurn != null // If player is set to null we do not want any updates (other than perhaps the understudy to kick in if necessary)

        if (currentPlayerTurnNotNull) {
          // Update the gameState data
          const data = {
            id: doc.id,
            status: doc.data().status,
            players: doc.data().players,
            currentTaskGroup: doc.data().currentTaskGroup,
            currentTask: doc.data().currentTask,
            currentStep: doc.data().currentStep,
            currentStepActorTimerHasFinished: doc.data().currentStepActorTimerHasFinished,
            currentPrompt: doc.data().currentPrompt,
            currentTeamTurn: doc.data().currentTeamTurn,
            currentPlayerTurn: doc.data().currentPlayerTurn,
            currentStepTimestamp: doc.data().currentStepTimestamp,
            gameId: doc.data().gameId,
            teams: doc.data().teams,
          };
          this.SET_CURRENT_GAME_STATE(data);
          this.FILTER_PLAYERS_UPDATE(doc.data().players)
        }

        // Other things that need to happen:-
        if (this.playerCount == 1) {
          // 1. Last player standing
          this.gameEndingEarlyWarning = true;
          window.setTimeout(() => this.endGameEarly(), 5000);
        } else if (doc.data().status == "ended") {
          // 2. Game has ended, trigger podium
          return this.$router.push({
            name: "BreakoutRoomScores",
            params: { 
              gameId: this.gameId, 
              breakoutRoomId: this.breakoutRoomId,
              fromMenu: false
            },
          });
        } else if (doc.data().currentStep != this.stepId) {
          // 3. Game step has moved one
          const stepType = this.getStepById(doc.data().currentStep).stepType
          const screenType = gameStepMap(stepType)
          // move to next step
          return this.$router.push({
            name: screenType,
            params: {
              gameId: this.gameId,
              taskGroupId: doc.data().currentTaskGroup,
              taskId: doc.data().currentTask,
              stepId: doc.data().currentStep,
            },
          });
        } else if(!currentPlayerTurnNotNull && (doc.data().currentUnderstudyTurn == this.currentPlayer.id)) {
          // 4. I have been chosen as the understudy!
          // This means there is an issue with the currentPlayer (Actor) and I 
          // have been chosen by the Cloud Function to choose another actor for the next turn. Proud moment!
          console.warn(`engaging understudy mode`, `promptQuestion`)
          return this.SET_CURRENT_TEAM_PLAYER_TURN({ 
            breakoutRoomId: this.breakoutRoomId, 
            fromStepId: this.step.id, 
            understudyActioned: true 
          })
        } else if(doc.data().understudyMovedGameAhead == true) {
          console.warn(`currentPlayerTurnChanged by the understudy so we are rendering`)
          // 5. currentPlayerTurn has been altered by the understudy so let's re-render
          return this.reRenderComponent()
        }
      });

      this.unsubGame = onSnapshot(this.gameRef, (doc) => {
        if (!doc.data()) return

        this.UPDATE_END_GAME_TIME(doc.data()["gameEndsAt"])
      })
    },

    setupTeamListener() {
      const teamsQuery = query(
        collection(db, "teams"),
        where("gameId", "==", this.gameId),
        where("breakoutRoomId", "==", this.breakoutRoomId)
      )
      this.unsubTeamStates = onSnapshot(teamsQuery, (docs) => {
        docs.forEach((doc) => {
          const teamData = {
            teamId: doc.id,
            players: doc.data().players,
          }
          this.UPDATE_TEAM_PLAYERS(teamData)
        })
      });
    },

    reRenderComponent() {
      this.reloadKey += 1 // important! updating the key forces component reload
    },

    async setupTimerSeconds() {
      let seconds
      if (this.step && this.step.stepTimer) {
        seconds = this.step.stepTimer
      } else {
        seconds = this.getCurrentGameSecondsPerStep
      }
      
      setTimeout(() => this.timerLoaded = true, 750)
      return seconds
    },

    async getCurrentGame() {
      await this.GET_GAME_BY_ID({ gameId: this.gameId });
    },

    async endGameEarly() {
      await endGameState(this.gameId);
      return this.$router.push({
        name: "BreakoutRoomScores",
        params: { 
          gameId: this.gameId, 
          breakoutRoomId: this.breakoutRoomId,
          fromMenu: false
        },
      });
    },

    confirmNavChange(event) { 
      // Please don't refresh or fwd/back in the middle of a game
      event.preventDefault()
      event.returnValue = ""
    }
  }
};
</script>
