<template>
  <div v-if="previewing || currentGameState" class="flex flex-col w-11/12 lg:w-1/2 mx-auto space-y-4 pt-8 md:pt-2">
    <div v-if="actorView || guesserView" class="flex flex-col space-y-4 mx-auto">
      <div v-if="actorView" class="space-y-4">
        <div class="rounded-full bg-black flex items-center justify-center w-12 h-12 text-white px-2 py-1 font-bold mx-auto mb-2 text-lg">
          {{ currentPlayer.initials }}
        </div>    
        <h2 class="font-bold text-2xl lg:text-4xl">{{ currentPlayer.name }}!</h2>
      </div>

      <h2 v-if="guesserView" class="font-bold text-2xl lg:text-4xl">Team {{ currentTeam.name }}!</h2>
      
      <p class="text-lg">Here's your performance last round!</p>
    </div>

    <div v-if="spectatorView" class="flex flex-col space-y-4 mx-auto mb-6">
      <h2 class="font-bold text-4xl">{{ currentTeam.name }} results!</h2>
      
      <p class="text-lg">Here's how they performed this round</p>
    </div>

    <div class="flex flex-col w-full mx-auto">
      <div class="mb-6 space-y-6 md:space-y-0 md:grid md:grid-cols-2 md:gap-6">
        <div class="rounded-3xl px-5 pb-2 md:pb-6 pt-3 md:pt-5 flex flex-col justify-between border-2"
            :style="{
              'color': primaryColours.font,
              'background-color': primaryColours.background,
              'border-color': primaryColours.border
            }">
          <p class="mb-5 text-xl md:text-2xl xl:text-3xl font-bold flex items-center justify-center space-x-4">
            <span>Correct</span> 
            <span class="text-xl">✅</span>
          </p>

          <div v-if="previewing">
            <ul class="md:py-6 list-disc pl-4">
              <li class="list-item">
                <div class="text-xl lg:text-xl mb-2 md:mb-3 flex flex-row justify-between">
                  <span class="text-left">Monkey</span>
                  <span class="text-right">10 pts</span>
                </div>
              </li>
              <li class="list-item">
                <div class="text-xl lg:text-xl mb-2 md:mb-3 flex flex-row justify-between">
                  <span class="text-left">Elephant</span>
                  <span class="text-right">12 pts</span>
                </div>
              </li>
              <li class="list-item">
                <div class="text-xl lg:text-xl mb-2 md:mb-3 flex flex-row justify-between">
                  <span class="text-left">Chicken</span>
                  <span class="text-right">15 pts</span>
                </div>
              </li>
            </ul>
          </div>
          <div v-else>
            <ul v-if="correctAnswers.length > 0" class="md:py-6 list-disc pl-4">
              <li class="list-item" v-for="promptResult in correctAnswers" :key="promptResult.id">
                <div class="text-xl mb-2 md:mb-3 flex flex-row justify-between">
                  <span class="text-left">{{ getPromptById(promptResult.promptId).text }}</span>
                  <span class="text-right">{{ getPromptById(promptResult.promptId).pointsAwarded }}pts</span>
                </div>
              </li>
            </ul>
            <p v-else class="text-xl px-4 mb-3 md:mb-4">😭 No correct answers</p>
          </div>
          <p class="mb-3">{{ totalPoints(true) }} points gained!</p>
        </div>

        <div class="rounded-3xl px-5 pb-2 md:pb-6 pt-3 md:pt-5 flex flex-col justify-between border-2"
            :style="{
              'color': secondaryColours.font,
              'background-color': secondaryColours.background,
              'border-color': secondaryColours.border
            }">
          <p class="mb-5 text-xl md:text-2xl xl:text-3xl font-bold flex items-center justify-center space-x-4">
            <span>Skipped</span> 
            <span class="text-xl">❌</span>
          </p>

          <div v-if="previewing">
            <ul class="md:py-6 list-disc pl-4">
              <li class="list-item">
                <div class="text-xl lg:text-xl mb-2 md:mb-3 flex flex-row justify-between">
                  <span class="text-left">Badger</span>
                  <span class="text-md lg:text-lg text-right">22 pts</span>
                </div>
              </li>
              <li class="list-item">
                <div class="text-xl lg:text-xl mb-2 md:mb-3 flex flex-row justify-between">
                  <span class="text-left">Pangolin</span>
                  <span class="text-md lg:text-lg text-right">144 pts</span>
                </div>
              </li>
            </ul>
          </div>
          <div v-else>
            <ul v-if="wrongAnswers.length > 0" class="md:py-6 list-disc pl-4">
              <li class="list-item" v-for="promptResult in wrongAnswers" :key="promptResult.id">
                <div class="text-xl lg:text-xl mb-2 md:mb-3 flex flex-row justify-between">
                  <span class="text-left">{{ getPromptById(promptResult.promptId).text }}</span>
                  <span class="text-md lg:text-lg text-right">{{ getPromptById(promptResult.promptId).pointsAwarded }}pts</span>
                </div>
              </li>
            </ul>
            <p v-else class="text-xl px-4 mb-3 md:mb-4">🥳 Wow, no skipped answers!!</p>
          </div>
          <p class="mb-3">{{ spectatorView ? 'They' : 'You' }} missed out on {{ totalPoints(false) }} points!</p>
        </div>
      </div>
    </div>

    <div class="space-y-3" v-if="nextTeamLoaded">
      <div v-if="nextTeam">
        <p v-if="actorView || guesserView">We'll start the next round for Team {{ nextTeam.name }} in:</p>
        <p v-else>Team {{ nextTeam.name }}, it's your turn next,</p>
      </div>
      <div v-else>
        <p v-if="isFinalRound">That's the last round, let's see how you did...</p>
        <p v-else>That's the last turn this round, let's move onto the next one...</p>
      </div>

      <Countdown :timerSeconds="displayTimer" :colour="fontColour" :waitingMessage="'Moving on...'" />
    </div>
  </div>
</template>

<script>
import { 
  mapState, 
  mapGetters, 
  mapActions, 
  mapMutations } from "vuex";
import {
  query,
  collection,
  where,
  onSnapshot
} from "@firebase/firestore";
import Countdown from './Countdown.vue'
import { Timestamp } from "firebase/firestore";
import {
  db, 
  updateNextTeamTurn, 
  getGameStateRefByBreakoutRoomId, 
  getTeamByDocumentId } from "../../firebase";
import { getFunctions, httpsCallable } from "@firebase/functions";
import ColourUtils from '@/mixins/ColourUtils'
import { gameStepMap } from "../../store/modules/utils";

export default {
  name: "PromptResults",
  components: {
    Countdown
  },
  mixins: [ColourUtils],
  props: [
    "gameId", 
    "breakoutRoomId", 
    "step", 
    "taskGroupId",
    "taskId",
    "actorView", 
    "guesserView", 
    "spectatorView", 
    "previewing",
    "fontColour",
    "listenForActorResultsFinished",
    "resetCalibration"
  ],
  data() {
    return {
      showQuestionResult: false,
      unsubPromptResponses: Function,
      unsubNextTeamResponse: Function,
      unsubGameState: Function,
      answeredCorrectly: false,
      currentPlayerAnswerLoaded: false,
      answerSentToStatsPipeline: false,
      displayTimer: 30,
      gameEndingEarlyWarning: false,
      borderSaturateBy: 32,
      borderShadeBy: -20,
      nextTeam: null,
      nextTeamLoaded: false,
      previewNextTeamId: "0600f451ce602c3bdc05",
      gameStateRef: null,
      timerInterval: null,
      nextPromptInterval: null,
      startListeningForActorAt: 15
    };
  },
  computed: {
    correctAnswers() {
      return this.getPromptResults(true)
    },

    wrongAnswers() {
      return this.getPromptResults(false)
    },

    currentTeam() {
      return this.getTeamById(this.currentTeamTurn)
    },

    yourTeam() {
      return this.getTeamForPlayer(this.currentPlayer.id)
    },

    primaryColours() {
      const pColours = this.step.colours.primary
      pColours.border = this.applySaturationToHexColor(pColours.background, this.borderSaturateBy)
      pColours.border = this.shade(pColours.border, this.borderShadeBy)
      return pColours
    },

    secondaryColours() {
      const rColours = this.step.colours.secondary
      rColours.border = this.applySaturationToHexColor(rColours.background, this.borderSaturateBy)
      rColours.border = this.shade(rColours.border, this.borderShadeBy)
      return rColours
    },

    isFinalRound() {
      if (this.taskGroupId === undefined || this.taskId === undefined) return false

      const nextStep = this.getNextStep({ taskGroupId: this.taskGroupId, taskId: this.taskId, stepId: this.step.id})
      return nextStep[0] === 'finished'
    },

    readyForNextPrompt() {
      return this.getActorTimeIsUp === false
    },

    ...mapState({
      currentPlayer: state => state.playersModule.currentPlayer,
      players: state => state.playersModule.players,
      currentGame: state => state.gameActionsModule.currentGame,
      currentGameLoaded: state => state.gameActionsModule.currentGameLoaded,
      currentGameState: state => state.gameStateModule.currentGameState,
      currentPlayerTurn: state => state.gameStateModule.currentGameState.currentPlayerTurn,
      currentTeamTurn: state => state.gameStateModule.currentGameState.currentTeamTurn,
      responses: state => state.responsesModule.responses,
    }),

    ...mapGetters([
      "getPlayerResponse", 
      "getCurrentGameSecondsPerStepResults", 
      "getPlayerById", 
      "getTeamById", 
      "getTeamForPlayer", 
      "getPromptById", 
      "getPromptResponsesForStepAndTeamId", 
      "getTeamsWhoHaveAnsweredCurrentStep",
      "getTeamIdForNextStep",
      "getStepById",
      "getNextStep",
      "getActorTimeIsUp"
    ]),
  },

  watch: {
    currentPlayerAnswerLoaded: function () {
      // if (val == true && this.answerSentToStatsPipeline == false)
        // this.submitAnswerToStatsPipeline(); uncomment later
    },
  },
  async created() {
    // setTimeout(() => this.resetCalibration(), 1000) // Resets the parent view to stop the flash after the results timer has reached 0
    
    this.gameStateRef = await getGameStateRefByBreakoutRoomId(this.breakoutRoomId);
    await this.setupStepListener()
    await this.handleNextTeam()
    if(this.previewing) return

    // time step results are shown
    this.displayTimer = this.getCurrentGameSecondsPerStepResults
    this.setupPromptListener() 
    
    const timerStartDelay = this.actorView ? 1250 : 0 // Forces actor to lag behind
    setTimeout(() => this.startTimer(), timerStartDelay)
  },
  beforeUnmount() {
    this.unsubPromptResponses()
    this.unsubNextTeamResponse()
    this.unsubGameState()
    clearInterval(this.timerInterval)
    clearInterval(this.nextPromptInterval)
  },
  methods: {
    ...mapMutations(["SET_STEP_TIME_STAMP", "UPDATE_STEP_RESPONSES", "SET_CURRENT_STEP"]),
    ...mapActions([
      "SET_CURRENT_TEAM_PLAYER_TURN",
      "NEXT_STEP",
      "NEXT_PROMPT",
      "TRIGGER_SERVER_TIMESTAMP"
    ]),

    async handleNextTeam() {
      if(this.previewing) {
        this.nextTeam = await this.fetchPreviewTeam()
      } else {
        await this.setupNextTeamListener()

        if(this.actorView) {
          // Actor calculates and sets the next team (unless this is the last round)
          let nextTeamId = await this.getTeamIdForNextStep(this.breakoutRoomId)
          if(nextTeamId === undefined) nextTeamId = null
          this.nextTeamLoaded = false
          await updateNextTeamTurn(this.breakoutRoomId, nextTeamId)
        }
      }
    },
    
    async setupNextTeamListener() {
      this.unsubNextTeamResponse = onSnapshot(this.gameStateRef, (doc) => {
        this.nextTeamLoaded = false
        if (doc.data().nextTeamTurn === undefined || doc.data().nextTeamTurn === null) {
          this.nextTeam = null
        } else {
          this.nextTeam = this.getTeamById(doc.data().nextTeamTurn)
        }
        this.nextTeamLoaded = true
      })
    },

    playerResponse() { // only one player answers per round, so it won't make sense to do this
      return this.getPlayerResponse(
        this.currentPlayer.id,
        this.step.id
      );
    },

    submitAnswerToStatsPipeline() {
      const response = this.playerResponse();
      const functions = getFunctions();
      const playerAnsweredStep = httpsCallable(functions, "playerAnsweredStep");
      playerAnsweredStep({
        playerId: this.currentPlayer.id,
        stepId: this.step.id,
        gameId: this.gameId,
        isCorrect: this.answeredCorrectly,
        answerId: response.answerId,
        newScore: this.currentPlayer.score,
      });

      this.answerSentToStatsPipeline = true;
    },

    getPromptResults(correct) {
      return this.getPromptResponsesForStepAndTeamId(this.step.id, this.currentTeamTurn, correct)
    },

    totalPoints(correct) {
      let points
      if (correct) {
        points = this.previewing ? 37 : this.getPromptResults(correct).map(pr => pr.pointsAwarded).reduce((sum, pts) => sum + pts, 0)
      } else {
        points = this.previewing ? 166 : this.getPromptResults(correct).map(pr => this.getPromptById(pr.promptId).pointsAwarded).reduce((sum, pts) => sum + pts, 0)
      }

      return points
    },

    setupPromptListener() {
      // listening for other players' answers
      const q = query(
        collection(db, "responses"),
        where("gameId", "==", this.gameId),
        where("breakoutRoomId", "==", this.breakoutRoomId)
      );

      // get all the prompt responses for this step
      this.unsubPromptResponses = onSnapshot(q, (docs) => {
        let responseItems = [];
        docs.forEach((doc) => {
          const response = {
            id: doc.id,
            promptId: doc.data().promptId,
            correct: doc.data().correct,
            status: doc.data().status,
            pointsAwarded: doc.data().pointsAwarded,
            gameId: doc.data().gameId,
            playerId: doc.data().playerId,
            stepId: doc.data().stepId,
            teamId: doc.data().teamId,
            answeredAt: doc.data().answeredAt,
          };
          responseItems.push(response);
        });
        this.UPDATE_STEP_RESPONSES(responseItems);
      });
    },

    async startTimer() {
      this.timerInterval = window.setInterval(() => {
        this.displayTimer -= 1
        if (this.displayTimer == this.startListeningForActorAt) this.listenForActorResultsFinished() // At 15 remaining seconds start listening for the actor (possibly us) to finish

        if (this.displayTimer == 0) {
          clearInterval(this.timerInterval)

          this.SET_STEP_TIME_STAMP(Timestamp.now()) // hack to get everyones timestamp back to current

          if (this.actorView) { // set next team and player
            // Actor triggers the next turn and also marks that his results timer has finished
            // thus triggering the call in  setupNextPromptsOnceActorIsReady
            // for all players (including themselves)
            this.TRIGGER_SERVER_TIMESTAMP({ breakoutRoomId: this.breakoutRoomId })
            this.SET_CURRENT_TEAM_PLAYER_TURN({ 
              breakoutRoomId: this.breakoutRoomId, 
              fromStepId: this.step.id 
            })
          }
        }
      }, this.actorView ? 1050 : 950) // Actor moves slower
    },

    async fetchPreviewTeam() {
      const teamDoc = await getTeamByDocumentId(this.previewNextTeamId)
      const teamData = {
        id: teamDoc.id,
        name: teamDoc.data().name,
        hexColour: teamDoc.data().hexColour,
        players: teamDoc.data().players,
        score: teamDoc.data().score,
        minPlayerCount: teamDoc.data().minPlayerCount,
        maxPlayerCount: teamDoc.data().maxPlayerCount,
        breakoutRoomId: teamDoc.data().breakoutRoomId,
      }
      return teamData
    },

    async setupStepListener() { 
      // In case we have moved on to the next step 
      // This can happen if this component is refreshed in the browser
      this.unsubGameState = onSnapshot(this.gameStateRef, (doc) => {
        this.SET_CURRENT_STEP(doc.data().currentStep);

        if (doc.data().currentStep != this.step.id) {
          const stepType = this.getStepById(doc.data().currentStep).stepType
          const screenType = gameStepMap(stepType)

          clearInterval(this.timerInterval)

          // move to next step
          return this.$router.push({
            name: screenType,
            params: {
              gameId: this.gameId,
              taskGroupId: doc.data().currentTaskGroup,
              taskId: doc.data().currentTask,
              stepId: doc.data().currentStep,
            },
          });
        }
      })
    }
  }
};
</script>
