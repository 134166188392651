<template>
  <div class="space-y-6 mt-6 mb-4">
    <div class="space-y-8">
      <h2 class="text-2xl lg:text-3xl font-bold text-center px-4">
        <span v-if="isWhoAmI">{{ player.name }} is currently guessing!</span>
        <span v-else-if="isTaboo">{{ player.name }} is currently describing!</span>
        <span v-else-if="isCharades">{{ player.name }} is currently performing!</span>
        <p><button class="text-base text-red-500" @click="skipAction">Skip player</button></p>
      </h2>
    
      <p class="text-base text-center">Team {{ team.name }}! It's currently your turn</p>
    </div>
  
    <div class="mt-4 lg:mt-16" v-if="canDisplayTimer">
      <Countdown :timerSeconds="timer" :colour="fontColour" :waitingMessage="'Finishing round...'" />
    </div>

    <div>
      <p v-if="isWhoAmI" class="font-bold text-xl lg:text-3xl pb-2 lg:pb-4 lg:pt-2 mx-auto">Category: {{humanisedPromptCategory}}</p>
    </div> 

    <div class="rounded-3xl py-4 px-6 w-4/5 lg:w-3/5 xl:w-2/5 mx-auto border-2"
        :style="{
          'color': colours.font,
          'background-color': colours.background,
          'border-color': colours.border 
        }">
      <div v-if="isWhoAmI && prompt" class="flex flex-col items-center">
        <p class="text-lg text-center mb-2 md:mb-4 px-4 lg:px-0">
          {{ player.name }} is currently trying to guess who they are.<br /><br />
          It's your turn to answer {{ player.name }}'s questions with a yes or no.
        </p> 

        <h2 class="font-bold text-3xl lg:text-3xl mx-auto mt-3 lg:mt-4">{{ prompt.text }}</h2>

        <!-- <img v-bind:src="prompt.image" class="w-16 h-16 md:w-20 md:h-20 my-4 lg:my-8 mx-auto block" /> -->

        <p class="my-4 lg:my-8">This is worth {{ prompt.pointsAwarded }} points!</p>
        <p class="font-bold text-base lg:text-lg mx-auto mt-4 mb-3">
          <span v-if="showHints">{{ player.name }}'s hint:<br /> {{currentHint}}</span>
          <span v-else>&nbsp;<br />&nbsp;</span>
        </p>
      </div>

      <div v-else class="flex flex-col items-center">
        <h3 class="text-center font-bold text-3xl mb-6">Team {{ team.name }}</h3>
    
        <p class="font-semibold text-lg text-center mb-4">{{playersList}}</p> 
    
        <p class="font-semibold text-lg text-center mb-4">
          It's currently your turn to guess what {{player.name}} is 
          <span v-if="isCharades">performing</span>
          <span v-else-if="isTaboo">describing</span>
        </p>
    
        <img src="https://wildgoose-production.s3.amazonaws.com/emu-resources/thinking-face.png" 
              class="flex h-20 w-20 mt-4 mb-6" 
              alt="Thinking face!" />
      </div>
    </div>

  </div>
</template>

<script>

import Countdown from './Countdown.vue'
import ColourUtils from '@/mixins/ColourUtils'
import StringUtils from '@/mixins/StringUtils'
import { mapActions, mapGetters } from "vuex"

export default {
  name: "GuesserPromptQuestion",
  mixins: [ColourUtils, StringUtils],
  props: [
    "player", 
    "team", 
    "timer", 
    "canDisplayTimer",
    "step",
    "prompt",
    "fontColour",
    "previewing",
    "breakoutRoomId"
  ],
  components: {
    Countdown
  },
  async created() {
    if(this.previewing) return
    if (this.isWhoAmI) this.rotateHints()

    
    await this.FETCH_TEAM({ teamId: this.team.id, getPlayers: true });    
    this.buildTeamPlayers()
  },
  data() {
    return {
      fullPlayers: [],
      borderSaturateBy: 32,
      borderShadeBy: -20,
      hintRotationInterval: null,
      promptHintIndex: 0,
      showHints: false
    }
  },  
  watch: {
    prompt() {
      if (this.isWhoAmI) {
        this.showHints = false
        this.promptHintIndex = 0
        clearInterval(this.hintRotationInterval)
        setTimeout(() => this.rotateHints(), 750) // Restart the hint timer
      }
    }
  },
  beforeUnmount() {
    clearInterval(this.hintRotationInterval)
  },
  computed: {
    ...mapGetters(["getTeamById"]),
    playersList() {
      const users = this.fullPlayers.filter((player) => player.id != this.player.id)
      return users.map((player) => player.name).join(", ").replace(/,(?=[^,]+$)/, ' and')
    },
    isWhoAmI() {
      return this.step.stepType == 'whoami'
    },
    isTaboo() {
      return this.step.stepType == 'taboo'
    },
    isCharades() {
      return this.step.stepType == 'charades'
    },
    colours() {
      const colours = this.step.colours.primary
      colours.border = this.applySaturationToHexColor(colours.background, this.borderSaturateBy)
      colours.border = this.shade(colours.border, this.borderShadeBy)
      return colours
    },
    currentHint() {
      return this.prompt ? this.prompt.hints[this.promptHintIndex] : ""
    },

    humanisedPromptCategory() {
      return this.capitaliseFirst(this.step.promptCategory)
    }
  },
  
  methods: {
    ...mapActions([
      "GET_GAME_STATE",
      "FETCH_TEAM",
      "SET_SKIP_PLAYER_TURN"
    ]),

    skipAction() {
      this.SET_SKIP_PLAYER_TURN({ 
        breakoutRoomId: this.breakoutRoomId, 
        fromStepId: this.step.id,
        teamId: this.team.id
      })
    },

    buildTeamPlayers() {
      this.fullPlayers = this.getTeamById(this.team.id).fullPlayers
    },

    rotateHints() {
      this.hintRotationInterval = window.setInterval(() => {
        if (this.prompt === null || this.prompt === undefined) { // i.e. the game has ended
          clearInterval(this.hintRotationInterval)
          return
        }

        if(this.promptHintIndex === this.prompt.hints.length - 1) {
          this.promptHintIndex = 0
        } else {
          this.promptHintIndex += 1
        }
        this.showHints = true
      }, 30000) // Show a new hint every 30 seconds
    },

  }
}
</script>
