<template>
  <p></p>
</template>

<script>
export default {
  name: "Logout",
  async created() {
    if (this.$store.getters.hasCurrentGame) 
      await this.$store.dispatch("EXIT_GAME_EARLY", { teamId: null });
    await this.$store.commit("CLEAR_CURRENT_PLAYER");
    this.$router.push({ name: "Login" });
  },
};
</script>