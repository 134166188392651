import {
  getGameStateRefByGameIdOrRoomId,
  getUserByDocumentId,
  updateTeamTurn,
  updatePlayerTurn,
  triggerNextGameStatePrompt,
  triggerStepTimeStamp
} from "../../firebase"
import { getDoc, Timestamp } from "firebase/firestore";

const gameStateModule = {
  state: () => ({
    currentGameState: null,
  }),
  getters: {
    getCurrentGameState: (state) => state.currentGameState,
    getActorTimeIsUp: (state) => state.currentGameState.currentStepActorTimerHasFinished
  },
  mutations: {
    SET_CURRENT_GAME_STATE(state, payload) {
      state.currentGameState = payload;
    },
    SET_CURRENT_STEP(state, payload) {
      state.currentGameState.currentStep = payload
    },
    SET_CURRENT_PROMPT(state, payload) {
      state.currentGameState.currentPrompt = payload
    },
    CLEAR_CURRENT_GAME_STATE(state) {
      state.currentGameState = null;
    },
    SET_CURRENT_TEAM_TURN(state, payload) {
      state.currentGameState.currentTeamTurn = payload; 
    },
    SET_CURRENT_PLAYER_TURN(state, payload) {
      state.currentGameState.currentPlayerTurn = payload;
    },
    SET_STEP_TIME_STAMP(state, payload) {
      state.currentGameState.currentStepTimestamp = payload;
    }
  },
  actions: {
    async GET_GAME_STATE({ commit }, { gameId, breakoutRoomId, getHistorical }) {
      // if room Id is present, we want the game state specific to that room
      if (breakoutRoomId) gameId = null;
      const gameStateRef = await getGameStateRefByGameIdOrRoomId(gameId, breakoutRoomId);
      const gameStateDoc = await getDoc(gameStateRef);
      if (gameStateDoc.exists()) {
        const data = {
          id: gameStateDoc.id,
          status: gameStateDoc.data().status,
          players: gameStateDoc.data().players,
          historicalPlayers: gameStateDoc.data().historicalPlayers,
          currentTaskGroup: gameStateDoc.data().currentTaskGroup,
          currentTask: gameStateDoc.data().currentTask,
          currentStep: gameStateDoc.data().currentStep,
          currentTeamTurn: gameStateDoc.data().teams[0],
          currentStepTimestamp: gameStateDoc.data().currentStepTimestamp,
          currentStepActorTimerHasFinished: gameStateDoc.data().currentStepActorTimerHasFinished,
          gameId: gameStateDoc.data().gameId,
          teams: gameStateDoc.data().teams,
          gameListenersUrl: gameStateDoc.data().gameListenersUrl,
        }
        let players = [];
        for (const playerId of data.players) {
          const userDoc = await getUserByDocumentId(playerId)
          if (userDoc.exists()) {
            const userData = {
              id: userDoc.id,
              name: userDoc.data().name,
              initials: userDoc.data().initials,
              score: userDoc.data().score
            }
            players.push(userData)
          }
        }
        if (getHistorical) {
          let historicalPlayers = [];
          for (const playerId of data.historicalPlayers) {
            const userDoc = await getUserByDocumentId(playerId)
            if (userDoc.exists()) {
              const userData = {
                id: userDoc.id,
                name: userDoc.data().name,
                initials: userDoc.data().initials,
                score: userDoc.data().score
              }
              historicalPlayers.push(userData)
            }
          }
          commit("SET_HISTORICAL_PLAYERS", historicalPlayers)
        }
        commit("UPDATE_GAME_PLAYERS", players)
        commit("SET_CURRENT_GAME_STATE", data)
      }
    },

    async SET_CURRENT_TEAM_PLAYER_TURN({ commit, rootState, getters, dispatch }, { gameId, breakoutRoomId, fromStepId=null, understudyActioned=false }) {
      const currentStepId = rootState.gameStateModule.currentGameState.currentStep

      if(fromStepId !== null && fromStepId !== currentStepId) {
        console.warn(`SET_CURRENT_TEAM_PLAYER_TURN has been triggered from a step that is not the current one!!!`, `currentStepId: ${currentStepId}`, `fromStepId: ${fromStepId}`)
        return
      }

      let teamId = getters.getTeamIdForNextStep(breakoutRoomId)
      
      // if all teams have played a step, start from the first team in the list 
      let triggerNextStep = false;
      if (!teamId) {
        const teamIdsInCurrentBreakoutRoom = getters.getBreakoutRoomById(breakoutRoomId).teamIds 
        teamId = teamIdsInCurrentBreakoutRoom[0]
        triggerNextStep = true
      }

      // Find a random player from that team
      const team = rootState.teamsModule.teams.find(t => t.id == teamId)
      
      let playerId
      if (team.playersPlayed.length >= team.players.length) {
        // Everyone has had at least 1 turn, choose another at random
        playerId = team.players[Math.floor(Math.random() * team.players.length)]
      } else {
        // Otherwise choose someone who has not yet had a turn
        const playersNotYetPlayed = team.players.filter((p) => !team.playersPlayed.includes(p))
        playerId = playersNotYetPlayed[Math.floor(Math.random() * playersNotYetPlayed.length)]
      }

      // Set the values locally and remotely
      // console.log("ACTION: SET TEAM TURN", teamId, playerId)
      commit("SET_CURRENT_TEAM_TURN", teamId)
      await updateTeamTurn(gameId, breakoutRoomId, teamId)

      if (playerId) {
        commit("SET_CURRENT_PLAYER_TURN", playerId)
        await updatePlayerTurn(gameId, breakoutRoomId, playerId, understudyActioned)
      }

      if (triggerNextStep) {
        dispatch("NEXT_STEP", { gameId: gameId, breakoutRoomId: breakoutRoomId, currentStepId: currentStepId })
      }
    },

    async SET_SKIP_PLAYER_TURN({ commit, rootState, getters, dispatch }, { gameId, breakoutRoomId, fromStepId=null, teamId=null, understudyActioned=false }) {
      const currentStepId = rootState.gameStateModule.currentGameState.currentStep
      const currentPlayerTurn = rootState.gameStateModule.currentGameState.currentPlayerTurn

      if(fromStepId !== null && fromStepId !== currentStepId) {
        console.warn(`SET_SKIP_PLAYER_TURN has been triggered from a step that is not the current one!!!`, `currentStepId: ${currentStepId}`, `fromStepId: ${fromStepId}`)
        return
      }

      // Find a random player from that team
      const team = rootState.teamsModule.teams.find(t => t.id == teamId)

      let playerId
      // Everyone has had at least 1 turn, choose another at random
      const readyPlayers = team.players.filter(item => item !== currentPlayerTurn);
      playerId = readyPlayers[Math.floor(Math.random() * readyPlayers.length)]

      if (playerId) {
        commit("SET_CURRENT_PLAYER_TURN", playerId)
        await updatePlayerTurn(gameId, breakoutRoomId, playerId, understudyActioned)
      }
    },

    async NEXT_PROMPT({ getters, commit, dispatch, rootState }, { gameId, breakoutRoomId } ) {
      const currentStepId = rootState.gameStateModule.currentGameState.currentStep
      if (!currentStepId) return // Game has ended

      const currentStep = getters.getStepById(currentStepId)
      if (!currentStep) return // Cannot find current step

      const category = currentStep.promptCategory
      const prompt = getters.getUnansweredPrompt(category)

      if (prompt) {
        const promptId = prompt.id
        commit("SET_CURRENT_PROMPT", promptId)
        await triggerNextGameStatePrompt(gameId, breakoutRoomId, promptId)
      } else {
        dispatch("NEXT_STEP", { 
          breakoutRoomId: breakoutRoomId, 
          currentStepId: currentStepId
        })
      }
    },

    async TRIGGER_SERVER_TIMESTAMP({ commit }, { gameId, breakoutRoomId }) {
      // fire off step timeStamp to trigger new timers
      commit("SET_STEP_TIME_STAMP", Timestamp.now())
      await triggerStepTimeStamp(gameId, breakoutRoomId)
    }
  }
}

export default gameStateModule;