import { firebaseApp } from './firebaseConfig';
import {
  Timestamp,
  arrayRemove,
  arrayUnion,
  updateDoc,
  getFirestore,
  collection,
  getDocs,
  doc,
  getDoc,
  query,
  where,
  addDoc,
  deleteDoc
} from '@firebase/firestore';

const db = getFirestore(firebaseApp);
const gamesCollectionName = 'games';
const usersCollectionName = 'users';
const teamsCollectionName = 'teams';
const gameStatesCollectionName = 'gameStates';
const responsesCollectionName = 'responses';
const charadesPromptsCollectionName = 'prompts';
const whoAmIPromptsCollectionName = 'whoAmIPrompts';
const tabooPromptsCollectionName = 'tabooPrompts';
const breakoutRoomsCollectionName = 'breakoutRooms'
const previewStepsCollectionName = 'previewSteps'
const stepCheckInsCollectionName = 'stepCheckIns'

const getGameRefByDocumentId = async (gameId) => {
  return doc(db, gamesCollectionName, gameId);
}

const getTeamRefByDocumentId = async (teamId) => {
  return doc(db, teamsCollectionName, teamId)
}

const getGameByCode = async (gameCode) => {
  const q = query(collection(db, gamesCollectionName), where("gameCode", "==", gameCode))

  const querySnapshot = await getDocs(q);
  const documentId = querySnapshot.docs.find((doc) => doc.data()['gameCode'] == gameCode).id;
  return getDoc(doc(db, gamesCollectionName, documentId));
}

const getGameStateRefByGameId = async (gameId) => {
  const q = query(collection(db, gameStatesCollectionName), where("gameId", "==", gameId))

  const querySnapshot = await getDocs(q);
  const documentId = querySnapshot.docs.find((doc) => doc.data()['gameId'] == gameId).id;
  return doc(db, gameStatesCollectionName, documentId);
}

const getGameStateRefByBreakoutRoomId = async (breakoutRoomId) => {
  const q = query(collection(db, gameStatesCollectionName), where("breakoutRoomId", "==", breakoutRoomId))

  const querySnapshot = await getDocs(q);
  const documentId = querySnapshot.docs.find((doc) => doc.data()['breakoutRoomId'] == breakoutRoomId).id;
  return doc(db, gameStatesCollectionName, documentId);
}

const getGameStateRefByGameIdOrRoomId = (gameId, breakoutRoomId) => {
  if (breakoutRoomId) {
    return getGameStateRefByBreakoutRoomId(breakoutRoomId);
  } else if (gameId) {
    return getGameStateRefByGameId(gameId);
  }
}

const getUserByDocumentId = async (userId) => getDoc(doc(db, usersCollectionName, userId));

const getTeamByDocumentId = async (teamId) => getDoc(doc(db, teamsCollectionName, teamId));

const addUser = async (data) => await addDoc(collection(db, usersCollectionName), data)

const addPlayerResponse = async (data) => await addDoc(collection(db, responsesCollectionName), data)

const addPlayerToGameState = async (gameId, breakoutRoomId, playerId) => {
  const gameStateRef = await getGameStateRefByGameIdOrRoomId(gameId, breakoutRoomId)
  await updateDoc(gameStateRef, {
    players: arrayUnion(playerId),
    historicalPlayers: arrayUnion(playerId)
  })
}

const removePlayerFromGameState = async (gameId, breakoutRoomId, playerId) => {
  const gameStateRef = await getGameStateRefByGameIdOrRoomId(gameId, breakoutRoomId)
  await updateDoc(gameStateRef, {
    players: arrayRemove(playerId),
    historicalPlayers: arrayRemove(playerId)
  })
}

const addPlayerToTeam = async (teamId, playerId) => {
  const teamRef = await getTeamRefByDocumentId(teamId)
  await updateDoc(teamRef, {
    players: arrayUnion(playerId)
  })
}

const updateTeamPlayerPlayed = async (teamId, playerId) => {
  const teamRef = await getTeamRefByDocumentId(teamId)
  await updateDoc(teamRef, {
    playersPlayed: arrayUnion(playerId)
  })
}

const getBreakoutRoomsByGameId = async (gameId) => {
  const q = query(collection(db, breakoutRoomsCollectionName), where("gameId", "==", gameId))
  const querySnapshot = getDocs(q)
  let rooms = [];
  (await querySnapshot).forEach((doc) => {
    const data = {
      id: doc.id,
      gameId: doc.data().gameId,
      gameStateId: doc.data().gameStateId,
      name: doc.data().name,
      teamIds: doc.data().teamIds,
    }
    rooms.push(data)
  })

  function compare( a, b ) {
    if ( a.name < b.name ){
      return -1;
    }
    if ( a.name > b.name ){
      return 1;
    }
    return 0;
  }
  return rooms.sort(compare);
}

const setActorTimerHasFinished = async (breakoutRoomId) => {
  const gameStateRef = await getGameStateRefByBreakoutRoomId(breakoutRoomId)
  await updateDoc(gameStateRef, {
    currentStepActorTimerHasFinished: true,
    understudyMovedGameAhead: false
  })
}

const removePlayerFromTeam = async (teamId, playerId) => {
  const teamRef = await getTeamRefByDocumentId(teamId)
  await updateDoc(teamRef, {
    players: arrayRemove(playerId)
  })
}

const getCharadesPrompts = async (category) => {
  const q = query(collection(db, charadesPromptsCollectionName), where("categories", "array-contains", category))
  const querySnapshot = getDocs(q)
  let prompts = [];
  (await querySnapshot).forEach((doc) => {
    const data = {
      id: doc.id,
      text: doc.data().text,
      pointsAwarded: doc.data().pointsAwarded,
      categories: doc.data().categories,
      image: doc.data().image
    }
    prompts.push(data)
  })
  return prompts;
}

const getWhoAmIPrompts = async (category) => {
  const q = query(collection(db, whoAmIPromptsCollectionName), where("categories", "array-contains", category))
  const querySnapshot = getDocs(q)
  let prompts = [];
  (await querySnapshot).forEach((doc) => {
    const data = {
      id: doc.id,
      text: doc.data().text,
      pointsAwarded: doc.data().pointsAwarded,
      categories: doc.data().categories,
      hints: doc.data().hints,
      image: doc.data().image
    }
    prompts.push(data)
  })
  return prompts;
}

const getTabooPrompts = async (category) => {
  const q = query(collection(db, tabooPromptsCollectionName), where("categories", "array-contains", category))
  const querySnapshot = getDocs(q)
  let prompts = [];
  (await querySnapshot).forEach((doc) => {
    const data = {
      id: doc.id,
      text: doc.data().text,
      pointsAwarded: doc.data().pointsAwarded,
      categories: doc.data().categories,
      tabooWords: doc.data().tabooWords,
      image: doc.data().image
    }
    prompts.push(data)
  })
  return prompts;
}

const triggerNextGameStateStep = async (gameId, breakoutRoomId, stepId, taskId, taskGroupId) => {
  const gameStateRef = await getGameStateRefByGameIdOrRoomId(gameId, breakoutRoomId)
  const stamp = Timestamp.now()
  await updateDoc(gameStateRef, {
    currentStep: stepId,
    currentTask: taskId,
    currentTaskGroup: taskGroupId,
    playersAnsweredCurrentStep: [],
    currentStepTimestamp: stamp,
    understudyMovedGameAhead: false
  })
}

const triggerStepTimeStamp = async (gameId, breakoutRoomId) => {
  const gameStateRef = await getGameStateRefByGameIdOrRoomId(gameId, breakoutRoomId)
  const stamp = Timestamp.now()
  await updateDoc(gameStateRef, {
    currentStepTimestamp: stamp
  })
}

const triggerNextGameStatePrompt = async (gameId, breakoutRoomId, promptId) => {
  const gameStateRef = await getGameStateRefByGameIdOrRoomId(gameId, breakoutRoomId)
  await updateDoc(gameStateRef, {
    currentPrompt: promptId,
    understudyMovedGameAhead: false
  })
}

const endGameState = async (gameId, breakoutRoomId) => {
  const gameStateRef = await getGameStateRefByGameIdOrRoomId(gameId, breakoutRoomId)
  await updateDoc(gameStateRef, {
    status: 'ended',
    currentPrompt: null,
    currentStep: null,
    currentTask: null,
    currentTaskGroup: null,
    playersAnsweredCurrentStep: [],
    understudyMovedGameAhead: false
  })
}

const playerExitGameEarly = async (playerId, breakoutRoomId, gameId) => {
  try {
    const gameStateRef = await getGameStateRefByGameIdOrRoomId(gameId, breakoutRoomId)
    await updateDoc(gameStateRef, {
      players: arrayRemove(playerId),
      playersAnsweredCurrentStep: arrayRemove(playerId)
    })
    await cleanPlayerResponses(gameId, playerId)
  } catch(TypeError) {
    console.log(TypeError, "couldn't find game")
  }
}

const cleanPlayerResponses = async (gameId, playerId) => {
  const q = query(collection(db, responsesCollectionName), where("gameId", "==", gameId), where("playerId", "==", playerId))
  const querySnapshot = getDocs(q);
  (await querySnapshot).forEach(async (document) => {
    await deleteDoc(doc(db, responsesCollectionName, document.id))
  });
}

const updatePlayerScore = async (playerId, pointsAwarded) => {
  const userRef = doc(db, 'users', playerId);
  const userDoc = await getDoc(userRef);
  let currentScore = userDoc.data().score
  const newScore = currentScore += pointsAwarded;
  await updateDoc(userRef, {
    score: newScore
  })
}

const updateTeamScore = async (teamId, pointsAwarded) => {
  const teamRef = doc(db, "teams", teamId);
  const teamDoc = await getDoc(teamRef);
  let currentScore = teamDoc.data().score;
  const newScore = currentScore += pointsAwarded;
  await updateDoc(teamRef, {
    score: newScore
  })
}

const updateTeamTurn = async (gameId, breakoutRoomId, teamId) => {
  const gameStateRef = await getGameStateRefByGameIdOrRoomId(gameId, breakoutRoomId)
  await updateDoc(gameStateRef, {
    currentTeamTurn: teamId,
    currentStepActorTimerHasFinished: false
  })
}

const updateNextTeamTurn = async (breakoutRoomId, teamId) => {
  const gameStateRef = await getGameStateRefByBreakoutRoomId(breakoutRoomId)
  await updateDoc(gameStateRef, {
    nextTeamTurn: teamId,
  })
}

const updateTeamName = async (teamId, newName) => {
  const teamRef = doc(db, "teams", teamId);
  await updateDoc(teamRef, {
    name: newName,
  })
}

const updatePlayerTurn = async (gameId, breakoutRoomId, playerId, understudyActioned=false) => {
  const gameStateRef = await getGameStateRefByGameIdOrRoomId(gameId, breakoutRoomId)
  const data = {
    currentPlayerTurn: playerId,
    currentUnderstudyTurn: null,
    currentStepActorTimerHasFinished: false,
    understudyMovedGameAhead: understudyActioned
  }
  await updateDoc(gameStateRef, data)
}

const startRemoteGame = async (gameId, breakoutRoomId) => {
  const gameStateRef = await getGameStateRefByGameIdOrRoomId(gameId, breakoutRoomId)
  const stamp = Timestamp.now()
  await updateDoc(gameStateRef, {
    status: 'started',
    currentStepTimestamp: stamp,
    currentStepActorTimerHasFinished: false,
    understudyMovedGameAhead: false
  })
}

const cancelStartRemoteGame = async (gameId, breakoutRoomId) => {
  const gameStateRef = await getGameStateRefByGameIdOrRoomId(gameId, breakoutRoomId)
  await updateDoc(gameStateRef, {
    status: 'lobby',
    currentStepTimestamp: null,
    understudyMovedGameAhead: false
  })
}

const getAllPlayerResponses = async (gameId, breakoutRoomId) => {
  const q = query(collection(db, responsesCollectionName), where("gameId", "==", gameId), where("breakoutRoomId", "==", breakoutRoomId))
  const querySnapshot = getDocs(q)
  let responses = [];
  (await querySnapshot).forEach((doc) => {
    const data = {
      id: doc.id,
      playerId: doc.data().playerId,
      teamId: doc.data().teamId,
      correct: doc.data().correct,
      answerId: doc.data().answerId,
      promptId: doc.data().promptId,
      stepId: doc.data().stepId,
      timeTaken: doc.data().timeTaken,
      status: doc.data().status
    }
    responses.push(data)
  })
  return responses
}

const getPlayerResponses = async (gameId, playerId) => {
  const q = query(collection(db, responsesCollectionName), where("gameId", "==", gameId), where("playerId", "==", playerId))
  const querySnapshot = getDocs(q)
  let responses = [];
  (await querySnapshot).forEach((doc) => {
    const data = {
      id: doc.id,
      playerId: doc.data().playerId,
      answerId: doc.data().answerId,
      stepId: doc.data().stepId,
      timeTaken: doc.data().timeTaken,
      status: doc.data().status
    }
    responses.push(data)
  })
  return responses
}

const createBlankSkippedResponses = async (gameId, currentStep, allSteps, currentPlayer) => {
  const earlierSteps = allSteps.filter((step) => Number(step.position) < Number(currentStep.position))

  earlierSteps.forEach(async (step) => {
    await addPlayerResponse({
      gameId: gameId,
      answerId: null,
      playerId: currentPlayer.id,
      stepId: step.id,
      timeTaken: 0,
      status: 'skipped'
    })
  })
}

const getPreviewStepDocById = async (previewStepId) => {
  const document = doc(db, previewStepsCollectionName, previewStepId)
  return document
}

const getPreviewStep = async (previewStepId) => {
  const previewStepDoc = await getDoc( await getPreviewStepDocById(previewStepId) )
  const data = {
    id: previewStepDoc.id,
    stepType: previewStepDoc.data().stepType,
    promptCategory: previewStepDoc.data().promptCategory,
    colours: previewStepDoc.data().colours,
    images: previewStepDoc.data().images,
    timerSeconds: previewStepDoc.data().timerSeconds
  }

  return data
}

const getPreviewPrompt = async (type, category) => {
  let prompts
  switch(type) {
    case 'taboo':
      prompts = await getTabooPrompts(category)
      break
    case 'charades':
      prompts = await getCharadesPrompts(category)
      break
    case 'whoami':
      prompts = await getWhoAmIPrompts(category)
      break
    default:
      prompts = await getTabooPrompts(category)
      break
  }

  const randomIndex = Math.floor(Math.random()*prompts.length)
  return prompts[randomIndex]
}

const checkIntoStep = async (stepId, playerId, gameId, breakoutRoomId) => {
  const coll = collection(db, stepCheckInsCollectionName)
  const q = query(coll, where("breakoutRoomId", "==", breakoutRoomId))

  const querySnapshot = await getDocs(q)
  const document = querySnapshot.docs.find((doc) => {
    return doc.data()['gameId'] == gameId && doc.data()['playerId'] == playerId
  });
  const now = Date.now()
  
  if (document !== undefined) {
    const checkInDoc = doc(db, stepCheckInsCollectionName, document.id)
    await updateDoc(checkInDoc, { lastCheckedInAt: now, currentStepId: stepId })
  } else {
    const data = {
      lastCheckedInAt: now,
      currentStepId: stepId,
      playerId: playerId,
      gameId: gameId,
      breakoutRoomId: breakoutRoomId
    }
    await addDoc(coll, data)
  }
}

const touchTriggerGameStateListener = async (breakoutRoomId) => {
  const now = Date.now()
  const gameStateRef = await getGameStateRefByBreakoutRoomId(breakoutRoomId)
  const data = { touchedAt: now }
  await updateDoc(gameStateRef, data)
}

const touchTriggerTeamListener = async (breakoutRoomId) => {
  const now = Date.now()
  const teamsQuery = query(
    collection(db, teamsCollectionName),
    where("breakoutRoomId", "==", breakoutRoomId)
  )
  const querySnapshot = await getDocs(teamsQuery);
  (await querySnapshot).forEach(async (document) => {
    await updateDoc(doc(db, teamsCollectionName, document.id), { touchedAt: now })
  });
}

export {
  db,
  getGameRefByDocumentId,
  getTeamRefByDocumentId,
  getGameByCode,
  getGameStateRefByGameId,
  getGameStateRefByBreakoutRoomId,
  getGameStateRefByGameIdOrRoomId,
  getUserByDocumentId,
  getTeamByDocumentId,
  getBreakoutRoomsByGameId,
  addPlayerToTeam,
  updateTeamPlayerPlayed,
  removePlayerFromTeam,
  addUser,
  addPlayerResponse,
  addPlayerToGameState,
  removePlayerFromGameState,
  triggerNextGameStateStep,
  triggerNextGameStatePrompt,
  getCharadesPrompts,
  getWhoAmIPrompts,
  getTabooPrompts,
  endGameState,
  playerExitGameEarly,
  updatePlayerScore,
  updateTeamScore,
  updateTeamTurn,
  updateNextTeamTurn,
  updateTeamName,
  updatePlayerTurn,
  triggerStepTimeStamp,
  startRemoteGame,
  cancelStartRemoteGame,
  getAllPlayerResponses,
  getPlayerResponses,
  createBlankSkippedResponses,
  getPreviewStepDocById,
  getPreviewStep,
  getPreviewPrompt,
  setActorTimerHasFinished,
  checkIntoStep,
  touchTriggerGameStateListener,
  touchTriggerTeamListener
}
