<template>
  <span>{{currentTeam.name}}</span>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "TeamName",
  computed: {
    ...mapState({
      currentPlayer: state => state.playersModule.currentPlayer,
    }),
    ...mapGetters(["getTeamForPlayer"])
  },
  created() {
    this.currentTeam = this.getTeamForPlayer(this.currentPlayer.id)
  }
}
</script>
