<script>
import { getGameStateRefByBreakoutRoomId } from "../../firebase";
import { onSnapshot } from "firebase/firestore";

export default {
  name: "GameResetWatcher",
  async created() {
    try {
      this.gameStateRef = await getGameStateRefByBreakoutRoomId(this.breakoutRoomId)
      await this.watchForGameReset()
    } catch {
      console.warn(`no gameStateRef in GameResetWatcher`)
    }
  },
  render() { return "" },
  props: ["breakoutRoomId"],
  data() {
    return {
      unsubGameStatesListener: Function
    }
  },
  beforeUnmount() {
    this.unsubGameStatesListener()
  },
  methods: {
    async watchForGameReset() {
      this.unsubGameStatesListener = onSnapshot(this.gameStateRef, (doc) => {
        if (doc.data() !== undefined && doc.data().status == "terminated") this.reset()
      })
    },

    reset() {
      return this.$router.push({ name: "ExitGame" })
    }
  },
}
</script>
