<template>
  <div class="flex flex-row mx-auto flex-wrap items-center space-x-4 space-y-2 justify-center">
    <div v-for="player in players" :key="player.id" class="flex space-x-2 items-center">
      <div class="bg-gray-300 flex rounded-full w-10 h-10 font-bold text-center mx-2">
        <div class="flex my-auto mx-auto">
          {{ player.initials }}
        </div>
      </div>
      <p v-if="showName">{{ player.name }}</p>
      <p v-if="showScore" class="mt-2">{{ player.score }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlayerList",
  props: {
    players: Array,
    showName: Boolean,
    showScore: Boolean,
  }
}
</script>