<template>
  <InfoHeader v-if="!gameHasEnded" :verb="headerVerb" :mode="headerMode" :gameFinishedFunction="gameFinished" />

  <section v-if="step && !gameHasEnded" id="gameplay"
          class="bg-cover" 
          :class="[(gameEndingEarlyWarning ? 'pt-20 md:pt-12' : 'pt-3 md:pt-6 lg:pt-8')]"
          :style="{
            'backgroundImage': `url('${backgroundImage}')`,
            'backgroundColor': backgroundColour,
            'color': fontColour
          }">
    <img v-if="cornerImage.length > 0" v-bind:src="cornerImage" id="top-corner-image" class="block z-10" />

    <div v-if="ready" class="flex flex-col space-y-4 z-20 relative">
      <ActorPromptQuestion 
        v-if="actorView && !showResults"
        :player="getPlayerById(currentPlayerTurn)"
        :team="getTeamById(currentTeamTurn)"
        :prompt="getPromptById(this.currentPrompt)"
        :respondToPrompt="respondToPrompt"
        :currentPrompt="this.currentPrompt"
        :timer="this.displayTimer"
        :tooLate="tooLate"
        :canDisplayTimer="canDisplayTimer"
        :step="step"
        :promptNumber="promptNumber" />

      <GuesserPromptQuestion 
        v-if="guesserView && !showResults"
        :player="getPlayerById(currentPlayerTurn)"
        :team="getTeamById(currentTeamTurn)"
        :prompt="getPromptById(this.currentPrompt)"
        :timer="this.displayTimer"
        :canDisplayTimer="canDisplayTimer"
        :step="step"
        :breakoutRoomId="breakoutRoomId" />

      <SpectatorPromptQuestion 
        v-if="spectatorView && !showResults"
        :player="getPlayerById(currentPlayerTurn)"
        :team="getTeamById(currentTeamTurn)"
        :prompt="getPromptById(this.currentPrompt)"
        :currentPrompt="this.currentPrompt"
        :timer="this.displayTimer"
        :canDisplayTimer="canDisplayTimer"
        :step="step"
        :promptNumber="promptNumber" />

      <PromptResults
        v-if="showResults"
        :step="step"
        :taskGroupId="taskGroupId"
        :taskId="taskId"
        :gameId="gameId"
        :breakoutRoomId="breakoutRoomId"
        :actorView="actorView"
        :guesserView="guesserView"
        :spectatorView="spectatorView"
        :fontColour="fontColour"
        :listenForActorResultsFinished="setupNextPromptsOnceActorIsReady"
        :resetCalibration="resetCalibration" />
    </div>
    <img v-if="cornerImage.length > 0" v-bind:src="cornerImage" id="bottom-corner-image" class="block z-10" />
    
    <NotificationsCallout :gameId="gameId" :breakoutRoomId="breakoutRoomId" :screenType="'rounds'" />
  </section>

  <GameResetWatcher :breakoutRoomId="breakoutRoomId" />
</template>

<script>
import PromptResults from "./PromptResults.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import ActorPromptQuestion from "./ActorPromptQuestion.vue";
import GuesserPromptQuestion from "./GuesserPromptQuestion.vue";
import SpectatorPromptQuestion from "./SpectatorPromptQuestion.vue";
import { 
  setActorTimerHasFinished, 
  updateTeamPlayerPlayed, 
  endGameState,
  touchTriggerTeamListener,
  touchTriggerGameStateListener } from "../../firebase"
import GameResetWatcher from "../post_game/GameResetWatcher.vue";
import InfoHeader from './InfoHeader.vue';
import NotificationsCallout from "./NotificationsCallout.vue";


export default {
  name: "ReloadablePromptQuestion",
  components: {
    PromptResults,
    ActorPromptQuestion,
    GuesserPromptQuestion,
    SpectatorPromptQuestion,
    GameResetWatcher,
    InfoHeader,
    NotificationsCallout
},
  props: [
    "gameId", 
    "breakoutRoomId", 
    "taskGroupId", 
    "taskId", 
    "step", 
    "reRenderFunction", 
    "timerSeconds",
    "pauseForDevelopment",
    "gameEndingEarlyWarning",
  ],
  computed: {
    ...mapState({
      currentPlayer: state => state.playersModule.currentPlayer,
      currentGame: state => state.gameActionsModule.currentGame,
      currentGameLoaded: state => state.gameActionsModule.currentGameLoaded,
      currentPrompt: state => state.gameStateModule.currentGameState.currentPrompt,
      currentTeamTurn: state => state.gameStateModule.currentGameState.currentTeamTurn,
      currentPlayerTurn: state => state.gameStateModule.currentGameState.currentPlayerTurn,
      currentStepTimestamp: state => state.gameStateModule.currentGameState.currentStepTimestamp,
      teams: state => state.teamsModule.teams,
    }),
    ...mapGetters([
      "getPromptById",
      "getTeamForPlayer",
      "getPlayerById",
      "getTeamById",
      "getCurrentGameSecondsPerStep",
      "getAnswerOptionById",
      "getActorTimeIsUp"
    ]),

    backgroundImage() {
      const images = this.step.images
      return images.background && images.background.length > 0 ? images.background : ""
    },

    fontColour() {
      return this.step.colours.main.font
    },

    backgroundColour() {
      return this.step.colours.main.background
    },

    cornerImage() {
      return this.step.images.corner
    },

    showResults() {
      return this.getActorTimeIsUp == true
    },

    ready() {
      console.log("- START -")
      console.log(this.showResults)
      console.log(this.currentGameLoaded)
      console.log(this.currentGame)
      console.log(this.recalibrationPerformed)
      console.log(this.pauseForDevelopment)
      console.log("Ready")
      console.log(this.showResults || (this.currentGameLoaded && this.currentGame && (this.recalibrationPerformed || this.pauseForDevelopment)))
      console.log("- FIN -")
      return this.showResults || (this.currentGameLoaded && this.currentGame && (this.recalibrationPerformed || this.pauseForDevelopment))
    },

    canDisplayTimer() {
      return this.secondsFromQuestionStart > 0 
    },

    isCurrentActor() {
      return (this.getTeamForPlayer(this.currentPlayer.id).id == this.currentTeamTurn) && this.currentPlayer.id == this.currentPlayerTurn
    } ,

    headerVerb() {
      if (this.spectatorView) return 'spectating'

      let verb
      switch (this.step.stepType) {
        case 'whoami':
          verb = this.actorView ? 'guessing' : 'responding'
          break      
        case 'taboo':
          verb = this.actorView ? 'performing' : 'guessing'
          break      
        case 'charades':
          verb = this.actorView ? 'performing' : 'guessing'
          break      
      }

      return verb
    },

    headerMode() {
      return this.headerVerb == 'spectating' ? 'spectating' : 'playing'
    },

    actorView() {
      if(!this.currentPlayer) {
        touchTriggerGameStateListener(this.breakoutRoomId)
        return false
      }
      
      const currentTeam = this.getTeamForPlayer(this.currentPlayer.id)
      if(!currentTeam) {
        touchTriggerTeamListener(this.breakoutRoomId)
        return false
      }

      return this.currentPlayer.id == this.currentPlayerTurn && currentTeam.id == this.currentTeamTurn
    },

    guesserView() {
      if(!this.currentPlayer) {
        touchTriggerGameStateListener(this.breakoutRoomId)
        return false
      }
      
      const currentTeam = this.getTeamForPlayer(this.currentPlayer.id)
      if(!currentTeam) {
        touchTriggerTeamListener(this.breakoutRoomId)
        return false
      }

      return this.currentPlayer.id != this.currentPlayerTurn && currentTeam.id == this.currentTeamTurn
    },

    spectatorView() {
      if(!this.currentPlayer) {
        touchTriggerGameStateListener(this.breakoutRoomId)
        return false
      }
      
      const currentTeam = this.getTeamForPlayer(this.currentPlayer.id)
      if(!currentTeam) {
        touchTriggerTeamListener(this.breakoutRoomId)
        return false
      }

      return currentTeam.id != this.currentTeamTurn
    }
  },
  data() {
    return {
      answerId: null,
      startTime: null,
      endTime: null,
      displayTimer: 1,
      secondsFromQuestionStart: 0,
      timerCourtesyOffset: 1,
      timerInterval: null,
      tooLate: false,
      recalibrationPerformed: false,
      promptNumber: 1,
      nextPromptInterval: null,
      debug: false,
      gameHasEnded: false,
      actorTouchInterval: null
    };
  },
  async created() {    
    console.log("CREATED")
    this.secondsFromQuestionStart = this.calculateSecondsFromServerTimestamp(this.currentStepTimestamp)
    const timerStartDelay = this.actorView ? 1200 : 0 // Forces actor to lag behind
    setTimeout(() => this.setupTimer(this.timerSeconds), timerStartDelay)

    // Mark that this actor has played
    this.UPDATE_PLAYERS_PLAYED_FOR_TEAM({ 
      playerId: this.currentPlayerTurn, 
      teamId: this.getTeamForPlayer(this.currentPlayerTurn).id
    }) 
    if (this.actorView) {
      await updateTeamPlayerPlayed(this.getTeamForPlayer(this.currentPlayerTurn).id, this.currentPlayerTurn)
    }

    if(this.actorView) {
      // Actor touches the game state and team docs every 10secs
      // Keep things alive!
      this.actorTouchInterval = setInterval(() => {
        touchTriggerGameStateListener(this.breakoutRoomId)
        touchTriggerTeamListener(this.breakoutRoomId)
      }, 10000)
    }
  },
  mounted() {
    this.startTime = new Date();
  },
  beforeUnmount() {
    window.clearInterval(this.timerInterval)
    window.clearInterval(this.nextPromptInterval)

    if(this.actorTouchInterval) window.clearInterval(this.actorTouchInterval)
  },
  methods: {
    ...mapActions(["RESPOND_TO_PROMPT", "NEXT_PROMPT", "UPDATE_PLAYERS_PLAYED_FOR_TEAM"]),

    setupTimer(secondsPerStep) {
      this.displayTimer = secondsPerStep
      console.log("setup timer")
      setTimeout(() => this.recalibrationPerformed = true, 750)

      if(this.pauseForDevelopment) return

      if (this.secondsFromQuestionStart > 0) {
        this.startTimer()
      } else if(this.actorView) {
        this.tooLate = true
        this.tooLateToAnswer(true)
      }
    },

    calculateSecondsFromServerTimestamp(serverTimestamp) {
      const now = new Date();
      const serverStamp = new Date(serverTimestamp.toDate());
      const diff = now.getTime() - serverStamp.getTime();
      return Math.ceil(diff / 1000);
    },

    startTimer() { 
      const actorIntervalMilliseconds = (this.step.stepType == 'whoami') ? 1010 : 1050
      const otherPlayerIntervalMilliseconds = (this.step.stepType == 'whoami') ? 990 : 950
      const intervalMilliseconds = this.isCurrentActor ? actorIntervalMilliseconds : otherPlayerIntervalMilliseconds

      this.timerInterval = window.setInterval(() => {
        this.displayTimer -= 1;

        if (this.displayTimer <= 0) {
          window.clearInterval(this.timerInterval);
          this.tooLateToAnswer(true)
        }
      }, intervalMilliseconds) // Actor moves slower
    },

    tooLateToAnswer(respond = true) {
      this.tooLate = true;
      if (respond && this.isCurrentActor) window.setTimeout(() => this.respondToPrompt(false, false), 1000)
    },

    playerResponse() {
      return this.getAnswerOptionById({
        stepId: this.step.id,
        answerId: this.answerId,
      });
    },

    resetCalibration() {
      console.log("reset happened")
      this.recalibrationPerformed = false
    },

    async respondToPrompt(correct = false, answered = true) {
      let teamId = null
      let team = this.getTeamForPlayer(this.currentPlayer.id)
      if (team) teamId = team.id
      
      await this.RESPOND_TO_PROMPT({
        gameId: this.gameId,
        teamId: teamId,
        playerId: this.currentPlayer.id,
        promptId: this.currentPrompt,
        stepId: this.step.id,
        correctStatus: correct, // they clicked correct
        answeredStatus: answered ? "answered" : "unanswered",
        breakoutRoomId: this.breakoutRoomId
      });
      
      if (this.tooLate) { // currentPlayer ran out of time, move to prompt results
        await setActorTimerHasFinished(this.breakoutRoomId)
      } else {
        await this.NEXT_PROMPT({ breakoutRoomId: this.breakoutRoomId, gameId: null })
        this.promptNumber += 1
      }
    },

    setupNextPromptsOnceActorIsReady() {
      this.nextPromptInterval = window.setInterval(async () => {
        if (!this.getActorTimeIsUp) { // Watch for when actor ready is set back to false, then rerender the next prompt
          clearInterval(this.nextPromptInterval)
          await this.NEXT_PROMPT({breakoutRoomId: this.breakoutRoomId, gameId: null}) // ready up the next prompt
          setTimeout(() => this.reRenderFunction(), 500) // and fire off the reRender
        }
      }, 750)
    },

    gameFinished() {
      this.gameHasEnded = true // clear all screens and stop processing stuff

      if(this.actorView) {
        endGameState(this.gameId, this.breakoutRoomId)
      }
      return true
    }
  },
};
</script>