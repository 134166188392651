import {
  getGameByCode,
  getGameRefByDocumentId,
  triggerNextGameStateStep,
  endGameState,
  playerExitGameEarly,
  removePlayerFromTeam,
} from "../../firebase"
import { getDoc } from 'firebase/firestore'

const secondsPerStepDefault = 60
const secondsPerStepResultsDefault = 20

const gameActionsModule = {
  state: () => ({
    currentGame: null,
    currentGameLoaded: null,
  }),
  getters: {
    getIsTeamGame: (state) => {
      return state.currentGame.teamsEnabled
    },
    getStepById: (state, getters) => (stepId) => {
      return getters.getCurrentGameSteps.find((step) => step.id == stepId)
    },
    getAnswerOptionById: (state, getters) => ({ stepId, answerId }) => {
      return getters.getStepById(stepId).answerOptions.find((a) => a.id == answerId)
    },
    getTaskGroupsForGame: (state) => {
      return state.currentGame["taskGroups"]
    },
    getTasksForTaskGroupId: (state) => (taskGroupId) => {
      const taskGroups = state.currentGame["taskGroups"]
      return taskGroups.find((tg) => tg.id == taskGroupId).tasks
    },
    getStepsForTaskId: (state, getters) => ({ taskGroupId, taskId }) => {
      const tasks = getters.getTasksForTaskGroupId(taskGroupId);
      return tasks.find((t) => t.id == taskId).steps;
    },
    getCurrentGameId: (state) => state.currentGame.id,
    getCurrentGameSecondsPerStep: (state) => state.currentGame.secondsPerStep,
    getCurrentGameSecondsPerStepResults: (state) => state.currentGame.secondsPerStepResults,
    getCurrentGameStepIds: (state) => {
      let stepIds = [];
      state.currentGame["taskGroups"].forEach(el => el["tasks"].forEach(t => t["steps"].forEach((step) => {
        stepIds.push(step.id)
      })))
      return stepIds;
    },
    hasCurrentGame: (state) => state.currentGame !== null,
    getCurrentGameSteps: (state) => {
      let steps = []
      state.currentGame.taskGroups.forEach(el => el["tasks"].forEach(t => t["steps"].forEach((step) => {
        steps.push(step)
      })))
      return steps;
    },
    getNextStep: (state, getters) => ({ taskGroupId, taskId, stepId }) => {
      const stepsInTask = getters.getStepsForTaskId({ taskGroupId: taskGroupId, taskId: taskId })
      const currentStep = getters.getStepById(stepId)
      let nextTaskGroup = null;
      let nextTask = null;
      let nextStep = null;
      if (currentStep.position == stepsInTask.length) {
        // if this task has run out of steps, go to next task in this taskGroup
        nextTaskGroup = getters.getTaskGroupsForGame.find((taskGroup) => +taskGroup.id == +taskGroupId)
        const tasks = getters.getTasksForTaskGroupId(taskGroupId)
        const currentTaskPosition = tasks.find((task) => task.id === taskId).position
        nextTask = tasks.find((task) => +task.position == +currentTaskPosition + 1)
        if (!nextTask) {
          // if this taskGroup has run out of tasks, go to next taskGroup
          const taskGroups = getters.getTaskGroupsForGame
          const currentTaskGroupPosition = taskGroups.find((taskGroup) => taskGroup.id === taskGroupId).position
          nextTaskGroup = taskGroups.find((taskGroup) => +taskGroup.position == +currentTaskGroupPosition + 1)
          if (!nextTaskGroup) {
            // no more taskGroups, tasks or steps
            return ["finished"]
          }
          nextTask = nextTaskGroup.tasks.find((task) => task.position == "1")
        }
        nextStep = nextTask.steps.find((step) => step.position == "1")
      } else {
        nextStep = stepsInTask.find((step) => +step.position == +currentStep.position + 1)
        return [taskGroupId, taskId, nextStep.id]
      }

      return [nextTaskGroup.id, nextTask.id, nextStep.id]
    },
    getAnswersForStepId: (state, getters) => (stepId) => {
      const step = getters.getStepById(stepId)
      return step.answerOptions
    },
    getCharadesPromptCategories: (state, getters) => {
      const categories = getters.getCurrentGameSteps.filter(step => step.stepType == "charades").map(step => step.promptCategory)
      return [...new Set(categories)]
    },
    getWhoAmIPromptCategories: (state, getters) => {
      const categories = getters.getCurrentGameSteps.filter(step => step.stepType == "whoami").map(step => step.promptCategory)
      return [...new Set(categories)]
    },
    getTabooPromptCategories: (state, getters) => {
      const categories = getters.getCurrentGameSteps.filter(step => step.stepType == "taboo").map(step => step.promptCategory)
      return [...new Set(categories)]
    },
    gameContainsCharadesPromptSteps: (state, getters) => {
      return getters.getCurrentGameSteps.some(step => step.stepType == "charades")
    },
    gameContainsWhoAmIPromptSteps: (state, getters) => {
      return getters.getCurrentGameSteps.some(step => step.stepType == "whoami")
    },
    gameContainsTabooPromptSteps: (state, getters) => {
      return getters.getCurrentGameSteps.some(step => step.stepType == "taboo")
    },
    getGameStatePauseScreenForDevelopment: (state) => {
      return state.currentGame.pauseScreenForDevelopment
    },
    getFullPromptStepTime: (state, getters, rootState, rootGetters) => (stepId) => {
      const turns = rootGetters.getTeams.length
      const step = getters.getStepById(stepId)
      const resultTime = state.currentGame.secondsPerStepResults
      
      // Each team has a go. PromptQuestion + PromptResults screens
      return (turns * step.stepTimer) + (turns * resultTime)
    },
    getFullInstructionStepTime: (state, getters) => (stepId) => {
      const step = getters.getStepById(stepId)
      return step.stepTimer
    }
  },
  mutations: {
    SET_CURRENT_GAME(state, payload) {
      state.currentGame = payload
    },
    UPDATE_END_GAME_TIME(state, gameEndsAt) {
      state.currentGame.gameEndsAt = gameEndsAt
    },
    UPDATE_OVERALL_SCORES_LOCKED(state, lockStatus) {
      state.currentGame.overallScoresLocked = lockStatus
    },
    GAME_LOADED(state) {
      state.currentGameLoaded = true;
    },
    CLEAR_CURRENT_GAME(state) {
      state.currentGame = null
      state.currentGameLoaded = false
    },
  },
  actions: {
    async GET_GAME({ commit, state }, { gameCode }) {
      const gameDocument = await getGameByCode(gameCode)
      if (gameDocument.exists() && state.currentGame == null) {
        let secondsPerStep
        if (gameDocument.data().secondsPerStep) {
          secondsPerStep = gameDocument.data().secondsPerStep
        } else {
          secondsPerStep = secondsPerStepDefault
        }
        let secondsPerStepResults
        if (gameDocument.data().secondsPerStepResults) {
          secondsPerStepResults = gameDocument.data().secondsPerStepResults
        } else {
          secondsPerStepResults = secondsPerStepResultsDefault
        }

        const data = {
          id: gameDocument.id,
          gameCode: gameDocument.data().gameCode,
          gameName: gameDocument.data().gameName,
          gameEndsAt: gameDocument.data().gameEndsAt,
          secondsPerStep: secondsPerStep,
          secondsPerStepResults: secondsPerStepResults,
          taskGroups: gameDocument.data().taskGroups,
          breakoutRoomsEnabled: gameDocument.data().breakoutRoomsEnabled,
          teamsEnabled: gameDocument.data().breakoutRoomsEnabled,
          teamCount: gameDocument.data().teamCount,
          maxPlayersPerTeam: gameDocument.data().maxPlayersPerTeam,
          pauseScreenForDevelopment: gameDocument.data().pauseScreenForDevelopment,
          overallScoresLocked: gameDocument.data().overallScoresLocked       
        }
        commit("SET_CURRENT_GAME", data)
        commit("GAME_LOADED")
      }
    },
    async GET_GAME_BY_ID({ commit }, { gameId }) {
      const gameRef = await getGameRefByDocumentId(gameId)
      const gameDocument = await getDoc(gameRef)
      if (gameDocument.exists()) {
        let secondsPerStep
        if (gameDocument.data().secondsPerStep) {
          secondsPerStep = gameDocument.data().secondsPerStep
        } else {
          secondsPerStep = secondsPerStepDefault
        }
        let secondsPerStepResults
        if (gameDocument.data().secondsPerStepResults) {
          secondsPerStepResults = gameDocument.data().secondsPerStepResults
        } else {
          secondsPerStepResults = secondsPerStepResultsDefault
        }
        const data = {
          id: gameDocument.id,
          gameCode: gameDocument.data().gameCode,
          gameName: gameDocument.data().gameName,
          gameEndsAt: gameDocument.data().gameEndsAt,
          secondsPerStep: secondsPerStep,
          secondsPerStepResults: secondsPerStepResults,
          taskGroups: gameDocument.data().taskGroups,
          teamsEnabled: gameDocument.data().breakoutRoomsEnabled,
          breakoutRoomsEnabled: gameDocument.data().breakoutRoomsEnabled,
          teamCount: gameDocument.data().teamCount,
          maxPlayersPerTeam: gameDocument.data().maxPlayersPerTeam,
          pauseScreenForDevelopment: gameDocument.data().pauseScreenForDevelopment,
          overallScoresLocked: gameDocument.data().overallScoresLocked
        }
        commit("SET_CURRENT_GAME", data)
        commit("GAME_LOADED")
      }
    },
    async NEXT_STEP({ getters, dispatch, commit }, { gameId, currentStepId, breakoutRoomId }) {
      // console.log(gameId, currentStepId, breakoutRoomId)
      const stepIds = getters.getCurrentGameStepIds
      const index = stepIds.findIndex((id) => id == currentStepId)

      if (stepIds[index + 1] !== undefined) {
        const nextStepId = stepIds[index + 1]
        const taskGroups = getters.getTaskGroupsForGame
        let nextTask

        const nextTaskGroup = taskGroups.find((taskGroup) => {
          nextTask = taskGroup.tasks.find((task) => {
            return task.steps.some((step) => step.id == nextStepId)
          })
          return nextTask
        })

        await triggerNextGameStateStep(gameId, breakoutRoomId, nextStepId, nextTask.id, nextTaskGroup.id)
        commit("SET_CURRENT_STEP", nextStepId)

        if (["charades", "taboo", "whoami"].includes(getters.getStepById(nextStepId).stepType)) {
          // console.log("FINDING NEW PROMPT AS STEP HAS CHANGED")
          dispatch("NEXT_PROMPT", { gameId: gameId, breakoutRoomId: breakoutRoomId })
        }
      } else {
        // console.log("NO MORE STEPS, ENDING GAME")
        await endGameState(gameId, breakoutRoomId)
      }
    },

    async EXIT_GAME_EARLY({ commit, state, rootState }, { teamId, breakoutRoomId }) {
      if (Object.keys(rootState.playersModule.currentPlayer).length == 0 || state.currentGame === null) return

      await playerExitGameEarly(rootState.playersModule.currentPlayer.id, breakoutRoomId, state.currentGame.id)
      if (teamId) await removePlayerFromTeam(teamId, rootState.playersModule.currentPlayer.id);
      commit("CLEAR_RESPONSES");
      commit("CLEAR_LOBBY_PLAYERS");
      commit("CLEAR_GAME_STATS");
      commit("CLEAR_CURRENT_GAME");
      commit("CLEAR_CURRENT_GAME_STATE");
      commit("CLEAR_TEAMS")
      commit("CLEAR_PROMPTS")
      commit("CLEAR_BREAKOUT_ROOMS")
      commit("CLEAR_READ_NOTIFICATIONS")
    },
  }
}

export default gameActionsModule;