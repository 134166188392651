<template>
  <p></p>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "ExitGame",
  computed: {
    ...mapState({
      currentPlayer: state => state.playersModule.currentPlayer
    }),
    ...mapGetters(["getTeamForPlayer", "getBreakoutRoomForTeam"]),
  },
  async created() {
    try {
      let id = window.setTimeout(function() {}, 0)
      while (id--) { // clear all timeouts on the window to stop screen shifting
        window.clearTimeout(id)
      }
      let teamId;
      let breakoutRoomId;
      let team = this.getTeamForPlayer(this.currentPlayer.id);
      let breakoutRoom;
      if (team) {
        teamId = team.id
        breakoutRoom = this.getBreakoutRoomForTeam(teamId)
        if (breakoutRoom) breakoutRoomId = breakoutRoom.id
      } 
      await this.$store.dispatch("EXIT_GAME_EARLY", { teamId: teamId, breakoutRoomId: breakoutRoomId });
      this.$router.push({ name: "Login" })
    } catch(error) {
      this.$router.push({ name: "Login" })
    }
  },
};
</script>