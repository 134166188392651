<template>
  <section v-if="previewReady" 
          id="gameplay" 
          class="bg-cover pt-3 md:pt-6 lg:pt-8"
          :style="{
            'backgroundImage': `url('${backgroundImage}')`,
            'backgroundColor': backgroundColour,
            'color': fontColour
          }">
    <img v-if="cornerImage.length > 0" v-bind:src="cornerImage" id="top-corner-image" class="block" />

    <div class="flex flex-col space-y-4">
      <ActorPromptQuestion  v-if="actorView()"
        :player="previewPlayer"
        :team="previewTeam"
        :prompt="previewPrompt"
        :respondToPrompt="respondToPrompt"
        :currentPrompt="previewPrompt"
        :timer="previewStep.timerSeconds"
        :tooLate="0"
        :canDisplayTimer="1"
        :step="previewStep"
        :previewTeamName="previewTeam.name"
        :fontColour="fontColour"
        :promptNumber="promptNumber" />

      <GuesserPromptQuestion  v-if="guesserView()"
          :player="previewPlayer"
          :team="previewTeam"
          :prompt="previewPrompt"
          :timer="previewStep.timerSeconds"
          :canDisplayTimer="1"
          :step="previewStep"
          :fontColour="fontColour"
          :previewing="1"
          :breakoutRoomId="previewBreakoutRoomId" />
  
      <SpectatorPromptQuestion  v-if="spectatorView()"
          :player="previewPlayer"
          :team="previewTeam"
          :prompt="previewPrompt"
          :currentPrompt="previewPrompt"
          :timer="previewStep.timerSeconds"
          :canDisplayTimer="1"
          :step="previewStep"
          :fontColour="fontColour"
          :promptNumber="promptNumber" />
  
      <PromptResults v-if="resultsView()"
          :step="previewStep"
          :gameId="previewGameId"
          :breakoutRoomId="previewBreakoutRoomId"
          :actorView="1"
          :guesserView="0"
          :spectatorView="0"
          :reRenderFunction="reRenderFunction"
          :previewing="1"
          :fontColour="fontColour" />
    </div>

    <img v-if="cornerImage.length > 0" v-bind:src="cornerImage" id="bottom-corner-image" class="block" />
  </section>
</template>

<script>
import { 
  getPreviewPrompt, 
  getPreviewStep, 
  getTeamByDocumentId, 
  getUserByDocumentId, 
  getPreviewStepDocById 
} from '../firebase'
import { onSnapshot } from '@firebase/firestore';
import ActorPromptQuestion from "../components/game/ActorPromptQuestion.vue";
import GuesserPromptQuestion from "../components/game/GuesserPromptQuestion.vue";
import SpectatorPromptQuestion from "../components/game/SpectatorPromptQuestion.vue";
import PromptResults from "../components/game/PromptResults.vue";

export default {
  name: "PromptQuestionPreview",
  props: ["screenType", "stepPreviewId"],
  components: {
    ActorPromptQuestion,
    GuesserPromptQuestion,
    SpectatorPromptQuestion,
    PromptResults
  },
  data() {
    return {
      previewPlayerId: "txryXdZRSuD4rebVzYes",
      previewTeamId: "0600f451ce602c3bdc05",
      previewGameId: "steve_bee_test_games",
      previewBreakoutRoomId: "8144eb30fc1fe3c74ea9",
      previewStep: null,
      previewPrompt: null,
      previewPlayer: null,
      previewTeam: null,
      unsubPreviewStep: Function,
      promptNumber: 1
    }
  },
  computed: {
    previewReady() {
      return this.previewStep && this.previewPrompt && this.previewPlayer && this.previewTeam
    },

    cornerImage() {
      return this.previewStep.images.corner
    },

    backgroundImage() {
      const images = this.previewStep.images
      return images.background && images.background.length > 0 ? images.background : ""
    },

    fontColour() {
      return this.previewStep.colours.main.font
    },

    backgroundColour() {
      return this.previewStep.colours.main.background
    }
  },
  async created() {
    this.previewStep = await getPreviewStep(this.stepPreviewId)
    this.previewPrompt = await getPreviewPrompt(this.previewStep.stepType, this.previewStep.promptCategory)
    await this.setupStepListener()
    await this.fetchPlayer()
    await this.fetchTeam()
  },
  beforeUnmount() {
    this.unsubPreviewStep();
  },
  methods: {
    reRenderFunction() {},
    respondToPrompt() {},

    actorView() {
      return this.screenType == 'actor'
    },

    guesserView() {
      return this.screenType == 'guesser'
    },

    spectatorView() {
      return this.screenType == 'spectator'
    },

    resultsView() {
      return this.screenType == 'results'
    },

    async fetchPlayer() {
      const userDoc = await getUserByDocumentId(this.previewPlayerId)
      const userData = {
        id: userDoc.id,
        name: userDoc.data().name,
        initials: userDoc.data().initials,
        score: userDoc.data().score
      }
      this.previewPlayer =  userData
    },

    async fetchTeam() {
      const teamDoc = await getTeamByDocumentId(this.previewTeamId)
      const teamData = {
        id: teamDoc.id,
        name: teamDoc.data().name,
        hexColour: teamDoc.data().hexColour,
        players: teamDoc.data().players,
        score: teamDoc.data().score,
        minPlayerCount: teamDoc.data().minPlayerCount,
        maxPlayerCount: teamDoc.data().maxPlayerCount,
        breakoutRoomId: teamDoc.data().breakoutRoomId,
      }
      this.previewTeam =  teamData
    },

    async setupStepListener() {
      const docRef = await getPreviewStepDocById(this.stepPreviewId)
      this.unsubPreviewStep = onSnapshot(docRef, async (doc) => {
        this.previewStep = doc.data()
        this.previewPrompt = await getPreviewPrompt(doc.data().stepType,  doc.data().promptCategory)
      })
    }
  }
}
</script>