<template>
  <InfoHeader v-if="!gameHasEnded" verb="instructions" mode="spectating" :gameFinishedFunction="gameFinished" />

  <section v-if="!gameHasEnded" id="gameplay" 
            class="pt-12 lg:pt-14 bg-cover" 
            :style="{
              'backgroundImage': `url('${backgroundImage}')`,
              'backgroundColor': backgroundColour,
              'color': fontColour
            }">

    <img v-if="cornerImage.length > 0" v-bind:src="cornerImage" id="top-corner-image" class="block z-10" />

    <div v-if="currentGameLoaded && currentGame" class="flex flex-col w-4/5 lg:w-1/2 mx-auto relative space-y-4 mb-4 z-20">
      <h2 class="text-2xl lg:text-3xl font-bold md:mb-2">{{ step.title }}</h2>
      
      <div v-if="step.images.content" class="w-3/5 md:w-1/2 mx-auto px-2 md:px-8 xl:px-10 py-1 rounded-md">
        <img :src="step.images.content" class="rounded-md"  />
      </div>

      <p class="text-base px-8" v-html="step.text"></p>
    </div>
    
    <div class="mt-2 lg:mt-3 space-y-2">
      <p class="font-bold text-xl">The {{ this.step.position == 1 ? 'first' : 'next' }} round begins in:</p>
      <Countdown :timerSeconds="timeToNextStep" :colour="fontColour" :waitingMessage="'Moving on...'" />
    </div>

    <NotificationsCallout :gameId="gameId" :breakoutRoomId="breakoutRoomId" :screenType="'rounds'" />

    <img v-if="cornerImage.length > 0" v-bind:src="cornerImage" id="bottom-corner-image" class="block z-10" />
  </section>

  <GameResetWatcher :breakoutRoomId="breakoutRoomId" />
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { db, getGameStateRefByBreakoutRoomId, getGameRefByDocumentId, endGameState } from "../firebase";
import { collection, where, query, onSnapshot } from "firebase/firestore";
import { gameStepMap } from "../store/modules/utils";
import { Timestamp } from "firebase/firestore";
import Countdown from '../components/game/Countdown.vue';
import NotificationsCallout from "../components/game/NotificationsCallout.vue";
import InfoHeader from '../components/game/InfoHeader.vue';
import GameResetWatcher from "../components/post_game/GameResetWatcher.vue";
import FirebaseCheckIn from '@/mixins/firebase_check_in'

export default {
  name: "Instruction",
  components: {
    Countdown,
    GameResetWatcher,
    NotificationsCallout,
    InfoHeader
  },
  mixins: [
    FirebaseCheckIn
  ],
  props: [
    "gameId", 
    "breakoutRoomId", 
    "taskGroupId", 
    "taskId", 
    "stepId"
  ],
  computed: {
    pauseForDevelopment: state => state.getGameStatePauseScreenForDevelopment == 'instruction',
    
    ...mapState({
      currentPlayer: state => state.playersModule.currentPlayer,
      currentGame: state => state.gameActionsModule.currentGame,
      currentStep: state => state.gameStateModule.currentGameState.currentStep,
      currentGameLoaded: state => state.gameActionsModule.currentGameLoaded,
      currentPlayerTurn: state => state.gameStateModule.currentGameState.currentPlayerTurn
    }),
    
    ...mapGetters([
      "getStepById", 
      "getGameStatePauseScreenForDevelopment", 
      "getFullInstructionStepTime",
      "getNotificationIsUnread"
    ]),
    
    step() {
      return this.getStepById(this.stepId)
    },

    cornerImage() {
      return this.step.images.corner
    },

    backgroundImage() {
      const images = this.step.images
      return images.background && images.background.length > 0 ? images.background : ""
    },

    fontColour() {
      return this.step.colours.main.font
    },

    backgroundColour() {
      return this.step.colours.main.background
    },
  },
  data() {
    return {
      interval: null,
      unsubGameState: Function,
      unsubTeamStates: Function,
      unsubGame: Function,
      gameStateRef: null,
      gameEndingEarlyWarning: false,
      timeToNextStep: 30,
      gameHasEnded: false,
    };
  },
  async created() {
    if (this.getStepById(this.currentStep).stepTimer) this.timeToNextStep = this.getStepById(this.currentStep).stepTimer
    
    this.gameRef = await getGameRefByDocumentId(this.gameId)
    this.setupGameListener();

    this.gameStateRef = await getGameStateRefByBreakoutRoomId(this.breakoutRoomId);
    this.setupListeners();
    
    await this.GET_GAME_BY_ID({ gameId: this.gameId })
    if(!this.pauseForDevelopment) this.setupIntervalTimer(this.timerSeconds)

    await this.startCheckingIn(
      this.stepId, 
      this.currentPlayer.id, 
      this.gameId, 
      this.breakoutRoomId,
      this.getFullInstructionStepTime(this.stepId)
    )
  },

  beforeUnmount() {
    this.unsubGameState()
    this.unsubTeamStates()
  },

  methods: {
    ...mapActions([
      "NEXT_STEP",
      "TRIGGER_SERVER_TIMESTAMP",
      "GET_GAME_BY_ID",
      "SET_CURRENT_TEAM_PLAYER_TURN"
    ]),
    ...mapMutations([
      "SET_CURRENT_STEP", 
      "SET_STEP_TIME_STAMP", 
      "FILTER_PLAYERS_UPDATE", 
      "UPDATE_TEAM_PLAYERS",
      "UPDATE_END_GAME_TIME",
    ]),

    setupGameListener() {
      this.unsubGame = onSnapshot(this.gameRef, (doc) => {
        if (!doc.data()) return

        this.UPDATE_END_GAME_TIME(doc.data()["gameEndsAt"])
      })
    },

    setupListeners() {
      // Listen for game state updates
      this.setupGameStateListener()

      // Listen to see if ppl have been kicked off of a team
      this.setupTeamListener()
    },

    setupGameStateListener() {
      this.unsubGameState = onSnapshot(this.gameStateRef, (doc) => {
        this.SET_CURRENT_STEP(doc.data().currentStep)
        this.FILTER_PLAYERS_UPDATE(doc.data().players)

        if ((doc.data().currentStep) && (doc.data().currentStep != this.stepId)) {
          const step = this.getStepById(doc.data().currentStep)
          
          if (step) {
            const stepType = step.stepType
            const screenType = gameStepMap(stepType)

            this.clearAllIntervals();
            // move to next step
            return this.$router.push({
              name: screenType,
              params: {
                gameId: this.gameId,
                taskGroupId: doc.data().currentTaskGroup,
                taskId: doc.data().currentTask,
                stepId: doc.data().currentStep,
              },
            });
          }
        } else if (doc.data().status == "ended") {
          return this.$router.push({
            name: "BreakoutRoomScores",
            params: { 
              gameId: this.gameId, 
              breakoutRoomId: this.breakoutRoomId,
              fromMenu: false
            },
          });
        } else if((doc.data().currentPlayerTurn === null) && (doc.data().currentUnderstudyTurn == this.currentPlayer.id)) {
          // I have been chosen as the understudy!
          // This means there is an issue with the currentPlayer (Actor) and I 
          // have been chosen by the Cloud Function to choose another actor for the next turn. Proud moment!
          // console.warn(`engaging understudy mode`, `instruction`)
          return this.SET_CURRENT_TEAM_PLAYER_TURN({ breakoutRoomId: this.breakoutRoomId, fromStepId: this.step.id })
          // As this is an 'instruction' round there is no need to move the game ahead or re-render at this point
        }
      });
    },

    setupTeamListener() {
      const teamQuery = query(
        collection(db, "teams"),
        where("gameId", "==", this.gameId),
        where("breakoutRoomId", "==", this.breakoutRoomId)
      )
      this.unsubTeamStates = onSnapshot(teamQuery, (docs) => {
        docs.forEach((doc) => {
          const teamData = {
            teamId: doc.id,
            players: doc.data().players,
          }
          this.UPDATE_TEAM_PLAYERS(teamData)
        })
      });
    },

    setupIntervalTimer() {
      this.interval = setInterval(async () => {
        this.timeToNextStep -= 1
        if (this.timeToNextStep <= 0) {
          this.clearAllIntervals();
          this.SET_STEP_TIME_STAMP(Timestamp.now())

          if (this.currentPlayerTurn == this.currentPlayer.id) {
            this.TRIGGER_SERVER_TIMESTAMP({ breakoutRoomId: this.breakoutRoomId })
            this.NEXT_STEP({ 
              breakoutRoomId: this.breakoutRoomId, 
              currentStepId: this.stepId,
            })
          }
        }
      }, 1000)
    },

    clearAllIntervals() {
      const interval_id = setInterval(function(){}, Number.MAX_SAFE_INTEGER);
      // Clear any timeout/interval up to that id
      for (let i = 1; i < interval_id; i++) {
        clearInterval(i);
      }
    },

    gameFinished() {
      this.gameHasEnded = true // clear all components and stop processing stuff
      
      if (this.currentPlayerTurn == this.currentPlayer.id) {
        endGameState(this.gameId, this.breakoutRoomId)
      }
      return true
    }    
  },
};
</script>
