const ColourUtils = {
  data() {
    return {}
  },
  methods: {
    shade(col, amt) {
      col = col.replace(/^#/, '')
      if (col.length === 3) col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2]
    
      let [r, g, b] = col.match(/.{2}/g);
      ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt])
    
      r = Math.max(Math.min(255, r), 0).toString(16)
      g = Math.max(Math.min(255, g), 0).toString(16)
      b = Math.max(Math.min(255, b), 0).toString(16)
    
      const rr = (r.length < 2 ? '0' : '') + r
      const gg = (g.length < 2 ? '0' : '') + g
      const bb = (b.length < 2 ? '0' : '') + b
    
      return `#${rr}${gg}${bb}`
    },

    applySaturationToHexColor(hex, saturationPercent) {
      if (!/^#([0-9a-f]{6})$/i.test(hex)) {
          throw('Unexpected color format');
      }
  
      if (saturationPercent < 0 || saturationPercent > 100) {
          throw('Unexpected color format');
      }
  
      var saturationFloat   = saturationPercent / 100,
          rgbIntensityFloat = [
              parseInt(hex.substr(1,2), 16) / 255,
              parseInt(hex.substr(3,2), 16) / 255,
              parseInt(hex.substr(5,2), 16) / 255
          ];
  
      var rgbIntensityFloatSorted = rgbIntensityFloat.slice(0).sort(function(a, b){ return a - b; }),
          maxIntensityFloat       = rgbIntensityFloatSorted[2],
          mediumIntensityFloat    = rgbIntensityFloatSorted[1],
          minIntensityFloat       = rgbIntensityFloatSorted[0];
  
      if (maxIntensityFloat == minIntensityFloat) {
          // All colors have same intensity, which means 
          // the original color is gray, so we can't change saturation.
          return hex;
      }
  
      // New color max intensity wont change. Lets find medium and weak intensities.
      var newMediumIntensityFloat,
          newMinIntensityFloat = maxIntensityFloat * (1 - saturationFloat);
  
      if (mediumIntensityFloat == minIntensityFloat) {
          // Weak colors have equal intensity.
          newMediumIntensityFloat = newMinIntensityFloat;
      }
      else {
          // Calculate medium intensity with respect to original intensity proportion.
          var intensityProportion = (maxIntensityFloat - mediumIntensityFloat) / (mediumIntensityFloat - minIntensityFloat);
          newMediumIntensityFloat = (intensityProportion * newMinIntensityFloat + maxIntensityFloat) / (intensityProportion + 1);
      }
  
      var newRgbIntensityFloat       = [],
          newRgbIntensityFloatSorted = [newMinIntensityFloat, newMediumIntensityFloat, maxIntensityFloat];
  
      // We've found new intensities, but we have then sorted from min to max.
      // Now we have to restore original order.
      rgbIntensityFloat.forEach(function(originalRgb) {
          var rgbSortedIndex = rgbIntensityFloatSorted.indexOf(originalRgb);
          newRgbIntensityFloat.push(newRgbIntensityFloatSorted[rgbSortedIndex]);
      });
  
      var floatToHex = function(val) { return ('0' + Math.round(val * 255).toString(16)).substr(-2); },
          rgb2hex    = function(rgb) { return '#' + floatToHex(rgb[0]) + floatToHex(rgb[1]) + floatToHex(rgb[2]); };
  
      var newHex = rgb2hex(newRgbIntensityFloat);
  
      return newHex;
    }
  }
}

export default ColourUtils