<template>
  <div class="flex flex-col mx-auto">
    <Header :backButton="leaveBreakoutRoom" :backButtonText="'Back'" :title="gameName()" />
    <div class="flex flex-col w-11/12 lg:w-2/3 mx-auto">
      <div v-if="breakoutRooms && showList" class="grid grid-cols-1 md:hidden mx-auto mt-8">
        <carousel :items-to-show="1">
          <slide v-for="roomPair in breakoutRoomPairs" :key="roomPair[0].id" class="flex flex-col space-y-8 mx-auto">
            <div v-for="room in roomPair" :key="room.id" class="w-full">
              <div class="flex flex-col space-y-8 p-8 lg:p-8 border rounded-2xl px-12 room-box">
                <div class="flex flex-col space-y-6 text-left">
                  <p class="text-sm lg:text-md font-semibold">{{ room.name }}</p>
                  <p class="text-sm lg:text-md mt-2">Space available: {{ playersOccupying(room) }}/{{ playerSpace(room) }}</p>
                </div>
                
                <button type="button" v-if="playersOccupying(room) < playerSpace(room)" @click="joinBreakoutRoom(room.id)" class="px-4 py-2 border border-black rounded-full text-sm lg:text-md bg-white font-semibold h-9 hover:bg-green-500 hover:text-white hover:border-green-600 transition ease-in-out">Join this room!</button>
                <p v-else>Room is full!</p>
              </div>
            </div>
          </slide>

          <template #addons>
            <Pagination />
          </template>
        </carousel>
      </div>

      <div v-if="breakoutRooms" class="hidden md:grid grid-cols-2 gap-6 mx-auto mt-8">
        <div v-for="room in breakoutRooms" :key="room.id" class="hidden md:flex mx-auto">
          <div class="flex space-x-8 lg:space-x-8 xl:space-x-20 p-6 lg:p-8 border rounded-2xl room-box">
            <div class="flex flex-col text-left">
              <p class="text-sm lg:text-md font-semibold">{{ room.name }}</p>
              <p class="text-sm lg:text-md mt-2">Space available: {{ playersOccupying(room) }}/{{ playerSpace(room) }}</p>
            </div>
            
            <button type="button" v-if="playersOccupying(room) < playerSpace(room)" @click="joinBreakoutRoom(room.id)" class="px-4 border border-black rounded-full text-sm lg:text-md bg-white font-semibold h-9 hover:bg-green-500 hover:text-white hover:border-green-600 transition ease-in-out">Join this room!</button>
            <p v-else>Room is full!</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <GameplayFooter />
</template>


<script>
import Header from "../components/Header.vue";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import { addPlayerToGameState } from '../firebase'
import { onSnapshot, query, collection, where } from "firebase/firestore";
import { db } from "../firebase";
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination } from 'vue3-carousel'
import GameplayFooter from "../components/game/GameplayFooter.vue";

export default {
  name: "BreakoutRoomLobby",
  props: ["gameId"],
  components: {
    Header,
    Carousel,
    Slide,
    Pagination,
    GameplayFooter
},
  async created() {
    await this.FETCH_BREAKOUT_ROOMS({ gameId: this.gameId }).then(() => this.showList = true)

    this.setupListener()
  },
  data() {
    return {
      unsubTeamState: Function,
      showList: false,
    }
  },
  computed: {
    ...mapState({
      currentGame: state => state.gameActionsModule.currentGame,
      currentGameState: state => state.gameStateModule.currentGameState,
      breakoutRooms: state => state.breakoutRoomsModule.breakoutRooms,
      currentPlayer: state => state.playersModule.currentPlayer,
      teams: state => state.teamsModule.teams,
    }),
    ...mapGetters(["getBreakoutRooms", "getCurrentGameId"]),
    breakoutRoomPairs() { return this.breakoutRooms.reduce(function(result, value, index, array) {
        if (index % 2 === 0) result.push(array.slice(index, index + 2));
        return result
      }, [])
    }
  },
  beforeUnmount() {
    this.unsubTeamState();
  },
  methods: {
    ...mapActions(["FETCH_BREAKOUT_ROOMS", "GET_GAME_STATE", "REMOVE_PLAYER_FROM_TEAM"]),
    ...mapMutations(["UPDATE_TEAM_STATES"]),

    setupListener() {
      const q = query(
      collection(db, "teams"),
        where(
          "breakoutRoomId",
          "in",
          this.breakoutRooms.map((room) => room.id).flat()
        ),
      );

      this.unsubTeamState = onSnapshot(q, (docs) => {
        let teams = []
        docs.forEach((teamDoc) => {
          const team = {
            id: teamDoc.id,
            breakoutRoomId: teamDoc.data().breakoutRoomId,
            hexColour: teamDoc.data().hexColour,
            name: teamDoc.data().name,
            players: teamDoc.data().players,
            score: teamDoc.data().score,
            minPlayerCount: teamDoc.data().minPlayerCount,
            maxPlayerCount: teamDoc.data().maxPlayerCount,
          }
          teams.push(team)
        })
        this.UPDATE_TEAM_STATES(teams)
      })
    },

    async leaveBreakoutRoom() {
      this.$router.push({ name: "Login", params: { gameId: this.gameId }})
      this.teams.forEach((team) => {
        this.REMOVE_PLAYER_FROM_TEAM({ playerId: this.currentPlayer.id, teamId: team.id })
      })
    },

    async joinBreakoutRoom(roomId) {
      await this.loadGameState(roomId);

      const endTime = this.currentGame.gameEndsAt.seconds * 1000
      const reamainingSeconds = Math.round((endTime - Date.now()) / 1000)

      if (this.currentGameState.status == "ended") {
        alert("The game has already ended for this breakout room!")
        return
      } else if (this.currentGameState.status == "started") {
        alert("The game has already started for this breakout room!")
        return
      } else if (reamainingSeconds < 1) {
        alert("This game has already expired!")
        return
      } else {
        await addPlayerToGameState(null, roomId, this.currentPlayer.id)
        return this.$router.push({ name: "TeamLobby", params: { gameId: this.gameId, breakoutRoomId: roomId } })
      }
    },

    async loadGameState(roomId) {
      await this.GET_GAME_STATE({ breakoutRoomId: roomId, getHistorical: false });
    },

    playersOccupying(room) {
      return this.teams.filter(team => team.breakoutRoomId == room.id).reduce((sum, team) => sum + team.players.length, 0)
    },

    playerSpace(room) {
      return room.teamIds.length * this.currentGame.maxPlayersPerTeam
    },

    gameName() {
      return this.currentGame.gameName || "Wildgoose"
    }
  },
};
</script>

<style scoped>
  .room-box {
    background-color: #f9f9f9;
  }
</style>