<template>
  <div v-if="currentGameLoaded && currentGame" class="flex flex-col space-y-4">
    <ShowQuestion
      v-if="!questionAnswered"
      :step="getStepById(this.stepId)"
    />

    <div
      v-if="!questionAnswered"
      class="flex flex-row mx-auto flex-wrap space-x-4"
    >
      <div
        v-for="answer in getStepById(this.stepId).answerOptions"
        :key="answer.id"
      >
        <p
          v-if="questionAnswered"
          class="p-6 bg-gray-300 rounded-xl cursor-pointer"
        >
          {{ answer.text }}
        </p>
        <p
          @click="respondToQuestion(answer.id)"
          v-if="!questionAnswered"
          class="p-3 font-semibold bg-green-300 rounded-xl cursor-pointer"
        >
          {{ answer.text }}
        </p>
      </div>
    </div>

    <QuestionResults
      v-if="questionAnswered"
      :stepId="stepId"
      :gameId="gameId"
      :playerCount="playerCount"
    />

    <p class="font-semibold text-xl" v-if="canDisplayTimer">
      Time remaining: {{ this.displayTimer }}
    </p>
    <p class="text-red-600 font-bold text-2xl" v-if="tooLate">
      TOO SLOW! Moving on......
    </p>

    <p class="text-red-600 font-bold text-2xl" v-if="gameEndingEarlyWarning">
      The other players have left the game. We'll end it here....
    </p>
  </div>
</template>

<script>
import ShowQuestion from "../components/game/ShowQuestion.vue";
import QuestionResults from "../components/game/QuestionResults.vue";
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { getGameStateRefByGameId, endGameState } from "@/firebase";
import { onSnapshot, getDoc } from "firebase/firestore";

export default {
  name: "Question",
  components: {
    ShowQuestion,
    QuestionResults
  },
  props: ["gameId", "taskGroupId", "taskId", "stepId"],
  computed: {
    ...mapState({
      currentPlayer: state => state.playersModule.currentPlayer,
      currentGame: state => state.gameActionsModule.currentGame,
      currentGameLoaded: state => state.gameActionsModule.currentGameLoaded,
    }),
    ...mapGetters(["getStepById", "getCurrentGameSecondsPerStep", "getAnswerOptionById", "getTeamForPlayer"]),

    canDisplayTimer() {
      return (
        this.displayTimer >= 0 &&
        this.secondsFromQuestionStart > 0 &&
        this.secondsFromQuestionStart <= this.displayTimer
      );
    },
  },
  data() {
    return {
      questionAnswered: false,
      answerId: null,
      startTime: null,
      endTime: null,
      playerCount: 0,
      displayTimer: 1,
      secondsFromQuestionStart: 0,
      timerCourtesyOffset: 3,
      timerInterval: null,
      tooLate: false,
      gameStateRef: null,
      gameStateDoc: null,
      unsubGameState: Function,
      gameEndingEarlyWarning: false,
    };
  },
  async created() {
    this.getCurrentGame();

    this.gameStateRef = await getGameStateRefByGameId(this.gameId);
    this.gameStateDoc = await getDoc(this.gameStateRef);

    this.setupTimer(this.getCurrentGameSecondsPerStep);
    this.setupListener();
  },
  mounted() {
    this.startTime = new Date();
  },
  beforeUnmount() {
    window.clearInterval(this.timerInterval);
    this.unsubGameState();
  },
  methods: {
    ...mapMutations(["SET_CURRENT_GAME_STATE"]),
    ...mapActions(["GET_GAME_BY_ID", "RESPOND_TO_STEP"]),

    setupTimer(secondsPerStep) {
      this.displayTimer = secondsPerStep;
      this.secondsFromQuestionStart = this.calculateSecondsFromServerTimestamp(
        this.gameStateDoc.data()["currentStepTimestamp"]
      );
      if (
        this.secondsFromQuestionStart > 0 &&
        this.secondsFromQuestionStart <
          this.displayTimer + this.timerCourtesyOffset
      ) {
        this.startTimer();
      }
    },

    calculateSecondsFromServerTimestamp(serverTimestamp) {
      const now = new Date();
      const serverStamp = new Date(serverTimestamp.toDate());
      const diff = now.getTime() - serverStamp.getTime();
      return Math.ceil(diff / 1000);
    },

    startTimer() {
      this.timerInterval = window.setInterval(() => {
        this.displayTimer -= 1;

        if (this.displayTimer < 0) {
          window.clearInterval(this.timerInterval);

          if (!this.questionAnswered) this.tooLateToAnswer(true);
        }
      }, 1000);
    },

    tooLateToAnswer(respond = true) {
      this.tooLate = true;

      if (respond)
        window.setTimeout(() => {
          this.respondToQuestion(null);
        }, 2000);
    },

    setupListener() {
      this.unsubGameState = onSnapshot(this.gameStateRef, (doc) => {
        this.playerCount = doc.data().players.length;

        const data = {
          id: doc.id,
          status: doc.data().status,
          players: doc.data().players,
          currentTaskGroup: doc.data().currentTaskGroup,
          currentTask: doc.data().currentTask,
          currentStep: doc.data().currentStep,
          gameId: doc.data().gameId,
        };
        this.SET_CURRENT_GAME_STATE(data);

        if (this.playerCount == 1) {
          this.gameEndingEarlyWarning = true;
          window.setTimeout(() => {
            this.endGameEarly();
          }, 5000);
        } else if (doc.data().status == "ended") {
          return this.$router.push({
            name: "BreakoutRoomScores",
            params: { gameId: this.gameId },
          });
        } else if (doc.data().currentStep != this.stepId) {
          // move to next step
          return this.$router.push({
            name: "Question",
            params: {
              gameId: this.gameId,
              taskGroupId: doc.data().currentTaskGroup,
              taskId: doc.data().currentTask,
              stepId: doc.data().currentStep,
            },
          });
        }
      });
    },

    getCurrentGame() {
      this.GET_GAME_BY_ID({ gameId: this.gameId });
    },

    playerResponse() {
      return this.getAnswerOptionById({
        stepId: this.stepId,
        answerId: this.answerId,
      });
    },

    async respondToQuestion(answerId = null) {
      if (this.questionAnswered === true) return;

      window.clearInterval(this.timerInterval);
      this.displayTimer = -1;

      let teamId = null
      if (this.currentGame.teamsEnabled) {
        let team = this.getTeamForPlayer(this.currentPlayer.id)
        if (team) teamId = team.id
      }

      let timeTaken = new Date() - this.startTime;
      await this.RESPOND_TO_STEP({
        playerId: this.currentPlayer.id,
        teamId: teamId,
        stepId: this.stepId,
        gameId: this.gameId,
        answerId: answerId,
        timeTaken: timeTaken,
      });
      this.questionAnswered = true;
      this.answerId = answerId;
    },

    async endGameEarly() {
      await endGameState(this.gameId);
      return this.$router.push({
        name: "BreakoutRoomScores",
        params: { gameId: this.gameId },
      });
    },
  },
};
</script>