<template>
  <div class="space-y-4 mt-4">
    <p class="text-lg">It is currently</p>
    <p class="font-bold text-2xl lg:text-4xl">{{ player.name }}'s </p>
    <p class="text-lg">Turn from Team {{ team.name }}</p>
  </div>

  <div class="mt-8 lg:mt-10" v-if="canDisplayTimer">
    <Countdown :timerSeconds="timer" :colour="fontColour" :waitingMessage="'Finishing round...'" />
  </div>

  <ShowPrompt
    v-if="this.currentPrompt"
    :prompt="prompt"
    :spectatorView="true"
    :player="player" 
    :step="step"
    :promptNumber="promptNumber"
    :team="team" />
</template>

<script>
import ShowPrompt from './ShowPrompt.vue';
import Countdown from './Countdown.vue';

export default {
  name: "SpectatorPromptQuestion",
  props: [
    "player", 
    "team", 
    "prompt", 
    "timer", 
    "currentPrompt", 
    "canDisplayTimer",
    "step",
    "fontColour",
    "promptNumber"
  ],
  components: {
    ShowPrompt,
    Countdown
  }
}
</script>
