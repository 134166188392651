import {
  getBreakoutRoomsByGameId,
} from '../../firebase'

const breakoutRoomsModule = {
  state: () => ({
    breakoutRooms: []
  }),
  getters: {
    getBreakoutRooms: (state) => {
      return state.breakoutRooms
    },
    getBreakoutRoomById: (state) => (breakoutRoomId) => {
      return state.breakoutRooms.find(br => br.id == breakoutRoomId)
    },
    getBreakoutRoomForTeam: (state) => (teamId) => {
      return state.breakoutRooms.find(room => {
        return room.teamIds.some(tid => tid == teamId)
      })
    },
  },
  mutations: {
    SET_BREAKOUT_ROOMS(state, rooms) {
      state.breakoutRooms = rooms
    },
    CLEAR_BREAKOUT_ROOMS(state) {
      state.breakoutRooms = []
    },
  },
  actions: {
    async FETCH_BREAKOUT_ROOMS({ commit }, { gameId }) {
      const rooms = await getBreakoutRoomsByGameId(gameId)
      commit("SET_BREAKOUT_ROOMS", rooms)
    }
  }
}

export default breakoutRoomsModule;